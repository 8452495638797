diff-row {
  --spacing-h: 1rem;
  --spacing-v: 0.3rem;
  --minColWidth: 0;
  --columns: 24;
  overflow-wrap: anywhere;

  @media (min-width:768px) {
    display: grid;
    grid-template-columns: repeat( var(--columns), minmax(var(--minColWidth), 1fr) );
  }

  > * {
    padding: 0 var(--spacing-h) ;
    display: grid;
    grid-template-rows: subgrid;
    grid-row: auto / span 2;
    grid-column: span 5;

    &.diff-row__wide {
      grid-column: span 7;
    }

    strong {
      display: block;
    }
  }

  .diff-row__title {
    background : #e0e0e0;
    padding: var(--spacing-v) var(--spacing-h);
    margin: calc(var(--spacing-v) * -1) calc(var(--spacing-h) * -1);
    margin-bottom: 1rem;
    font-size: 0.95rem;
    font-weight: bold;
    text-transform: uppercase;
  }

  ul, ol {
    padding:0 0 0 var(--spacing-h);

    p {
      margin: 0;
      display: inline;
    }
    li {
      margin-bottom: px-to-rem(4);
    }
  }

  span.reference {
    margin-left: 0.25rem !important;
  }

}