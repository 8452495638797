//----------------------------------------
// Checkboxes
//----------------------------------------
.bp-checkbox {
  accent-color: $palette-pink-600;
  height: 1.2rem;
  width: 1.2rem;
}

//----------------------------------------
// Wrapping label
//----------------------------------------
.bp-labelWrap {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 0;
}

//----------------------------------------
// Form stack
//----------------------------------------
.bp-form-stack {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

//----------------------------------------
// Legend
//----------------------------------------
.bp-legend {
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.75rem;
}