//----------------------------------------------
// Search controls
//----------------------------------------------

.rf-search {
  position: relative;
}
.rf-search__controls {
  display: flex;
}

.rf-search__input {
  height: px-to-rem(46);
  width: 100%;
  background-color: $white;
  border: px-to-rem(1) solid $palette-bmj-grey-100;
  border-right: none;
  border-radius: px-to-rem(3) 0 0 px-to-rem(3);
  font-size: 1rem;
  padding: px-to-rem(12) px-to-rem(16);

  &:focus-visible {
    outline-offset: px-to-rem(-6);
  }
}

.rf-search__submit {
  border: px-to-rem(1) solid $palette-bmj-grey-100;
  border-left: none;
  border-radius: 0 px-to-rem(3) px-to-rem(3) 0;
  background-color: $white;
}
.rf-search__close {
  position: absolute;
  top: px-to-rem(-34);
  right: px-to-rem(56);
  background-color: $white;
  border: none;
  width: px-to-rem(20);
  height: px-to-rem(20);
  padding: 0;


  &:after {
    display: block;
    width: px-to-rem(20);
    height: px-to-rem(2);
    background-color: $palette-grey-500;
    content: '';
    transform: rotate(-45deg);
    position: absolute;
    top: px-to-rem(9);
  }

  &:before {
    display: block;
    width: px-to-rem(20);
    height: px-to-rem(2);
    background-color: $palette-grey-500;
    content: '';
    transform: rotate(45deg);
    position: absolute;
    top: px-to-rem(9);
  }
}
//----------------------------------------------
// Results list
//----------------------------------------------

.rf-search__results {
  list-style: none;
  position: absolute;
  top: calc(100% - 2px);
  width: 100%;
  background-color: white;
  z-index: 50;
  border: px-to-rem(1) solid $palette-bmj-grey-100;
  border-radius: 0 0 px-to-rem(3) px-to-rem(3);
  padding: 0;

  // Highlighted string
  mark {
    color: $palette-pink-500;
    background-color: transparent;
    font-weight: bold;
    padding: 0;
  }

}

.rf-search__resultsItem {
  padding: 0 px-to-rem(16);
}

.rf-search__title {
  color:  $palette-pink-500;
  font-size: 0.8rem;
  margin-bottom: 0;
}

.rf-search__resultsItem--expanded{
  box-shadow: inset px-to-rem(5) 0 0 $palette-pink-500;
  border-bottom: 1px solid $palette-bmj-grey-100;
  padding: px-to-rem(12) px-to-rem(16) px-to-rem(16);

  .rf-search__resultsItem__link {
    padding: px-to-rem(2) 0 px-to-rem(5);
  }
}

.rf-search__resultsItem__link--topic {
  font-weight: bold;
}

.rf-search__resultsItem--more {
  padding: px-to-rem(16) px-to-rem(16) px-to-rem(32);
}

.rf-search__resultsItem__link {
  padding: px-to-rem(12) 0;
  display: block;
  color: $black-charcoal;
}

.rf-search__resultsItem__type {
  font-size: px-to-rem(14);
  color: $palette-grey-a500;
}

//----------------------------------------------
// Results sub list
//----------------------------------------------

.rf-search__resultsSub {
  list-style: none;
  padding: 0 0 0 px-to-rem(1);
  display: flex;
  flex-wrap: wrap;
  gap: px-to-rem(4) px-to-rem(8);
}

.rf-search__resultsSubItem {
  display: flex;
  align-items: center;

  &:before {
    display: block;
    height: px-to-rem(4);
    width: px-to-rem(4);
    border-radius: 100%;
    content: "";
    background-color: $black;
    margin-right: px-to-rem(8);
  }
}

.rf-search__resultsSubItem__link {
  color: $palette-blue-500;
  font-size: px-to-rem(14);
}
// Not great but only way to target a cm topic and show the logo on the quick links
.rf-search__resultsSubItem__link--como {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg version='1.1' class='co-morbidity-icon-pink' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 30 30' enable-background='new 0 0 30 30' xml:space='preserve'%3E%3Cg%3E%3Cg%3E%3Cpath fill='%23C50084' d='M13.403,11.642c1.223-2.384,3.7-4.019,6.564-4.019c2.285,0,4.326,1.039,5.679,2.669l4.119-4.119 c-2.416-2.68-5.905-4.375-9.798-4.375c-4.644,0-8.721,2.404-11.074,6.029C10.865,8.429,12.489,9.826,13.403,11.642z'%3E%3C/path%3E%3Cpath fill='%23C50084' d='M19.967,22.378c-2.864,0-5.341-1.635-6.564-4.019c-0.914,1.815-2.538,3.212-4.511,3.815 c2.353,3.626,6.43,6.029,11.074,6.029c3.893,0,7.381-1.695,9.798-4.375l-4.119-4.119C24.293,21.339,22.251,22.378,19.967,22.378z'%3E%3C/path%3E%3Cg%3E%3Ccircle fill='%23C50084' cx='6.736' cy='15' r='6.5'%3E%3C/circle%3E%3C/g%3E%3C/g%3E%3Cpolygon fill='%23FFFFFF' points='11.207,14 7.707,14 7.707,10.5 5.707,10.5 5.707,14 2.207,14 2.207,16 5.707,16 5.707,19.5 7.707,19.5 7.707,16 11.207,16 '%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center right;
  padding-right: px-to-rem(24);
}