.treatment-table {

  &.container .container {
    padding: 0 !important;
  }

  h3 a {
    cursor: pointer;
    width: 100%;
    display: block;
    color: $palette-blue-500;
  }

  h3 a:hover {
    & > span > span:first-child {
      text-decoration: underline;
    }
  }
  h3 a .material-icons {
    span + span {
      display: none;
    }
    span:first-child {
      display: block;
    }
  }

  h3 {
    a.show-panel {
      .material-icons {
        span:first-child {
          display: none;
        }
        span:last-child {
          display: block;
        }
      }
    }
  }

  h3 a > span + span {//or .hidden-sm-down
    float: right;
  }
  h3 a > span + span.icon {//reset for above for icons
    float: none;
  }

  .panel {
    padding: 0 0 0.5rem 0;
    float: left;
    clear: left;
    display: block;
    width: 100%;

      &:first-child {
        border-top: none 0;
      }
  }

  .panel-heading,
  .panel-group {//<section>
    float: left;
    clear: left;
    display: block;
    width: 100%;
    height: auto;
  }

  .panel-body {
    padding: 0;
  }

  .show-content h3 .material-icons {
    span:first-child {
      display: none;
    }
    span {
      display: block;
    }
  }

  .panel-header {
    width: 100%;
    display: block;
  }

  .panel-title {
    margin: 0;
    padding: 0;
    width: 100%;
    display: block;

    a {
      cursor: default;
      margin: 0;
      padding: 0;
      width: 100%;
      display: block;

      .titles-wrap:hover {
        cursor: pointer;
      }

      .noShow {
        position: absolute;
        left: -3000px;
        top: -3000px;
      }
    }

  }

.conditions-topic {
    .title-0 {
      padding-top: 0.5rem;
      padding-left: px-to-rem(32);
      margin-bottom: -7px;
      justify-content: space-between;
      align-items: flex-start;
    }

    .child-accordion-title {
      padding-top: 0.7rem;
      padding-bottom: 0.7rem;
      margin-left: 1rem;
      @include media-breakpoint-up(md) {
        margin-left: 0;
        }
    }

    .panel-content--level1 {
      padding-left: 1rem !important;
    }

    .treatment-table .title-01 span:before {
      content: none !important;
    }

    .treatment-table {
      .titles-wrap {
        margin-right: 1rem;
      }
    }

    .bg-line .first-child-accordion:not(:last-child):before {
      left: 26px;
    }

    .bg-line .parent:before, .bg-line .nested-accordion:before {
      left: 26px;
      top: 16px;
    }

    .new-user-icon {
      top: 9px;
    }

    .new-txtwrap {
      padding-left: 1rem !important;
      display: inline-flex;
    }

    .txtwrap {
      word-wrap: break-word;
      overflow-wrap: break-word;
      -webkit-hyphens: auto;
      -moz-hyphens: auto;
      hyphens: auto;
      padding-right: 2rem;
      display: block;
      @include ie-only(){
        padding-right: 5rem !important;
      }
    }

  .bg-line .panel-content--level1 {
    &:before {
      display: none;
      }
    }
  }

  .placeholder {
    .panel-group {
      &:not(:last-child) {
      .panel-header {
        .titles-wrap {
          border-bottom: 1px solid $palette-grey-300;
        }
      }
      }
    }
  }

  .title-01 {//************ PX padding/margin needed here for bg images etc  ****************
    display: block;
    clear: left;
    margin: 0;
    padding-left: 24px;//px important for bg img width

    span:before {
      left: 26px !important;
      top: 0;
    }
  }
  .title-03 {
    min-width: 5rem;
    display: inline-block;
    vertical-align: top;
  }

  .title-04 {
    display: inline-block; //for wrapping txt
    margin-left: -0.7rem;

    .material-icons {
      min-width: 24px;
      padding-top: 6px;

      span:first-child {
        display: block;
      }
      span {
        display: none;
      }

    }
  }

  .show-content,
  .show-panel {
    .title-04 .material-icons {
        span:first-child {
          display: none;
        }
        span {
          display: block;
        }

    }
  }
  .show-content .show-panel {
    .title-04 .material-icons {
        span:first-child {
          display: block;
        }
        span {
          display: none;
        }

    }
  }
  @include media-breakpoint-up(md) {
    .conditions-topic .title-02 {
      display: flex;
      flex-direction: column;
      @include ie-only(){
        display: block;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .title-02 {
      float: none;
      max-width: 100%;
      //flex-wrap: wrap;
    }

    .title-04 {
      margin-left: 0.5rem;
      .material-icons {
        margin-left: -4px;
      }
    }
  }


  .panel-content {
    padding: 0 1rem;//pad top/bot zero for smooth animation
    margin: 0;
    clear: both;

    .w-75 {
      width: auto !important;
    }
  }

  // Left side padding levels
  .panel-content--level1 {
    @include media-breakpoint-up(md) {
      padding-left: 8rem;
    }
    @include media-breakpoint-up(lg) {
      padding-left: 11rem;
    }
    @include media-breakpoint-up(xl) {
      padding-left: 12.5rem;
    }
  }

  // POCE specifics padding levels
  .poce_contents {
    .panel-content--level1 {
      padding-left: 1rem;
      @include media-breakpoint-up(sm) {
        padding-left: 1rem;
      }
      @include media-breakpoint-up(md) {
        padding-left: 1rem;
      }
      @include media-breakpoint-up(lg) {
        padding-left: 0;
      }
    }

    .panel-content--level2 {
      //padding-left: 2.4rem;
      @include media-breakpoint-down(sm) {
        padding-left: 1rem;
      }
    }
  }

  .show-content .panel-content {
    display: block;
  }

  .treatment-wrap {

    .small-or,
    .and-or {
      font-style: italic;
      display: block;
      margin: 0 0 1rem 0;
    }
    .small-or {
      padding: 0;
    }
    .and-or {
      background-color: $palette-light-blue-900;
      padding: 0.5rem 1rem
    }

    .treatment-box {
      background: $palette-blue-040;
      border: 1px solid $palette-blue-grey-200;
      padding: 0.9rem;
      margin-bottom: 1rem;

        .small-or,
        .and-or {
          margin: 1rem 0;
        }
        .and-or {
         margin-left: -1rem;
         margin-right: -0.9rem;
         position: relative;
         left: 1px;
        }

        .reference {
          display: inline;
          .morePop {
            padding-left: 1rem;
          }
        }

    }
    p {
      margin: 0.5rem 0 0 0;
    }
    p:first-child {
      margin-top: 0;
    }

  }

  @media (min-width:1024px) {
    .title-04 {
      max-width: 900px;
    }

    /* new hack starts (new long title wrap style 27/10/17) ***************** ..... */
    .title-04 {
      width: 100%;
      position: relative;
    }
    .title-04 .material-icons {

      float: left;
      clear: right;
      width: 24px;
      height: 24px;
      margin-bottom: -24px;
    }
    .txtwrap {
      width: 100% !important;
      padding-left: 0;
      @include ie-only(){
        padding-right: 3rem;
      }
    }
    /* new hack ends **************** ..... */
  }

  .badge {
    border-radius: 0;
  }

  img.cochraneInline {
    padding: 0 .1rem;
    width: 5.5rem;
  }
  .btn.btn-link {
    padding: 0;
    min-width: initial;
  }
}

//--------------------------------------------------------------
// TECH-DEBT
// Set icons behind link to stop them being clickable and triggering bug: BP-4338
//--------------------------------------------------------------
.accordion-toggle .material-icons {
  z-index: -1;
}

.complication-table .accordion-toggle .material-icons {
  z-index: auto;
}
