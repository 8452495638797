.specialties-list {
  .specialty-list {
    column-count: 3;
    margin: 0;
    width: 100%;

    @media only screen and (max-width : 768px) {
      column-count: 2;
    }

    @media only screen and (max-width : 480px) {
      column-count: 1;
    }

    li {
      margin-bottom: .8rem;

      @media only screen and (max-width : 480px) {
        margin-bottom: 1.4rem;
      }
    }

    hr {
      background-color: $white;
      border-top: 1px dashed $palette-blue-grey-700;
      margin: .5rem auto .5rem 0;
      max-width: 260px;
    }
  }
}

.specialties, .case-reports  {
  .tabCard + .card .card-block {overflow: hidden; }

  ul.topic-list {
    column-count: 1;
    padding: 0;
    li {
      list-style-type:  none;
      line-height: 2.5;
    }
  }

  .card {
    .card-block {
        .nav-pills {
          border-bottom: 1px solid $palette-grey-400;
        }
        h2 {
          border-bottom: 1px solid $palette-grey-300;
        }
    }
  }
  .breadcrumb {
    min-height: 2.5rem;
    padding: 0;
  }

  .iconAnim {
    display: none;
  }

  .card-block {
    @include ie-only {
      height: auto;
    }
  }
}


// A to Z Index - responsive Horizontal Nav

/* Basic Styles */

ul.a_z {
  padding: .33rem 0 .33rem .55rem;
	margin: 0;

  li {
    float: left;
    min-width: 46px !important; //important to have a min-width for js
    text-align: center;
    list-style-type:  none;
      a {
        font-weight: bold;
        color: $black !important;
        &:hover,
        &:active {
          background-color: $palette-grey-200;
          color: $palette-pink-500 !important;
        }
      }
  }
  a {
    display: inline-block;
    text-align: center;
    text-decoration: none;
  }
  li:last-child a {
    border-right: 0;
  }

  #a-zBtn {
    display: none;
  }
}

/*Styles for screen 767px and lower*/
@media screen and (max-width: 767px) {
	ul.a_z {
    padding: 0;
    position: relative;
    overflow: hidden;

    &.mobile-view {
      padding-right: 45px;
      padding-left: 0 !important; //this is important!

      .reveal-li {
        display: none;
        opacity: 0;
        transition: opacity 0.25s ease-in-out;
      }

      #a-zBtn {
        display: block;
        visibility: visible;
        opacity: 1;
        width: 50px;
        height: 50px;
        cursor: pointer;
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 10px 0 6px 0;

        & > span {
          width: 100%;
          height: 100%;
          border-left: 1px solid $palette-grey-300;
          display: block;
        }
        span span {
          width: 100%;
          position: absolute;
          top: 44%;
          left: 0;
        }
        span.up {
          display: none;
        }
        &.arrow-up {
          .down {display: none; }
          .up {display: block; top: 38%; }
        }

      }
    }

    &.open {
      .reveal-li {
       display: block;
      }
    }

  &.fade-li {
      .reveal-li {
        opacity: 1;

      }
  }

  	}

}

// Animated Accordion Icon  ????
#pull {
  .rotate{
    -moz-transition: all .5s linear;
    -webkit-transition: all .5s linear;
    transition: all .5s linear;
  }

  .rotate.down{
      -ms-transform:rotate(180deg);
      -moz-transform:rotate(180deg);
      -webkit-transform:rotate(180deg);
      transform:rotate(180deg);
  }
}
