.renalHandBookModal {

  padding-right: 0;
  .modal-dialog {
    max-width: 53rem;
    height: 650px;
    .modal-content {
      padding: 0.5rem;
      height: 650px;
      max-height: 650px;
      .modal-header {
        background-color: $white !important;
        position: relative;
        .modal-title {
          display: flex;
          justify-content: center;
          width: 100%;
          font-size: 2.25rem;
          font-width: normal;
        }
        button {
          &.close {
            > span {
              color: $black;
              font-size: 1.5rem;
              position: absolute;
              right: 0;
              top: -0.375rem;
            }
          }
        }
      }
    }
  }
}
#mobile-rdh{
  a {
    &:hover {
      span.icon {
        display: inline-block;
        text-decoration: none;
      }
    }
  }
}