//all ipads
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (-webkit-min-device-pixel-ratio: 2) { 
    #mainSearchBtn {right: 1rem !important; }
}

//all ipads portrait
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio: 2) {
    #panels-x2 .row div.col-md-6:first-child {
        margin-top: 1rem !important; 
    }
}