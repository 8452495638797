#recent-updates {
    //resets start
    #recent-updates-tabs.card {box-shadow: none !important; }
    section.card-block,
    section.card-block:first-child + .card-block {padding: 1.5rem 1.5rem 2rem 1.5rem; };
    ul, li {list-style: none; margin: 0; padding: 0; }
    //resets end

    .updateslist {
        & > li {
            margin-bottom: 1.5rem;
        }

        ul {
            margin: 1rem 0 0 0;

            & li {
                padding-top: 1.5rem;
                padding-left: 2rem;
            }
            & li:first-child {
                padding-top: 0;
            }
        }

        .updatedate {
            font-weight: bold;
        }
    }

    .updateslist > li > a,
    .updatedate,
    .updatetopic,
    .updateheadline {
        display: block;
        width: 100%;
    }
    .updateheadline .material-icons {position: relative; top: -0.2rem; font-size: 1.4rem; }

    @media only screen and (min-width : 768px) {//desktop
        section.tab-pane {min-height: 500px !important; }
    }

    @media only screen and (max-width : 768px) {//mobile
        #recent-updates-tabs {
            .nav-item {
               text-align: center;
               width: 100%;
            }

        }
    }

    @media only screen and (max-width : 480px) {//extra small mobile/device
        #recent-updates-tabs {
            .nav-tabs.animate {//resolves extreme mystery of "border" (:before ?) on .nav-tabs when .nav-items stacked
               width: 100%;
               float: left;
               box-shadow: none !important;
               margin-bottom: -3px;
               padding-bottom: 3px;
            }
            .nav {
                display: block;
            }
            .nav-item {
                flex: none;
                float: left;
                clear: left;
                display: block;
                border-bottom: 1px solid #eee;
            }
       }
    }

    #by-specialty-changes ul.updateslist > li {//a[data-toggle="collapse"]
        & > a {
            padding-left: 1rem;
        }

        & > a:before,
        & > a.collapsed:before {
            font-family: 'Material Icons';
            content: '\E5CF';
            color: $palette-pink-500;
            display: block;
            width: 1rem;
            position: absolute;
            left: 1.2rem;
        }
        & > a[class='']:before {
            content: '\E5CE';
        }
    }

    /*  #all-changes { } #npce-changes { } #by-specialty-changes { }   */
}