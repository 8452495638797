 // Patient Leaflets A - Z Browser
.patientLeaflets {
  .card {
    .card-block {
        .nav-pills {
          border-bottom: 1px solid $palette-grey-400;
        }
        h2 {
          border-bottom: 1px solid $palette-grey-300;
        }
    }
  }
  ul.a_z {
    li {
      min-width: 43px !important; //important to have a min-width for js
    }
  }
  .iconList {
    ul > li:before {
      content: none !important;
      }
    .list-group-item {
      padding: 1rem 0 1.5rem 0;
      a {
        display: block;
        line-height: 0;
        &:hover {
          color: $palette-pink-500;
        }
        .icon-bp-icons-patient-leaflets {
            &:before {
                font-size: 1.9rem;
                color: $black-primary;
                position: relative;
                top: 8px;
                margin: 0 10px 0 -6px;
                line-height: .1;
            }
        }
      }
      a:hover .icon-bp-icons-patient-leaflets:before { // icon hover
        color: $palette-pink-500;
      }
      p {
        display: block;
        margin: 1rem 0 1rem 2.125rem !important;
      }
    }

  }
  .card .card-block p {
    a {
      &:hover {
        color: $palette-pink-500 !important;
      }
      &:before {
        font-family: 'bmj-icons';
        color: $black-primary;
        content: "\44";
        font-size: 2rem !important;
        position: relative;
        top: 10px;
        margin: 0 5px 0 -5px;
      }
    }
    a:hover .icon-bp-icons-calculators:before { // icon hover
      color: $palette-pink-500;
    }
  }
}

// Patient Leaflet A - Z override
.patientLeaflets #atoz-list {
  margin: 1.25rem;
}

// Patient Leaflet tab active text color override
.patientLeaflets .card .card-block .nav-link.active {
  color: $black-primary
}


//printable patient leaflets
.patient-leaflet {
  .back-link, h1 {
    font-size: 2rem;
    @include media-breakpoint-up(sm) {
      font-size: 2.8125rem;
    }
    font-family: "interfaceregular", Arial;
    margin: 2rem 0 1rem 0;
    .back-target {
      color: rgba(0, 0, 0, 0.75);
    }
  }
  .patient-leaflet-header {
    background-color: $palette-blue-500;
    h1, p {
      color: $white;
    }
    hr {
      background-color: $white;
      margin-left: -1rem;
      margin-right: -1rem;
    }
    p {
      font-size: 1.125rem;
    }
    .cta {
      height: 1.5rem;
      a {
        color: $white;
        .icon {
          font-size: 2.5rem;
          &.icon-bp-icons-print {
            font-size: 1.5rem;
            padding-right: 0.75rem;
          }

        }
      }
    }
  }
    .patient-leaflet-subheading {
        font-weight: bold;
    }
  .patient-leaflet-body{
    ul {
      > li {
        margin-bottom: 0.5rem;
      }
    }
    p.patient-leaflet-intro {
      font-weight: bold;
    }
    p:last-of-type {
      margin-bottom: 0;
    }
  }
  .patient-leaflet-footer {
    background-color: #FAFAFA;
    p {
      font-size: 0.875rem;
      a {
        text-decoration: underline;
      }
    }
  }
}

.disclaimer-grid {
  display: none;
}