.tmp {

    .card-block  {
        @include lists;
    }

    .col-md-3 {
        ul {
            li {
                word-break: break-word;
                strong {
                    display: block;
                    overflow-wrap: break-word;
                }
                span.reference {
                    margin-left: 0.25rem;
                }
            }
        }
    }

    @include media-breakpoint-up(md) {
        .col-md-3 ul,
        .col-md-3 ul li {
            padding: 0;
        }
    }


    .col-md-3 > ul:first-child {
        padding-top: 1rem;
    }

    span.reference span {display: none; }

    p span.reference,
    h1 span.reference,
    h2 span.reference,
    h3 span.reference,
    h4 span.reference,
    h5 span.reference,
    h6 span.reference {
        display: inline;
        margin: 0;
        padding: 0;
        white-space: nowrap;
    }

    table.tblStyle01 {
        width: 100%;
        text-align: left;

        caption {
            position: absolute;
            left: -3000px;
            top: -3000px;
        }

        thead {
            th {
                text-align: center;
                padding: 0.4rem 0.6rem;
                background-color: $palette-light-blue-900;
                text-transform: uppercase;
                font-weight: normal;
            }
            th:first-child {
                text-align: left;
                padding-left: 1rem;
            }
        }
        tbody {
            th,
            td {
                padding: 0.5rem;
                vertical-align: top;
            }
            tr + tr {
                td:first-child {
                    padding-top: 0rem;
                    padding-bottom: 0.7rem;
                }
                td > .material-icons {
                    float: left;
                    margin-right: 0.4rem;
                    position: relative;
                    top: 0.7rem;
                }
                td {
                    p {
                        margin-top: 1rem;
                        margin-bottom: 0;

                        &:first-child {
                            margin-top: 0;
                        }
                    }
                }
                td > a {
                    display: block;
                    margin: 0.4rem 0;
                }

            }

            tr:first-child {
                th,
                td {
                    padding-top: 0.7rem;
                }
                td {
                    text-align: center;
                }
            }

            tr td:first-child,
            tr th {
                padding-left: 1rem;
            }

            &:nth-child(odd) {
                background-color: $palette-blue-040;
            }
            &:nth-child(even) {
                background-color: $white;
            }

        }

    }
    @media only screen and (min-width : 992px) {//bigger, up
        & > .card .card-block p.w-75 + h3,
        & > .card .card-block ul.w-75 + h3 {width: 75% !important; display: inline-block; margin: 0.5rem 0 1rem 0 !important}
    }

}

.subscriptionPg {//"Subscription not detected" page...
    #globalNav button, #panel-nhs *, #globalNav-ul, #searchBoxForm, #mainNavbarNav ul, #footer .row .row dl:first-child, #feedBackBtn {display: none !important; }
    #navbarNav {height: 30px; }
    #mainNavbarNav {height: 50px; }
    #panel-nhs {box-shadow: none; background-color: transparent; }
}

.container.tmp.topic.main-container{
 > .row {
     margin: 0;

     @include media-breakpoint-up(sm) {
         margin-right: -8px;
         margin-left: -8px;
     }

     @include media-breakpoint-up(md) {
         margin-right: -12px;
         margin-left: -12px;
     }
 }
}

#diffUnCommonGroup {
    summary {
        h3 {
            display: flex;
            align-items: center;
                &  span.icon-bp-icons-red-flag {
                    &:before {
                        position: relative;
                        top: 5px;
                        line-height: 0.2;
                    }
                }
            }
        }
}

#diffCommonContainer, #diffUnCommonContainer, #diffConditionTable {
    button {
        outline: none;
        &:focus-visible {
            outline: revert;
            outline: -webkit-focus-ring-color auto 1px;
        }
    }
    @include ie-only {
        button {
            outline: none;
            &:focus {
             outline: 1px dotted black;
            }
            &:hover, &:active { outline: none; }
        }
    }
    details {
      summary {
          &:focus-visible {
            outline: revert;
            outline: -webkit-focus-ring-color auto 1px;
          }
      }
        @include ie-only {
            summary {
                outline: none;
                &:focus {
                 outline: 1px dotted black;
                }
                &:hover, &:active { outline: none; }
            }
        }
    }
}
