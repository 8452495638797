.ekko-lightbox {
  .modal-header {
    padding-left: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .modal-footer {
    font-style: italic;
    text-align: center !important;
  }

}

[data-toggle="lightbox"] {
  &:hover {
    cursor: pointer;
  }
}