@import "../sass/mixins";
@import "../sass/variables";
@import "functions/calc";

@import "project/bmj-icons";
@import "project/platform-overrides";
@import "project/main-nav";
@import "project/footer";
@import "project/accordion";
@import "project/links";
@import "project/local-guidelines";
@import "project/global-elements";
@import "project/topic-tables";
@import "project/topic-landing";
@import "project/tmp";
@import "project/access";
@import "project/srp";
@import "project/utilities";
@import "project/treatment-tables";
@import "project/popover-tooltip";
@import "project/recent-updates";
@import "project/specialties";
@import "project/calculators";
@import "project/drugs-db";
@import "project/multimedia";
@import "project/patient_leaflets";
@import "project/evidence";
@import "project/cme";
@import "project/topic-nav";
@import "project/utilities";
@import "project/ipad";
@import "project/waves";
@import "project/fonts";
@import "project/browserupdate";
@import "project/notifications";
@import "project/como-page";
@import "project/poce-table";
@import "project/ekko-lightbox-overrides";
@import "project/como-icon";
@import "project/rdh";
@import "project/details-summary";
@import "project/dialog";

@import "project/quick-links";

@import "project/como-icon";

@import "project/atoz-filter.scss";
@import "project/tooltip-new-feature";
@import "project/guidelines";
@import "project/paywall";

@import "project/search-bar";
@import "project/clean-bootstrap";
@import "project/main-search";
@import "project/typography";
@import "project/diff-row";

@import "external/pikaday";

@import "header/header";
@import "header/navbar";
@import "header/top-section-header";
@import "login/login-page";
@import "ping/access-institution";
@import "project/bp-content-filter";
@import "project/bp-local-guideline-form";
@import "project/bp-form-elements";


@import "widgets/chat";

// project css
  .doc-jumbotron {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: ($toolbar-height * 2);

    @include media-breakpoint-down(sm) {
      h1 {
        @include typography-display-3;
      }
    }

    @include media-breakpoint-up(lg) {
      .navdrawer-permanent ~ & {
        margin-left: $navdrawer-width-mobile;
      }
    }
  }

  .doc-main {
    @include media-breakpoint-up(lg) {
      .navdrawer-permanent ~ & {
        margin-left: $navdrawer-width-mobile;
      }
    }
  }

  .doc-toolbar {
    position: fixed;

    @include media-breakpoint-up(lg) {
      .navdrawer-permanent ~ & {
        margin-left: $navdrawer-width-mobile;
      }
    }
  }

/* Clearfix */
.clearfix {
  &:before,
  &:after {
    content: " ";
    display: table;
    }
}
.clearfix:after {
    clear: both;
}
.clearfix {
    *zoom: 1;
}

.remove {position: absolute; left: -3000px; top: -3000px; }
.show2 {display: block; }
.hide2 {display: none; }
.hidden2 {visibility: hidden; }
.visible2 {visibility: visible; }


#case-report-article {
    .fig-inline {
        .vjs-social-share-links {
            a {
                cursor: pointer;
                color: #fff;
                &:hover {
                    text-shadow: -1px 1px 20px #fff, 1px -1px 20px #fff, -1px 1px 20px #fff, 1px -1px 20px #fff;
                }
            }
        }
    }
}


:focus-visible {
  outline: -webkit-focus-ring-color auto px-to-rem(1);
}

:focus {
  outline: -webkit-focus-ring-color auto px-to-rem(1);
}

:focus:not(:focus-visible) {
  outline: none;
}

.border-b-dark-blue {
  border-bottom: 1px solid $palette-blue-a900!important;
}
.border-t-dark-blue {
  border-top: 1px solid $palette-blue-a900!important;
}
.text-dark-blue {
  color: $palette-blue-a900!important;
}
.text-grey-light {
  color: $black-charcoal;
}
.invest {
  .card .card-block.urgent {
    padding: 1rem !important;
    border: 2px solid $palette-pink-500;
    width: 75%;
    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    ul.w-75 {
      width:90%!important;
      text-indent: 0;
    }

    ol.w-75 {
      width:90%!important;
      text-indent: 0;
    }

  }
  .rowDetails {
    .evidence-accordion {
      .title .material-icons span {
        color: $palette-pink-500;
      }
    }

    h3, h4, h5, h6, h7 {
      width: fit-content;
    }
  }
}

.invest {
  .card .card-block.urgent {
    padding: 1rem !important;
    border: 2px solid $palette-pink-500;
    width: 75%;
    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    ul.w-75 {
      width:90%!important;
      text-indent: 0;
    }

    ol.w-75 {
      width:90%!important;
      text-indent: 0;
    }

  }
  .rowDetails {
    .evidence-accordion {
      .title .material-icons span {
        color: $palette-pink-500;
      }
    }

    h3, h4, h5, h6, h7 {
      width: fit-content;
    }
  }
}

#onetrust-consent-sdk #onetrust-policy-text a:focus,
#onetrust-consent-sdk #onetrust-banner-sdk *:focus {
  outline-color: white !important;
  outline-width: 2px !important;
}