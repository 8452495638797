#mainNav {
    float: left;
    width: 100%;
    padding: 0;
    min-height:auto;

.navbar-nav {
    .nav-item.active {
        background-color: $palette-pink-500;
    }
}


@media only screen and (max-width : 992px) {//smaller mid size down
        #header #mainSearch {
            clear: both;
        }

        #mainNav, {
        display: none;
    }

    #navbarNav ul#globalNav-ul li.main {
        display: block;
    }
                        }
}