.serp {
    margin-bottom: 1rem;

    &.container > .card {
        background-color: transparent;
        box-shadow: none;
        border-bottom: none;
        z-index: 0;
        flex: none;
    }
    .search-lang {
        margin-top: -1.5rem;
    }
    .card {
        border-radius: 0 !important;
        border-bottom: 1px solid $palette-grey-300;

        a.card-link {
            display: block;
            line-height: 3.5;
            margin-left: 0;
        }
        &.tabCard {
            width: 100%;
            margin-bottom: 1rem !important;
        }
    }

    .card-group {
        h4 + p,
        h3 + div,
        h4 + div {margin-top: 0.8rem; margin-bottom: 0; }
    }
    .serp_detail {
        margin-top: -4rem; // to align with top
    }

    .lang {
        #searchByLanguage {
            list-style: none;
            padding: 0 0 0.5rem 0;
            text-align: right;
            margin: 0;

            div {
                display: inline;

                button {
                    display: inline-block;
                    margin-left: 0.5rem;
                    cursor: pointer;
                    border: none;
                    color: $palette-blue-500;
                    outline: none;
                    &:focus-visible {
                        outline: revert;
                        outline: -webkit-focus-ring-color auto 1px;
                    }
                    &.active,
                    &:hover {text-decoration: underline; color: $palette-pink-500;}
                    &:active {outline-width: 1px; }
                    @include ie-only {
                        &:focus {
                          outline: 1px dotted black;
                        }
                    }
                }
            }
        }
    }
}

.serp.mobile-padding {
    .row.search-lang {display: block; }

    div.srpLinks,
    .card-group .card:first-child div.srpLinks,
    .card-group .card:first-child .card-title + p {display: block; }
}

.serp_detail {
    margin-bottom: 1rem;
    .card {
        border-radius: 0;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12), 0 3px 1px -2px rgba(0, 0, 0, .2);
        .serpBullet {
            li {
                p {
                    margin: 0;
                    padding: 0;
                    display: inline;
                }
                p.w-75 {
                    width: 100%;
                }
            }
        }
        .list-group-item {
            display: block;
            padding: 1rem 1.5rem .25rem;
            h4 {
                a {
                    .material-icons {
                        margin-top: -3px; // hack :-(
                    }
                }
            }
            h4 a:hover {
                span {
                    color: $palette-pink-900;
                }
                img {
                    border-bottom: none;
                }
            }
            a:hover {
                color: $palette-pink-900;
                span {
                    color: $palette-pink-900;
                }
            }
            &.hasImage {
                background-color: #404040;
            }

        }

    }
}
    // RHS Full Details Links
    .fdRHS {
        color: $palette-pink-500;
        display: block;
        margin-top: 1rem;
    }

@media (min-width: 576px) {
    .serp {
        margin-bottom: 1rem;
        .nav-tabs {
            background-color: $white;
            box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12), 0 3px 1px -2px rgba(0, 0, 0, .2);
        }
        .card-group {
            display: block;
            box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12), 0 3px 1px -2px rgba(0, 0, 0, .2);
        }
        .card {
            a.card-link {
                display: inline-block;
                line-height: 0;
                &:after {//this resolves wrapping issues
                    content: '\00a0\00a0\2022\00a0';
                    color: $black-secondary-solid;
                }
                &:last-child:after {
                    content: none;
                }
            }
            &.spc {
                a.card-link {
                    &:after {//this resolves wrapping issues
                        content: '\00a0\00a0\2022\00a0\00a0';
                    }
                    &:last-child:after {
                        content: none;
                    }
                }
            }
        }
    }
}

.serp {
    ul.list-unstyled{
        > li {
            display: flex;
            padding: 0.8rem;

        @include media-breakpoint-up(sm) {
                padding: 0;
                float: left;
                margin-right: 0.125rem;

                &:after {
                    content: '\00a0\00a0\2022\00a0';
                    color: $black-secondary-solid;
                    font-size: 11px;
                    display: flex;
                    align-items: center;
                }
                &:last-child:after {
                    content: none;
                }
            }
            > a {
                display: flex;
                align-items: center;
                white-space: nowrap;
            }
        }
    }

}


// SEARCH RESULTS PAGINATION
//--------------------------------------//
.search-pagination {
    p {
        color: $black-secondary;
        display: block;
        text-align: center;
    }
    nav {
        display: block;
        margin: 1rem 0 0 0;
    }
    ul {
        display: block;
    }
    .pagination  {
        padding:0;
    }
    .page-link{
        width: 227px; // to play nice with loader
        margin: auto;
        box-shadow:none;
        padding:1rem;
        background: $white;
        border: 0;
    }
    .page-link:active, .page-link:focus, .page-link:hover {
        background-color: $white;
        color:  $palette-pink-500 !important;
        text-decoration: none;
    }
    .progress-circular-spinner {
        border: 0.15rem dotted $palette-pink-800;
    }
    .progress-circular-left {
        left: 6px;
    }
    .progress-circular-spinner {
        top: 10px;
    }
}

@media (min-width: 767px) {// Media Queries for Pagination
    .search-pagination {
        p {
            display: inline-block;
        }
        nav {
            float: right;
            margin: 1rem 0 0 0;
        }
    }
}

.serp {// BMJ Icons overrides:: SERP Results (Left Hand Side):
//updated by DH. stop double line text wrapping
    h4[id^="calc_"] a,
    h4.icon-calculators a,
    h4[id^="patLeaflet_"] a,
    h4.icon-leaflets a,
    h4[id^="specialty_"] a,
    h4.icon-specialties a,
    h4[id^="drug_"] a,
    h4.icon-drugs a {
        padding-left: 1.8rem;
        display: block;
        &:before {
            font-family: 'bmj-icons';
            font-size: 2rem !important;
            position: relative;
            margin: 0 0 0 -2rem;
        }
        &:hover {
            color: $palette-pink-500;
        }
    }
    h4[id^="calc_"] a:before,
    h4.icon-calculators a:before {// Calculators
        content: "\66";
        top: 0.5rem; //10px
    }
    h4[id^="patLeaflet_"] a:before,
    h4.icon-leaflets a:before {// Patient Leaflets
        content: "\44";
        top: 10px;
    }
    h4[id^="specialty_"] a:before,
    h4.icon-specialties a:before {// Specialty
        content: "\56";
        top: 6px;
        font-size: 1.75rem !important;
        margin: 0 5px 0 -2rem;
    }
    h4[id^="drug_"] a:before,
    h4.icon-drugs a:before {// Drugs - not yet available   ?
        content: "\6f";
        top: 14px;
    }
}

.serp_detail {
    a {
        &.serpPL,
        &.serpPV,
        &.serpCalc {
            display: inline-flex; // in event of long titles
        }
    }

    .icon-bp-icons-patient-leaflets {
        &:before {
            font-size: 1.9rem !important;
            color: rgba(0,0,0,0.75);
            position: relative;
            top: 7px;
            margin: 0 -0 0 -6px;
            line-height: .1 !important;
        }
    }



    .icon-bp-icons-calculators { // calculators icon
        &:before {
            font-size: 1.9rem !important;
            color: rgba(0,0,0,0.75);
            position: relative;
            top: 7px;
            margin: 0 0 0 -6px;
            line-height: .1 !important;
        }
    }



    a .icon-bp-icons-pdf-extra-small-on-white {  // PDF link icon
        &:before {
            font-size: 2.75rem !important;
            color: rgba(0,0,0,0.75);
            line-height: .1;
            position: relative;
            top: 14px;
            margin: 0 -6px 0 -13px;
        }
    }

    .icon-bp-icons-procedural-videos {  // Video link icon
        &:before {
            font-size: 1.75rem !important;
            color: rgba(0,0,0,0.75);
            line-height: .1;
            position: relative;
            top: 8px;
            margin: 0 2px 0 -5px;
        }
    }

    a .icon-bp-icons-red-flag {
        &:before {
            font-size: 1.75rem !important;
            color: $palette-red-500;
            line-height: .1;
            position: relative;
            top: 8px;
            margin: 0 0 0 -5px;
        }
    }

    // Hover states
    a:hover {
        .icon-bp-icons-red-flag,
        .icon-bp-icons-calculators,
        .icon-bp-icons-pdf-extra-small-on-white,
        .icon-bp-icons-patient-leaflets,
        .icon-bp-icons-procedural-videos {
            &:before {
                color: $palette-pink-900;
            }
        }
    }
}
