.paywall{
  background-color: $palette-alert;
  padding: 1.25rem;
  > h3 {
    font-size: 1.25rem;
    font-weight: bold;
  }
  a {
    font-weight: bold;
    color: $palette-blue-800;
    &:hover {
      text-decoration: underline;
      color: $palette-blue-800;
    }
  }
  .cta {
    font-weight: normal;
    text-transform: uppercase;
    font-size: 1rem;
    padding: 1rem 0;
    border-radius: 3px;
    max-width: 150px;
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.4);
    width: 100%;
    text-align: center;
    &:hover{
      text-decoration: underline;
    }
    &:nth-of-type(1) {
      color: $palette-pink-500;
      background-color: $white;
      border: 2px solid $palette-pink-500;
    }
    &:nth-of-type(2) {
      color: $white;
      background-color: $palette-blue-800;
      border: 2px solid $palette-blue-800;
    }
  }
}
// Soft Paywall Banner
.spbanner {
  .spbanner-or {
    &:after {
      content: '';
      width: 100%;
      display: block;
      height: 1px;
      position: absolute;
      left: 0;
      top: 50%;
      background: #CCCDCD;
    }
    span {
      color: #747678;
      background: $white;
      padding-left: 0.875rem;
      padding-right: 0.875rem;
      font-size: 0.875rem;
      z-index: 2;
    }
  }
  .container {
    padding: 0.9375rem;
    max-width: 1140px;
  }
  .shadow-radius {
    box-shadow: 0px -1px 10px rgba(0, 0, 0, 0.15);
    border-radius: 20px 20px 0px 0px;
  }
  .btn {
    border-radius: 8px;
    background: #88005B;
    &:hover {
      background: $palette-pink-500;
    }
  }
  .spbanner-login-btn {
    gap: 1rem;
    flex-direction: column;
    @include media-breakpoint-up(sm) {
      flex-direction: row;
    }
    a {
      min-height: 4.6875rem;
    }

    @include ie-only(){
      a {
        height: 1px;
      }
    }
  }

  .spbanner-login {
    touch-action: none;
    @include media-breakpoint-down(xl) {
      @include ie-only {
        .container {
          width: 100%;
        }
      }
    }
    background: $white;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: block;
    z-index: 3;
    -webkit-transform: translateY(70%);
    -moz-transform: translateY(70%);
    -ms-transform: translateY(70%);
    -o-transform: translateY(70%);
    transform: translateY(70%);
    @include media-breakpoint-up(sm) {
      -webkit-transform: translateY(100%);
      -moz-transform: translateY(100%);
      -ms-transform: translateY(100%);
      -o-transform: translateY(100%);
      transform: translateY(100%);
    }
    transition: all 0.3s ease;
    .close {
      position: absolute;
      top: 1.25rem;
      right: 1.5rem;
      border: 1px solid #747678;
      border-radius: 50px;
      width: 1.4375rem;
      height: 1.4375rem;
      font-size: 21px;
      color: #747678;
    }
    h3 {
      &:nth-child(2){
        font-size: 19px;
        &:before{
          content: '';
          position: absolute;
          width: 7.3125rem;
          height: 0.25rem;
          background: #CCCDCD;
          display: block;
          top: 0;
          left: 50%;
          transform: translate(-50%,-50%);
          border-radius: 0.25rem;
        }
      }
    }
  }
  .toggle-softpaywall {
    transform: translateY(0px);
  }
  .spbanner-options {
    h3 {
      @include media-breakpoint-between(sm, md) {
        font-size: 1.25rem;
      }
    }
    .container {
      flex-direction: column;
      @include media-breakpoint-up(sm) {
        flex-direction: row;
      }
    }
    position: relative;
    z-index: 2;
  }
}

// We want small to take precedence over x-btn-lg
.x-btn-lg.small {
  font-size: $small-font-size;
  font-weight: $font-weight-regular;
}

.spbanner-login-btn .x-btn {
  width: 100%;

  border-width: 2px;
  border-radius: 8px; // Extra round. XXX: Should be 0.5rem instead of 8px.
}

// Needs higher specificity than .spbanner-login-btn .x-btn
a.spbanner-institution.x-btn {
  justify-content: center;
  @include media-breakpoint-up(lg) {
    display: flex;
    justify-content: left;
  }
}

.spbanner-icon {
  display: none;
  @include media-breakpoint-up(lg) {
    display: inline-block;
  }
}

.spbanner-material-icon {
  background-position: center;

  // XXX: The styles below aren't Material-specific. They also apply to .icon-medical
  // So they should probably live in .spbanner-icon

  vertical-align: middle;

  margin-right: 0.5rem;
  width: 2rem;
  height: 2rem;
  background: rgba(42, 110, 187, 0.1);
  border-radius: 50%;

  align-items: center;
  justify-content: center;

  padding-top: 0.25rem;
  color: $palette-blue-500;
}

.spbanner-person-icon {
  padding-top: 0.7rem;
  font-size: 1rem;
}

.icon-bp-icons-library.spbanner-icon {
  padding-top: 0.5rem;
  font-size: 0.9rem;
  width: 2.3rem;
}

.icon-medical {
  background: #B10077 url("/images/medical_services.svg") no-repeat;

  background-size: 0.8125rem;
  background-position: center;
}

.spbanner-open-athens {
  text-transform: initial;

  display: inline-flex;
  justify-content: center;

  overflow: hidden !important; /* For IE11 */
}

.js-spbanner-space {
  margin-bottom: 5.3rem;
}

