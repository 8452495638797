//Access: Modal & Inline Reg form
input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px $white inset;
}
// Chinese Variant align email address on Chinese modal
html:lang(zh-cn) #feedbackForm .row:nth-child(8).smTxt .col-md-3:nth-child(2) {
    padding-right:0;
}
#regFail.modal-md {
    max-width: 44rem;

    div h2 {
        font-size: 1.8rem;
    }

    div p,
    div p:first-child {
        font-size: 0.875rem !important;
        padding-left: 0;
    }
    .support {
        display: inline-block;

        &:before {
            content: 'support@';
            display: inline-block;
            width: 3.9rem;
            height: 1.5rem;
            cursor: text;
        }
    }

    .modal-header.row {
        button.close {
            position: absolute;
            top: -6px;
            right: 6px;
        }
    }
}

#regSuccess {
    max-width: 50rem;

    div h2 {
        font-size: 1.8rem;
    }

    div p               {padding-left: 0; font-size: 1rem !important; }
    div p:first-child   {padding-left: 0; font-size: 1rem !important; }


    ul {
        padding: 0 0 1rem 0;
        margin: 0;

        li {
            list-style: none;
            display: inline-block;

            &:first-child {
                margin-right: 1rem;
            }
        }

    }

    .modal-header {
        button.close {
            position: absolute;
            top: 6px;
            right: 8px;
        }
    }

    @media only screen and (max-width: 768px) {//mobile
        #continueToSite {
            margin-bottom: 2rem;
        }
    }

}

.modal-registration-form,
.registration-form,
.modal-registration-success,
.login-form {
    .modal-dialog {
        font-size: 1rem;
        margin: 0;
        width: 100%;
    }

    .modal-lg {
        max-width: 60rem;
    }

    .modal-header {
        h2 {
            color: inherit;
            font-size: 2.125rem;
            font-weight: 400;
            letter-spacing: 0;
            line-height: 2.25rem;
        }
    }

    .modal-title {
        font-size: 1.5rem;
    }

    button.close {
        span {
            font-size: $font-size-display-1;
            &.close-text {
                font-size: 1.5rem;
            }
        }
    }

    .modal-body {
        padding: 1.25rem 1.5rem 0;

        p:first-of-type {
            font-size: .875rem;
            padding-left: 2.2rem;
        }
        /*AMY*/
        p.support {
            padding-left:0;
        }
        /*AMY*/
    }

    .floating-label {
        border-radius: 3px;
        height: 3.125rem;
        >label {
            color: $palette-grey-600;
            padding-left: .25rem;
            top: .875rem;
        }
        &.has-value,
        &.is-focused {
            border: 2px solid #2a6ebb;
            border-radius: 3px;
            >label {
                // position
                top: 2px;
            }
        }

        &.textarea textarea {
            resize: none;
        }

    }

    .form-control {
        border-bottom: none;
        padding-left: .25rem;
        /*AMY*/
        height: 2rem;
        /*AMY*/
    }

    /*
    .form-control-feedback, .form-text {
        font-size: .875rem;
        margin-top: .1rem;
    }

     */

    .form-group.has-danger label.custom-control.custom-checkbox {//styles added for checkboxes
        .custom-control-indicator {
            border-bottom: 2px solid $palette-red-500;
        }
    }
    #feedbackForm {
        .form-control-feedback {
            top: 0;
            display: block;
        }
    }
    .login-form-inputs{
        .has-danger {
            margin-top: 40px;
        }
        .floating-label {
            .has-danger {
                &:first-of-type {
                    margin-top: 60px;
                }
            }
        }
    }
    .form-group.floating-label.has-danger {
        //margin-bottom: 2.5rem;
        border: 1px solid $palette-red-500;
        border-bottom: 3px solid $palette-red-500;

        &.is-focused {
            >label {
                color: $palette-red-500;
            }
        }
    }

    .form-group.floating-label.password-hint {
        margin-bottom: 2.5rem;

        .form-control-feedback {
            color: $palette-blue-400;
        }
    }

    .modal-footer {
        p:first-of-type {
            font-size: $font-size-body-1;
        }
    }
    .regMessaging {
        border: 1px solid $palette-grey-400;
        .regMessageHeader {
            background-color: $palette-blue-400;
            padding: .35rem;
            h3 {
                color: $white;
            }
        }
        .list-group-item {
            background-color: $palette-grey-200;
            border: none;
            font-size: $font-size-subheading;
            position: relative;
            /* Helps us control overlap */
            padding-left: 3.875rem;
            &:before {
                color: $palette-blue-400;
                content: "\72";
                font-family: 'bmj-icons';
                position: absolute;
                left: 18px;
                top: 1px;
                font-size: 2.125rem;
            }
        }
    }
    .regResponses {
        background-color: $palette-orange-500;
    }
    .regSuccess {
        background-color: $white;
        .regSuccessHeader {
            padding: .35rem;
        }
        .list-group-item {
            background-color: inherit;
            border: none;
            font-size: $font-size-subheading;
        }
    }
    // Alert customisation (move to it's own scss file !!!!!!!!!!!!!!)
    .alert {
        box-shadow: none;
        color: $white;
        font-size: 1.1875rem;
        a {
            color: #fff;
            border-bottom: 1px solid #fff;
        }
    }
}

// Media Queries for form inputwidths (sigh!)
@include media-breakpoint-up(md) {
    .form-group:nth-child(2) {
        width: 75%;
    }
    .regMessaging {
        margin-top: 0;
    }
}

.registration-form {
    .floating-label {
        background-color: $white;
    }
    .regMessaging {
        .list-group-item {
            background-color: $white;
        }
    }
    h2 {
        color: $white;
        font-size: $font-size-headline;
    }

}

// Media Queries for single column form - tablet down
@include media-breakpoint-down(md) {
    #feedbackForm,
    #regformModal {
        margin-bottom: 1rem;
    }
}

// Login Form Overrides
.login-form {

    .floating-label {
        background-color: $white;
    }
    h2 {
        color: $white;
        font-size: $font-size-headline;
        &.form-message {
            color: inherit;
            font-size: $font-size-display-1;
        }
    }
    p {
        font-size: $font-size-subheading;
    }
    span {
        font-size: $font-size-body-1;
    }
    .btn::before{
        background-color: rgba(0,0,0,0);
    }
    .altLogin {
        a.btn {
            border: 3px solid $palette-pink-900;
            background-color: $white;
            color: $palette-pink-900;

            &.institution-login {
                max-width:252px;
                margin:0 auto;

                span {
                    white-space: normal;
                    max-width: 153px;
                    display: inline-block;
                    line-height: 1.3rem;
                }
                &:before {
                    color: $palette-pink-900;
                    opacity: 1;
                    position: absolute;
                    top: 27px;
                    background-color: unset;
                    width: 33px;
                    left: 10px;
                }
            }
        }
        a:hover.btn {
            background-color: $palette-pink-900;
            color: $white; &.institution-login {
            &:before {
                color: $white;
            }
        }
        }
    }

    & > .row + .row .col-md-6.altLogin,
    p.altLogin {
        margin-bottom: 2rem;
    }

    #altLogin-list {
        margin: 0;
        padding: 0;

        li {
            list-style: none;
            margin-bottom: 0.5rem;
        }
    }
}

#inlineLoginNoIp {
    .floating-label {
        background-color: $white;
    }
}

//Fix for overlapping forgot pass link / validation error
#lfInputPass, #lfInputPass-error {
    float: left;
}

#forgotPass {
    clear: both;
    margin: 0.8rem 0 1.2rem 0;
}

#feedBackBtn {//bottom right feedback button styles ...

    border-radius: 4px;
    background-color: $white;
    border: 1px solid $palette-pink-600;
    max-width: 150px;
    height: 40px;
    font-size: 16px; //must be fixed px size
    color: $palette-pink-600;
    display: inline-block;
    padding: 9px 13px 13px;

    @media only screen and (max-width: 358px) {
        margin-top: px-to-rem(-44) !important;
    }

    @media only screen and (max-width : 768px) {
        float: right;
        clear: both;
        margin-right: 1rem;
        margin-top: -50px;
        position: fixed;
        bottom: 10px;
        right: 0;
    }

    @media only screen and (min-width : 767px) {
        min-width: 128px;
        position: fixed;
        bottom: -3px;
        right: -2px;
        z-index: 21;//do not change
    }

    &:hover {
        text-decoration: underline;
    }
}

#feedbackContainer {

    .modal-header {
        & > .row {
            width: 100%;
        }
        h2 {
            width: auto;
            display: inline-block;
            padding-right: 6rem;
        }
        button.close {
            position: absolute;
            right: 1rem;
            top: 0;
        }

    }

    .floating-label.textarea {
        padding: 0;
        height: 100px;
    }

    .floating-label.textarea textarea {
        height: 96px; //allows for showing the think bottom border for is-focus etc
        border: none 0;
        padding: 1rem 0.5rem 0.5rem 0.3rem;
        width: 100%;
    }

    .floating-label.textarea {
        padding: 0;
    }

    fieldset.form-group {
        legend {
            position: absolute !important;
            left: -3000px  !important;
            top: -3000px !important;
        }
        label {
            display: block;
            width: 100%;
            margin-top: 0.5rem;
        }
        legend + label {
            margin-top: 0;
        }
    }

    .btn-lg {
        font-weight: bold;
        padding: 1rem 4.5rem;
    }

    p {padding-left: 0; }

    #feedbackSubmit {
        margin-bottom: 1rem;
        font-weight: normal;

    }

    @media only screen and (max-width : 768px) {
        h1, h2, h3 {
            padding-left: 0;
        }
    }

}

#feedbackSuccess {
    &.show {
        display: block !important;
    }
    h2 {
        display: inline;
    }
    p {padding: 1rem 0 0 0; }

}

#spinner {
    display: none;
}

body.modal-open2 {
    overflow: hidden;
    padding-right: 17px;
}//model does not support double models


.modal-registration-form .form-group.floating-label.has-danger {
    margin-bottom: px-to-rem(40);
}

.visibility-hidden {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
}

.js-feedback-btn {
    @media only screen and (max-width: 768px) {
        bottom: px-to-rem(142) !important
    }

    z-index: 25;
    bottom: px-to-rem(100) !important;
}