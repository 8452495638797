// -----------------------------------------------------
// LINKS
//
// Specific links styles

// Links with calculator icons in front
.link-calculator {
  &:hover {
    color: $palette-pink-500 !important;
    &:before {
      color: $palette-pink-500 !important;
    }
  }

  .icon-bp-icons-calculators {
    position: relative;
    top: 3px;
    font-size: 1.1rem;
    //line-height: 1rem;
    color: $black-primary;
  }
}

// View / Hide all links in headers
.link-toggle {
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 0;
  &:hover {
    text-decoration: underline;
  }
  .card-header:hover & {
    text-decoration: underline;
  }
  a {
    &:hover {
      border: none;
      text-decoration: underline;
    }
  }
}