.btn.btn-primary.btn-lg  {
   &:hover {
     background-color: $white !important;
     &:before {
       background-color: $white;
     }
   }
}

button.delete-guideline {
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: $palette-pink-500;
}

.guideline-table {
  .alert {
    z-index: 1;
  }
  .guideline-table-header {
    background-color: $palette-blue-500;

    &__edit {
      padding-right: 0;
    }

    &__delete {
      padding-left: 0;
    }
    .col-header {
      color: $white;
      font-size: 16px;
      vertical-align: middle;

      &.edit-col-head {
        padding-right: 0;
        &:after {
          content: "/";
        }
      }

      &.delete-col-head {
        padding-left: 0;
      }

      &.sort {
        &::after {
          font-family: 'Material Icons';
          content: "expand_more";
          font-feature-settings: 'liga';
          -webkit-font-feature-settings: 'liga';
          transition: 0.4s;
          position: relative;
          top: 2px;
        }
        &.icon-rotate {
          &::after {
            content: "expand_less" !important;
          }
        }
      }
    }
  }
  .icon-bp-icons-delete2, .icon-bp-icons-edit2 {
    font-size: 	1.25rem;
  }
}

.guideline-disclaimer-text {
  font-size: 0.75rem;
}
//MODALS
#delete-guideline-dialog{
  .modal-dialog {
    max-width: 310px;
    width: 100%;
    @include media-breakpoint-up(sm) {
      max-width: 400px;
    }

    .modal-content {
      border-radius: 5px;
      .modal-header {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }
      .icon-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
        width: 100%;
        .icon-bp-icons-empty-file {
          position: relative;
          font-size: 45px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: $palette-grey-800;
        }
        .icon-bp-icons-cross {
          font-size: 25px;
          position: absolute;
          right: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $palette-red-400;
          transform: translate(33%, 47%);
        }
      }
      .modal-footer{
        button.btn {
          border-radius: 4px;
          min-width: 8rem;
          @include media-breakpoint-up(sm) {
            min-width: 9rem;
          }

          &.dialog-cancel {
            background-color: $palette-grey-200;
            &:hover {
              color: $palette-grey-800;
            }
          }
          &.dialog-confirm{
            &:hover {
              border: none;
              color: $white;
            }
          }

        }
      }
    }
  }
}


#q2 {
  &.has-error {
    border-color: $palette-red-500 !important;
    border-bottom-width: 4px !important;
  }
}

#create-guideline, #edit-guideline {
  input, select {
    padding: 0.5rem;
    border-radius: 4px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.247);
  }
  .form-control-feedback {
    font-size: 0.75rem;
  }
  .tt-menu.tt-open {
    background-color: $white;
    border-left: 1px solid $palette-blue-500;
    border-right: 1px solid $palette-blue-500;
    border-bottom: 1px solid $palette-blue-500;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-top: -2px;
    width: 100%;

    &:before{
      font-size: 0.8rem;
      padding: 0.5rem;
    }

    .tt-suggestion.tt-selectable{
      &.card{
        background-color: #fafafa !important;
        box-shadow: none;
        border-radius: 0;
        padding: 0.5rem;
        &:last-of-type {
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }

      &:hover{
        cursor: pointer;
        background-color: #ccc !important;
      }
    }

  }
}


#guidelineModal, #delete-guideline-dialog {
  .modal-footer {
    button.btn {
      &:hover {
        border: none;
        color: $white;
      }
    }
 }
}


.guidelines.local-guidelines-page{
  .rowDetails {
    border-bottom: none;
  }
  h2{
    border-bottom: 1px solid #ddd;
  }
  .row.rowCondition{
    background-color: $white;

    h3 {
      a {
        font-size: 1.125rem;
      }
    }
  }
}

.guidelines {
  .row.rowCondition{
    background-color: $white;
    padding-bottom: 0;
    padding-top: 1.25rem;
  }
  .row.rowDetails {
    border-bottom: none;
  }
}

#searchGuidelinesInput:focus {
    outline: 1px solid;
    outline: 5px auto -webkit-focus-ring-color;
}
