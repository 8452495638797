#comorbidities {
    .poce_contents .card-block .row {
        margin:2rem 0;
        .col-md-4 {
            padding-left:0;
        }
    }
}

.como-home-page {
    color: $black-charcoal;

    &__intro {
        p {
            &:first-child, &:last-child {
                margin-bottom: 2rem;
            }
        }
    }
}

.como-home-page__header {
    padding: 1rem 1rem;

    @media only screen and (min-width: 575px) {
        padding: initial;
    }
}

//--------------------------------
// Como-home-page instructions
//--------------------------------

.como-instructions {
    border: solid $palette-pink-500;
    border-width: px-to-rem(2) 0 px-to-rem(2) 0;
    display: flex;
    flex-direction: column;

    //full width mobile, override card-block styles
    margin-left: -1rem;
    margin-right: -1rem;
    padding-left: 1rem;
    padding-right: 1rem;

    p {
        color: $palette-pink-500;
        padding-top: px-to-rem(30);
        font-size: px-to-rem(30);
        line-height: px-to-rem(34);
    }

    img {
        width: 100%;
        height: auto;
        object-fit: cover;
    }

    ol {
        margin: px-to-rem(23) auto px-to-rem(50) px-to-rem(17);
        padding-left: inherit;

        li {
            padding-bottom: px-to-rem(10);
        }
    }
}

.como-instructions__mobile-text {
    margin-bottom: px-to-rem(24);
}

.como-instructions__desktop-text {
    display: none;
}

@include media-breakpoint-up(md){
    .como-instructions {
        border-width: px-to-rem(2);
        border-radius: px-to-rem(6);
        padding: px-to-rem(22);
        margin: unset;
        flex-direction: row;

        @include ie-only(){
            margin: 0;
        }

        img {
            width: 33%;
            object-fit: fill;
            margin-bottom: auto;

            @include ie-only(){
                width: 50%;
                height: px-to-rem(350);
            }
        }
    }

    .como-instructions__mobile-text {
        display: none;
    }

    .como-instructions__desktop-text {
        display: block;
        padding-top: 0 !important;
    }

    .como-instructions-list {
        margin-left: px-to-rem(43);
    }
}

.como-instructions__scroll {
    color: $palette-blue-500;
    cursor: pointer;
    &:hover {
        color: $palette-pink-500;
    }
}

.como-symbol {
    color: $palette-pink-500;
    display: inline;
    font-weight: bold;
}

//--------------------------------
// Como-home-page list of acute topics
//--------------------------------

.como-acute-topic-list {
    margin: px-to-rem(42) auto px-to-rem(23) auto;
}

.como-tool-topic-table{
    .como-columns {
        columns: 1;
        line-height: px-to-rem(24);

        li {
            padding-bottom: 0.5rem;
            break-inside: avoid; // stop list items from breaking across columns
        }

        @include media-breakpoint-between(md, lg){
            columns: 2;
        }

        @include media-breakpoint-up(lg){
            columns: 3;
        }
    }
}

//--------------------------------
// Not related to como-home-page
//--------------------------------

.diffDiagnosis img.overview-show-como-icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0 0;
  width: 16px;
  height: 16px;
}

.rotate-toggle{
    transform: rotate(180deg);
}

.js-como-section-toggle {
    cursor: pointer;
    .material-icons {
        top: px-to-rem(4) !important;
        margin-right: px-to-rem(35);
        @include media-breakpoint-down(sm){
            margin-right: px-to-rem(-28);

        }
    }
}

.js-como-section-heading {
    display: inline;
}

.poce_drug_msg.js-como-section {
    padding-bottom: px-to-rem(19);
    padding-left: px-to-rem(15);
    background-position: px-to-rem(48) px-to-rem(22);
    @include media-breakpoint-down(sm){
        background-position: px-to-rem(10) px-to-rem(22);
    }

    h4 {
        color: $palette-pink-500;
        font-size: 1rem;
        font-weight: 700;
        display: inline-block;
    }

    .poce_title {
        margin: 0 0 px-to-rem(10) 0;
        @include media-breakpoint-down(sm){
            margin: 0 0 px-to-rem(10) px-to-rem(20);
            padding-left: 0;
        }
    }
}
