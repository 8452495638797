#embedded-messaging {
  position: relative;
  z-index: 99;
  .embeddedMessagingConversationButton {
    bottom: 55px!important;
  }
  @media screen and (min-height: 500px) and (max-height: 600px) {
    iframe {
      height: 80vh!important;
    }
  }
  @media only screen and (min-width: 15em) and (max-width: 47.9375em){
    .embeddedMessagingFrame[class~="isMaximized"] {
      height: 50vh!important;
    }
  }

}