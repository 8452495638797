.hl7-message {
	display: flex;
	align-items: baseline;
	padding-left: 0px;
	font-style: italic;	
		
	img {
		transform: scaleY(-1) scale(1.4);
	}
}

//nav-tabs starts ******
.nav-tabs {
    background-color: $white;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12), 0 3px 1px -2px rgba(0, 0, 0, .2);

    .nav-link {
      letter-spacing: $nav-tabs-letters-off;
      color: inherit;
      &.active {
        font-weight: bold;
        letter-spacing: $nav-tabs-letters-on;
        font-size:  0.910rem;
        padding-left: 1.5rem;
        padding-right: 1.65rem;
      }
    }

    button.nav-link {
        border: none;
        background-color: $white;

        &:focus, &.active {
            background-color: $white;
        }

    }

    .nav-item a, .nav-item:first-child a {
      letter-spacing: $nav-tabs-letters-off;
      padding-left: 1.55rem;
      padding-right: 1.8rem;
      &::before {
          background-color: $palette-pink-500;
          height: 6px;
      }
      &.active {
          font-weight: bold;
          font-size: 0.91rem;
          letter-spacing: $nav-tabs-letters-on;
          padding-left: 1.55rem;
          padding-right: 1.8rem;
      }
    }
}
@-moz-document url-prefix() {//firefox
    .serp ul.nav-tabs {
        a.nav-link.active {
            padding-left: 1.35rem; padding-right: 1.65rem;
        }
        .nav-item:first-child a.nav-link.active {
            padding-right: 1.55rem;
        }
    }
    .calculators .nav-tabs {
        a.nav-link.active {
            padding-left: 1.4rem; padding-right: 1.5rem;
        }
        .nav-item:first-child a.nav-link.active {
            padding-left: 1.5rem;
        }
    }
}
//nav-tabs ends ******

@mixin lists {
    ul, ol {
        & > li {
            margin-bottom: .25rem;
        }

        li {
            p {
                margin: 0;
                padding: 0;
                display: inline;
            }

            p.w-75 {
                width: 100%;
            }
        }
    }

    ol.w-75 ol.w-75,
    ol.w-75 > li > p.w-75,
    ul.w-75 ul.w-75,
    ul.w-75 > li > p.w-75 {
        width: 100% !important;
    }

    li > p.w-75 + p.w-75 {
        display: inline-block;
        margin-top: 0.5rem;
    }
}

ol li {
    padding-left: 0;

    p {
        padding: 0 !important;
    }
}

ul li ol {
    margin: 0.5rem 0;
}

ul li p + ul li:first-child {
    margin-top: 0rem;
}

.card-block ul.non-list {
    padding: 0 !important;
    margin: 0;

    li {
        display: inline;
        margin: 0 1.4rem 0 0;

        a {white-space: nowrap; }

        p + .figure {margin-top: 1rem; }
    }

    li:before {
        content: normal !important;
    }
}

/* ********** figure/figcaption start .... ***********/
.figureWrap {
    display: flex;
    clear: left;
}
.figure {
    background-color: $palette-grey-200;
    max-width: 800px;
    margin-top: 1rem !important;
    padding: 1rem;

    .figimage {
        display: block;
        margin-bottom: 1rem;
    }

    .figcaption {
        display: block;
        max-width: 524px;
        margin-bottom: 10px;

        span {
            font-weight: normal !important;
            color: $palette-grey-800;
            text-indent: initial;

            & + span {padding-top: 0; }

            &.source {font-style: italic; }
        }

    }

    img.open-win {cursor: pointer !important; }//for js click events
    .allowWidthGrab {//this is important!
        position: absolute !important;
        left: -3000px;
        visibility: hidden !important;
        display: block !important;
    }
}


span.reference-links::after{
    content: "\a";
    white-space: pre;
}

//start: *** the following styles are elaborate work-arounds for individual pgs, caused by random html structures. e.g figures within Ps, within nested ULs!
    #tmp {
        .card-block {
            h2, h3 {width: 100%; float: left; clear: left; }
        }
    }
    #tmp.imgsVids {//override above style: safer than using :not() for all browsers
        .card-block {
            h2, h3 {float: none !important;  }
        }
    }
    .details{//convoluted, but only, css solution available for issues raised on details pg (accomidating adhoc html structures and not breaking accordian layouts
        .container.common {padding: 0 !important; }
        .rowHead {margin: 0 !important; }
        section {
            .rowCondition,
            .rowDetails {float: left; clear: left; margin-left: 0 !important; }
            .rowCondition {width: 100% !important; }
            li p {width: 100% !important; padding: 0 !important; margin: 0 !important; }
        }
    }
//end: ***

/********** figure/figcaption ends *********** */

#gridder-links {
    li {
        list-style-type: disc;
    }
    a {
        color: #b4cfee;
    }
    a:hover {
        text-decoration: underline;
    }
}

/********** como panel *********** */
.como-panel {
    background-color: $card-bg;
    box-shadow: map-get($expansion-panel-elevation-shadow, shadow);
    label {
        margin-bottom: 0;
    }
    .list-group-item {
        border: none;
        min-height: initial;
        padding: 0 $expansion-panel-inner-spacer-x $spacer;
    }
    .como-panel-title,
    .como-panel-footer {
        padding: $expansion-panel-inner-spacer-y 0;
    }
    .como-panel-title p:first-child {
        color: $palette-pink-500;
        font-size: 18px;
    }
    .cm-panel-form {
        padding: 0 $expansion-panel-inner-spacer-x $expansion-panel-inner-spacer-y;
    }
    .custom-control-input {
        width: $selection-control-indicator-size;
        height: $selection-control-indicator-size;
        left: 0;
        top: 50%;
        opacity: 1;
        z-index: 0;
        transform: translateY(-50%);
        overflow: hidden;
        appearance: none;
    }
    .custom-control-indicator {
        top: 50%;
        transform: translateY(-50%);
        overflow: hidden;
    }
    .custom-control-indicator::before {
        background-color: transparent;
    }
    .custom-control-input:checked ~ .custom-control-indicator::before {
        background-color: transparent;
    }
    .custom-control-input:disabled ~ .custom-control-description {
        color: $black-disabled-solid;
    }
    .custom-control-description {
        width: 100%;
    }
}
.como-disable{
	text-decoration:line-through;
	color: $black-disabled-solid;
}

@media (max-width:768px) {
    .como-panel {
        label {
            padding-top: $expansion-panel-inner-spacer-y;
            padding-bottom: $expansion-panel-inner-spacer-y;

        }
    }
}
.serp_detail .como-panel .card .list-group-item {
    padding: $spacer-xs 0;
}
.topic .como-panel .card {
    border-bottom: none;
    box-shadow: none;
}

.como-panel-info {
    img {
        align-self: baseline;
    }
    p {
        color: #c50084 !important;
    }
}

.other-considerations {
    border-top: 1px solid;
    width: 100%;
    padding-top: 1rem;
    margin: 1rem 0 10px 0;

    p {
        text-transform: uppercase;
        margin-bottom: 0;
    }
}
/********** como panel ends *********** */