//--------------------------------
// Institutional banner above footer
//--------------------------------

.panel-nhs {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 5rem;
    padding: 0.5rem 0;
    margin-top: 3rem;

    background-color: $white;
    box-shadow: 0 px-to-rem(-3) px-to-rem(4) px-to-rem(-5) rgba(0, 0, 0, 0.4);

    @include media-breakpoint-up(sm) {
        flex-direction: row;
    }
}

.panel-nhs__access-by {
    margin-bottom: 0;
    padding-right: 0.5rem;
}

//--------------------------------
// BP Footer
//--------------------------------

.bp-footer {
    background-color:  $palette-blue-a900; //$palette-blue-500;
    padding: 2rem 2rem 3rem 2rem;
    color: $white;

    li {
        list-style: none;
        margin-bottom: 0.5rem;
    }

    ul {
        padding: 0;
    }

    a,
    button {
        color: $white;
        font-size: 0.9rem;

        &:hover {
            text-decoration: underline;
            color: $white;
        }
    }
}

.bp-footer__wrapper {
    padding: 0;
    margin-bottom: 1.5rem;

    @media (min-width: 767px) {
        margin: 0;
    }
}

.bp-footer__list-header {
    font-family: "interfaceregular", Arial, Helvetica, sans-serif;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.4rem;
}

.bp-footer-logout__form {
    padding: 0;
    margin: 0;
}

.bp-footer-logout__button {
    background: none;
    border: none 0;
    cursor: pointer;
    padding: 0;
    margin: 0;
}

.bp-footer__social-icons {
    margin-top: .25rem;
    margin-left: -.25rem;

    a {
        display: inline-block;
        padding: .2rem;
        margin-bottom: .25rem;
    }
}

.copyright {
    margin-top: 1rem;
}

.efwa-img {
        display: block;
        padding-top: 5px;
		padding-bottom: 5px;
		margin-left: -15px;
		background-color: $white;

        a {
            width: inherit;
            height: inherit;
            display: inline-block;
        }
		
		img {
			max-width:100%;
		}
}

//--------------------------------
// *** REMOVE WHEN WE ARE SURE IT'S NOT NEEDED  ***
//--------------------------------

/*
#panel-nhs {
    flex-direction: column;
    background-color: $white;
    box-shadow: 0 -3px 4px -5px rgba(0, 0, 0, 0.4);

    #access {
        margin: 0 auto;

        .col-md-6 {
            padding: 0;
            min-height: 54px;
        }

        p {
            width: 100%;
            text-align: right;
            padding-right: 1rem;
            position: relative;
            top: 33%;
        }

        img {
            border: 0;
            float: left;
        }

        @media only screen and (max-width : 768px) {//mobile
            .col-md-6,
            p {
                width: 100%;
                text-align: center;
                min-height: auto;
            }
            p {
                padding: 0;
            }
            img {
                float: none;
                margin: 0 auto 1rem auto;
                width: auto;
            }
        }

        .row > div + div p {
            text-align: left;
        }
    }

    @include media-breakpoint-up(sm) {
        flex-direction: row;
        min-height: 5rem;

        @include ie-only() {
           height: 5rem;
        }
    }

}

*/


