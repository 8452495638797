.heading-copy-l {
    font-family: "interfaceregular", Arial, sans-serif;
    font-size: px-to-rem(24) ;
    font-weight: 400;
    line-height: px-to-rem(32);
}

.heading-copy-m {
  font-family: "interfaceregular-bold", Arial, sans-serif;
  font-size: px-to-rem(20) !important;
  font-weight: 700;
  line-height: px-to-rem(28);
}

.heading-copy-s {
  font-family: "interfaceregular", Arial, sans-serif;
  font-size: px-to-rem(20) !important;
  font-weight: 400;
  line-height: px-to-rem(28);
}

.heading-copy-xs {
  font-family: "interfaceregular", Arial, sans-serif;
  font-size: px-to-rem(18) !important;
  font-weight: 400;
  line-height: px-to-rem(24);
}