$p-blue-800: #1951a4 !default;
$p-blue-500: #2a6ebb !default;
$p-blue-400: #4a84c5 !default;

/*
#tmp.topic > .row .card,
#tmp.tmp-static-list > div > .card, //e.g div.invest > .card
#tmp > .card { /z-index: 0 !important; } 'more links' fix & z-index 20 on .cards ? */

$menuLinks-L: 12px !default;
$menuLinks-R: 12px !default;
$menuLinks-Lx2: 24px !default; // = $menuLinks-L x 2
$menuLinks-minW: 180px !default; //has to be 184 for chrome & ie
$menuLinks-vi-vn:206px!default; //for Vietnamese menu to align

//--------------------------------
// Header
//--------------------------------

.topicMenuTopBg {
	display: none;
	background-color: #2a6ebb;
	width: px-to-rem(1116);
}

.topicMenuTop {
	width: inherit;
	min-height: inherit;
	padding: px-to-rem(12) $menuLinks-L 0 $menuLinks-R;
	position: relative;
}

.topicMenuTopWrap {
	background-color: $palette-blue-500;
	width: 100%;
	min-height: px-to-rem(110);
	padding: 0;
	position: relative; /* 'more links' fix */
	z-index: 4; /* 'more links' fix */
}

.rf-topicNav .topicMenuTopWrap h1 {
	margin: 0;
	padding: px-to-rem(12);
	color: $white;
	font-size: 2rem;
	line-height: 1;
	max-width: px-to-rem(880);
}

.topicMenuBottom {
	z-index: 4;

	&:before,&:after {
		content: ''; display: table;
	}
	&:after {
		clear: both;
	}

	&.activeMenu {
		position: absolute;
	}

	&.activeMenu2 {
		position: fixed !important;
	}
}

//--------------------------------
// Sticky Header
//--------------------------------

.stickme {
	.topicMenuTop {
		position: fixed; top: 0; z-index: 1;
	}

	.topicMenuTopBg {
		display: block;
		position: fixed;
		top: 0;
		z-index: 4; /* 'more links' fix */
	}
}

.stickme {
	.rf-topicNav__menus-links {
		li {
			span {
				opacity: 1;
			}
			div {
				cursor: pointer;
			}
			a {
				&:hover {
					background-color: $palette-blue-500;
				}
			}
		}
	}
}

.rf-topicNav.stickme .material-icons  {
	color: $white;
	opacity: 1;
	&:last-child {
		display: none;
	}
}

.rf-topicNav.up .material-icons {
	color: $white;
	opacity: 1;

	&:first-child {
		display: none;
	}

	&:last-child {
		display: block;
	}
}

//--------------------------------
// Topic nav menu headers
//--------------------------------

.rf-topicNav {
	padding: 0;
	margin: 0 0 1.5rem 0;

	* {margin: 0; padding: 0; }

	.material-icons {
		&:first-child {
			display: block;
		}
		display: none;
		opacity: 0;
		transition: opacity 0.25s ease-in-out;
	}
	.mobileMenu {
		display: none;
	}
}

.rf-topicNav__menu-header-links {
	background-color: $palette-blue-a900;
	padding: 0;
	position: absolute;
	bottom: 0;
	width: 100%;

	li {
		float: left;
		list-style: none;
		text-align: left;
		text-transform: uppercase;
		width: 100%;

		div {
			cursor: default;
			color: $white;
			font-size: 0.8rem;
			display: flex;
			width: 100%;
			padding: 0.5rem 0.5rem 0.3rem 1rem;
		}
	}

	li > span {
		opacity: 0; transition: opacity .25s ease-in-out;
	}

	@include media-breakpoint-up(lg) {
		display: flex;

		li {
			width: 100%;
		}
	}

	@include media-breakpoint-up(xl) {
		li {
			width: auto;
		}
	}
}

#topicMenuTop .grouping {
    display:flex;
    justify-content: space-between;
    align-items: center;
    @include media-breakpoint-up(xl) {
       width: 69.75rem;
    }

    .grouping-right {
        padding-right: 1rem;
        padding-top: px-to-rem(12);
    }
}

.pdf, .pdf-local-resources {
	text-align: right;
	position: relative;

	a {
		color: $white;
		span {
			color: $white;
		}

		.icon-pdf-xs {// main pdf link icon
			&:before {
				content: "F";
				font-size: 2.50rem !important;
				color: $white;
				line-height: .1;
				position: relative;
				top: px-to-rem(14);
				margin: 0 px-to-rem(-6) 0 px-to-rem(-13);
			}
		}
	}
	a:hover span + span {text-decoration: underline; }
}

//--------------------------------
// Topic nav menu links
//--------------------------------

.menus-boxshadow {
	box-shadow: 0 px-to-rem(2) px-to-rem(2) 0 rgba(0,0,0,0.14),0 px-to-rem(1) px-to-rem(5) 0 rgba(0,0,0,0.12),0 px-to-rem(3) px-to-rem(1) px-to-rem(-2) rgba(0,0,0,0.2);
}

#menus {
	@include media-breakpoint-up(lg) {
		display: flex !important;
	}

	.menus-no-link {
		padding: 0 0 1rem 1rem;
		display: flex;
		&:first-child {
			padding-top: 1rem;
			a {
				padding: 0;
			}
		}
		a {
			padding: 0;
		}
	}
}

#menus {
	background-color: $white;
	width: inherit;

	.mpdf {
		display: none;
	}

	li {
		line-height: 1;
		width: 100%;
		text-align: left;
		list-style: none;

		.parent {
			display: none;
		}
	}

	.active {
		color: $palette-pink-500;
	}
}

.stickme .rf-topicNav__menu-header-links,
	#menus {width: px-to-rem(1116); }

@media only screen and (max-width : 1199px) {//for when topic nav "drops"  (?) one size down
	.rf-topicNav {
		.topicMenuTopBg,
		.rf-topicNav__menu-header-links,
		#menus {
			width: px-to-rem(936);
		}

		.topicMenuTopWrap h1 {max-width: 700px; }

		&.stickme .pdf {
			left: px-to-rem(720) !important;
			position: absolute;
			z-index: 10;
		}
	    &.stickme .pdf-local-resources {
			left: px-to-rem(720) !important;
			position: absolute;
			z-index: 10;
		}
	}
}

@media only screen and (min-width : 992px) and (max-width: 1200px) {//not mobile
	#menus > li > a span {display: none; }//helps accessiblity
}

@media only screen and (min-width: 1200px) {
	// Vietnam Menu Fix Desktop
	.rf-topicNav {
		&__menu-header-links {
			li {
				&.vi-vn-desktop {
					a {
						font-size: 0.8rem!important;
					}
					ul{
						li{
							min-width: initial;
							max-width: initial;
						}
					}
				}
			}
		}
	}
}

//--------------------------------
// Mobile menu
//--------------------------------

@media only screen and (max-width : 992px) {//mobile
	.mobileMenu {
		&__open-menu, &__close-menu{
			display: flex;
			justify-content: flex-end;
		}
	}

	.rf-topicNav {
		.material-icons {
			color: #424242;
			font-size: 1.4rem;
			opacity: 1;
		}

		.topicMenuTopWrap h1 {
			padding: 0;
		}
	}

	.topicMenuTop {
		padding: 0.75rem 0 0 0.75rem;
	}

	.rf-topicNav {
		.topicMenuTopBg,
		.rf-topicNav__menu-header-links,
		#menus {
			width: px-to-rem(936);
			// Vietnam Menu Fix Desktop
			li {
				&.vi-vn-tablet{
					a {
						span{display: block}
					}
				}
			}
		}
	}
	body .rf-topicNav {
		width: 100%;

		&.stickme .topicMenuTopBg,
		.topicMenuTopBg,
		.rf-topicNav__menu-header-links,
		.topicMenuBottom.mUp,
		#menus li ul {display: none; }

		.topicMenuTop h1 {padding-right: 5.5rem}

		.topicMenuBottom {z-index: 0; background-color: $white; box-shadow: 0 px-to-rem(2) px-to-rem(2) 0 rgba(0,0,0,0.14),0 px-to-rem(1) px-to-rem(5) 0 rgba(0,0,0,0.12),0 px-to-rem(3) px-to-rem(1) px-to-rem(-2) rgba(0,0,0,0.2); }
		.topicMenuTopWrap {
			height:auto;
			min-height: auto;
			h1 {
				position: relative;
				padding-bottom: px-to-rem(10)!important;// added these styles so the height is auto
			}
		}

		.mobileMenu {
			display: block !important;
			width: px-to-rem(126);
			text-align: right;
			height: 1.5rem;
			position: absolute;
			top: 1.3rem;
			right: px-to-rem(12);
			text-transform: uppercase;
		}

		.topicMenuTopWrap h1 {
			margin-left: px-to-rem(-12);
			padding-left: px-to-rem(12);
		}

		.mobileMenu a {
			word-wrap: break-word; //not always reliable

			& > span {
				color: $white;
				width: 100%;
				font-size: 1rem; //deliberately px
			}

			& span span.material-icons {
				color: $white;
				display: inherit;
				font-size: px-to-rem(27);
				margin-right: px-to-rem(-6);
				height: 1.3rem;
				line-height: 0.6;
			}

			& > span {
				display: inline-block;
			}
			& > span:first-child {
				display: none;
			}
		}
		.mobileMenu.mUp a {
			& > span {
				display: none;
			}
			& > span:first-child {
				display: inline-block;
			}
		}

		#menus li.mpdf {
			display: block;
			a {
				text-transform: initial !important;
				border-top: 1px solid #eee !important;
				width: 100%;
				display: block;
				padding: px-to-rem(8) 0 px-to-rem(14) 3.2rem;
			}
			a,
			a span {
				color: $palette-blue-500 !important;
			}

			a:hover,
			a:hover span:first-child {
				text-decoration: none;
				& + span {text-decoration: underline;
				}
			}

			span {
				&:last-child {
					position: absolute;
					margin-top: px-to-rem(14);
				}
			}

		}

		#menus {
			width: 100% !important;
			display: flex;
			flex-direction: column;

			li {
				width: inherit;
				min-width: auto;
				padding-top: 1rem;

				.parent {
					color: $palette-grey-800;
					display: block;
					text-transform: uppercase;
					padding: 0.5rem 1rem;

					&:hover {
						background-color: $palette-grey-300;
					}
				}
			}
		}

		#menus a .material-icons {font-size: 1.4rem; font-weight: normal; float: right; position: relative; top: 0; }
		#menus a .material-icons,
		#menus a.mUp .material-icons + .material-icons {display: inline-block; }
		#menus a .material-icons + .material-icons,
		#menus a.mUp .material-icons {display: none; }


		.pdf, .pdf-local-resources {
			display: none;
		}
	}
}

.icon-pdf-xs {
	&:before {
		content: "F";
		color: inherit;
		font-size: 2.50rem !important;
	}
}

// Ukraine & Russia Menu Fix
.rf-topicNav {
	&__menu-header-links {
		&.ukru li {
			a {
				padding: 0.5rem 0.5rem 0.3rem 0;
			}
		}
	}
	#menus {
		&.ukru li {
			a {
				padding: 0.5rem 0.5rem 0.3rem 0;
			}
		}
	}
}

.rf-topicNav {
	&__menu-header-links.ukruMenu li {
		a {
			font-size: px-to-rem(12);
			padding: 0.5rem 0 0.3rem 0;
		}
	}
	#menus.ukruMenu li {
		a {
			font-size: px-to-rem(14);
			padding: 0.5rem 0 0.3rem 0;
		}
	}
}

@include ie-only{
	.card {
		display: -ms-flexbox;
		-ms-flex-direction: column;
	}
	.card-actions {
		display: -ms-flexbox;
	}
	.card-block {
		-ms-flex: 1 1 auto;
	}
	@media (min-width: 576px) {
		.card-deck {
			display: -ms-flexbox;
			-ms-flex-flow: row wrap;
		}
		.card-deck .card {
			-ms-flex: 1 0 0px;
		}
	}
	@media (min-width: 576px) {
		.card-group {
			display: -ms-flexbox;
			-ms-flex-flow: row wrap;
		}
		.card-group .card {
			-ms-flex: 1 0 0px;
		}
	}
}

.rf-topicNav .rf-topicNav__menu-header-links li div{
	height: 100%;
	align-items: center;
}

.rf-topicNav .rf-topicNav__menu-header-links li{
	width: 100%!important;
	padding-left: 1rem;
}

.rf-topicNav .rf-topicNav__menu-header-links li div{
	height: 100%;
	align-items: center;
	text-align: left;
	justify-content: flex-start;
	padding-left: 0;
}

//--------------------------------
// Logged in lang como icon alignment
//--------------------------------

.como-icon-cc {
	@include media-breakpoint-down(sm){
		margin-left: px-to-rem(10)!important;
	}
	@media only screen and (max-width : 1200px) {
		padding-right: px-to-rem(40)!important;
	}
	@media only screen and (min-width : 1200px) {
		padding-right: px-to-rem(70)!important;
	}
}

.como-icon-cc-ka-ge {
	@media only screen and (max-width : 1200px) {
		padding-right: px-to-rem(25)!important;
	}
	@media only screen and (min-width : 1200px) {
		padding-right: px-to-rem(45)!important;
	}
}

//--------------------------------
// toggle View content in English or Spanish
//--------------------------------

.toggle-lang-links-nav {
    width: 100%;
    z-index: 10;
    text-align: right;
    a {
       color: $white;
       gap: 0.5rem;
       display: flex;
       justify-content: end;
       margin-top: 0.5rem;
       &:hover {
         color: $white!important;
       }
    }
}

#topicMenuBottom {
    .toggle-lang-links-nav {
        display: none;
    }
}
@include media-breakpoint-down(sm){
    #topicMenuTop {
        .toggle-lang-links-nav {
            display: none;
        }
    }
    #topicMenuBottom {
        .toggle-lang-links-nav {
            display: block;
            position: unset;
            width: 100%;
            padding-top: 0;
            a {
                color: $p-blue-500;
                border-top: 1px solid $palette-grey-200 !important;
                width: 100%;
                padding: 0.5rem 0 0.875rem 3.2rem;
                padding-top: 1rem;
                display: block;

                img {
                    filter: unset;
                    margin:0 px-to-rem(10) 0 px-to-rem(10);
                }
            }
        }
    }
}

#menus {
    .toggle-lang-links-nav {
        a {
            margin-top: 0;
        }
    }
}

@media only screen and (max-width: 992px) {
    .toggle-lang-links-nav {
        a {
            margin-top: 1.5rem;
            margin-bottom: 0.5rem;
        }
    }
}

.lang_es-es {
    #menuLinks {
        li {
            div {
                display: block;
                padding-bottom: 0;
            }
        }
    }
}
.stickme {
    .lang_es-es {
        .rf-topicNav__menu-header-links {
            bottom: px-to-rem(-13);
        }
    }
    #menuLinks {
        li {
            div {
                display: flex;
                padding-bottom: .3rem;
            }
        }
    }
}