bp-local-guideline-form {
  input,
  select{
    border-color: $palette-bmj-grey-100 !important;
    box-shadow: none !important;
    border-right: 1px solid $palette-bmj-grey-100 !important;
    display: block;
  }

  input[disabled],
  select[disabled]{
    background-color: $palette-grey-light !important;
    color: $palette-grey-a500 !important;
  }

  input[type="checkbox"] {
    accent-color: $palette-pink-600;
    height: 1.2rem;
    width: 1.2rem !important;
  }

  .allTopicsWrapper {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
    margin-bottom: 1.5rem;
  }

  .allTopicsWrapper label{
    margin-bottom: 0;
  }

  legend{
    font-size: 1rem;
  }

  button.card-saved-from-search.js-remove-list-item[disabled] {
    opacity: 0.5;
  }

  button.card-saved-from-search.js-remove-list-item[disabled]:hover {
    cursor: not-allowed;
    color: $palette-grey-a500 !important;
  }

  .nav-item {
    box-shadow: none !important;
  }

  #guidelineList ul {
    margin-top: 1.5rem;
  }

  #guidelineList ul:empty {
    margin-top: 0;
  }
}