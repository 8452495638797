
.tooltip {
  &.show {
    opacity: 1 !important;
    z-index: 99 !important;
    margin-top: 0;

    @include media-breakpoint-up(sm) {
      margin-top: -0.125rem;
    }

  }
}
.tooltip-inner{
  background-color: #ffe8ae !important;
  color: $black !important;
  max-width: 300px;
  @include border-radius($border-radius);
  box-shadow: map-get($card-elevation-shadow, shadow);
  cursor: pointer;
  position: relative;

  .tooltip-header {
    font-size: 1rem;
    padding-top: 0.5rem;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  .close {
    font-size: 1.25rem;
    position: absolute;
    top: 0;
    right: 4px;
    font-size: 1.25rem;
    color: $black;
    font-weight: bold;

  }
  .tooltip-content {
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
  }
  .tooltip-arrow-down {
    position: absolute;
    bottom: -11px;
    left: calc((50%) - 12px);
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 12px solid #ffe8ae;
  }
}