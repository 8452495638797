.activity {
	input, textarea, select {
		border: 1px solid rgb(118, 118, 118);
	}
	h1 {
		font-size: 2.25rem;
		@include media-breakpoint-up(sm) {
			font-size: 2.8125rem;
		}
	}
	.custom-control-description {
		font-size: 1rem;
	}
	.custom-control {
		margin-right: 0;
	}
	.custom-control-input {
		&:focus {
			+ span.custom-control-indicator {
				outline: 5px auto Highlight !important;
				outline: 5px auto -webkit-focus-ring-color !important;
			}
		}
	}
	.cme-form {
		border-top: 1px solid rgb(215, 215, 215);
		border-bottom: 1px solid rgb(215, 215, 215);
		background-color: $palette-grey-050;
		padding-left: 0.5rem;
		padding-top: 1.25rem;
	}
	.bmj-access-permission {
		top: 50%;
		margin-top: -12px;
	}
	> :nth-child(2) {
		padding: 0 .5rem; // tweak intro card padding
		margin-bottom: 1.5rem !important;
	}
	#activity-description-image {
		img{
			max-height: 125px;
		}
	}

	.create-certificate-form {
		background-color: rgba(250, 250, 250, 1);
		padding: 0.5rem;
		border-width: 1px;
		border-style: solid;
		border-color: rgba(215, 215, 215, 1);
		border-left: 0px;
		border-right: 0px;
		border-radius: 0px;
		padding-top: 1rem;
	}
	#month-select, #name, #last-name {
		width: 250px;
		@include media-breakpoint-up(sm) {
			width: 300px;
		}
	}
	textarea {
		width: 100%;
		max-width: 500px;
	}
	.see-activity-modal-btn {
		border: none;
		color: $palette-blue-500;
		background-color: transparent;
		padding: 0;

		&:hover {
			text-decoration: underline;
		}
	}
	#activity-table   {
		.modal-dialog {
			max-height: 80vh;
			overflow-y: scroll;
			max-width: 100%;
			width: 100%;
			margin: 0;
			.modal-body {
				padding: 0.25rem;
				.collapse {
					display: none !important;
				}
				@include media-breakpoint-up(sm) {
					padding: 0.5rem;
				}
				.custom-control {
					margin: 0;
				}
				.custom-control-indicator {
					left: 6px;
				}
			}
			@include media-breakpoint-up(md) {
				max-width: 80%;
				margin: 1.5rem 1.5rem;
			}
			.header {
				border-bottom: 1px solid rgba(170, 170, 170, 1);
				.close {
					color: $black;
					span:first-of-type {
						font-size: 1.25rem;
					}
					.close-x {
						font-size: 2.25rem;
						font-weight: bold;
					}
				}
			}
		}
		table {
			th, td {
				border: 1px solid $black-divider-solid;
				padding: 1rem 0.25rem;
				color: $body-color;
				font-size: 1rem;
				@include media-breakpoint-up(sm) {
					padding: 1rem 0.5rem;
				}
			}
		}
	}
	.tabCard {
		padding: 0;
	}
	.list-group-flush .list-group-item:first-child {
		border-top: none;
	}
	div[id^=month] {
	margin-left: -.75rem;
	}
  .download-cert-btn {
    border: 2px solid $palette-pink-500;
    color: $palette-pink-500;
	padding: 1rem 1.5rem;
    &:hover {
        text-decoration: underline;
		ursor: pointer;
    }
  }
  .btn::before { // override for :focus bg-color.
      background-color:inherit;
      color: inherit;
  }
  div[id^=reflections-and-impact] {
    p {
      margin-top: 1rem !important;
    }
    textarea {
      border-radius: 2px;
      background-color: #fafafa;
      color: $black-primary;
      min-height: 110px;
      width: 100%;
    }
    .char-count-refl, .char-count-impact {
      color: $palette-pink-500;
    }
    .charCount, .char-count-impact {
      font-size: .75rem;
    }
  }
  .helpLinks {
    h3 {
      margin: 2rem 0 1rem 0;
    }
}
	#certificates {
		p {
			margin: 1rem 0 1rem 1.5rem;
		}
		.table {
			thead th {
			color: rgba(0,0,0,0.7);
			font-weight: bold;
			font-size: .875rem;
			}
			td{
				height:0;
				padding-top:0.875rem;
				padding-bottom:0.875rem;
			}
		}
		.icon-bp-icons-delete:before {
			content: "\6b";
			font-size: 1.75rem;
			position: relative;
			top: -5px;
	  }
	}
	.nav-tabs {
		.nav-link{
			width: 100%;
			justify-content: flex-start;
		}
	}
}

#help-and-faq {
	#activity-description-image-faq {
		img{
			height:auto;
			width:100%;
			padding-left:10px;
		}
	}
	.card-header {
		padding:0.9375rem;

		a{
			&.collapsed{
				&:before{
					content: "\E5CF";
				}
			}
			&:before{
				color: $palette-pink-800;
				font-family: 'Material Icons';
				content: "\E5CE";
				position: relative;
				top: 3px;
			}
		}
	}
	h3{
		color:#565759;
		font-size:1.625rem;
	}
	h5 {
		color:#565759;
		font-size: 1.1rem;
	}
	.help-and-faq--dropdown {
		p {
			font-weight: 600;
			font-size: 0.875rem;
			color:#333333;

			&:hover{
				cursor: pointer;
				text-decoration:underline;
			}
		}
	}

	.help-and-faq--right {
		.img-container {
			position: relative;
			padding-bottom: 75%; /* 4:3 ratio */
			height: 0;
			overflow: hidden;

			img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
	}
	.help-faq-underline {
		text-decoration:underline;
	}
	.help-and-faq-button {
		text-transform:unset;
	}
}

@media only screen and (max-width : 991px) {
	#help-and-faq {
		.w-50 {
			width: 100% !important;
		}
	}
}

#recreate-cert-modal{
	img{
		width:50px;
	}
	.modal-certificate-text{
		font-weight: bold;
		color: #333;
	}
	.wrapper-modal-certificates {
		button{
			img{
				width:17px;
			}
		}
	}
}
@media only screen and (max-width : 991px) {
	#recreate-cert-modal{
		.wrapper-modal-certificates{
			text-align:center;
		}
	}
}