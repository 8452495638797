.main-container {
  clear: left !important; //stops wrapper floating up beyond floating #header and causing space above header

  &:before,
  &:after {
    content: ' ';
    display: table;
  }

  &:after {
    clear: both;
  }

}

// ********************* global padding control !?!  Start: **************
$std-padding-md: 1.6rem 1.6rem 2rem 1.6rem;
$std-padding-sm: 1rem 1rem 1.6rem 1rem;

.tmp {padding-top: 1rem; padding-bottom: 1rem; }
.tmp .card-block {padding: $std-padding-md; }

.ebm-calculators .card-block {
  @media (max-width: 375px) {
    padding: $std-padding-sm;
  }
  padding: $std-padding-md;
}

.specialties-list .card-block {padding: $std-padding-md; }
.specialties .card-block {padding: $std-padding-md; }
.specialties > .card-block,
.specialties .card-block.a_z {padding: 0; }
.patientLeaflets-list .card-block {padding: $std-padding-md; }
.patientLeaflets .card-block {padding: $std-padding-md; }
.patientLeaflets .card-block.a_z {padding: 0; }
.calculators .tab-content .card-block .card-header {padding: 4.7rem 0 0.5rem 0; }
.calculators #bySpecialty .card-header {padding: 1.2rem 0 0.5rem; }
.calculators .tab-content .card-block .card-body {padding: 1rem 1rem 1rem 1.5rem; }
.patientLeaflets .tab-content .card-block .card-header {padding: 4.7rem 0 0.5rem 0; }
.patientLeaflets #bySpecialty .card-header {padding: 1.2rem 0 0.5rem; }
.patientLeaflets .tab-content .card-block .card-body {padding: 1rem 1rem 1rem 1.5rem; }
.serp .card-block {padding: 0.75rem 1rem 0.75rem 1.5rem; }
.serp .card-group .card:first-child .card-block {padding: 1.5rem 1rem 1.5rem 1rem; }
.serp.mobile-padding .card-block {padding: 0.8rem; }
.serp .serp_detail .card-block:first-child {padding: 1rem; }
.serp .list-group-item {padding: 0.75rem 1rem; }


/*
.container.login-form {
  & > .row {
    margin-bottom: 1.7rem;

    .col-md-6 p {
      margin-right: 1.5rem;
    }
  }
}

 */

@media (max-width: 767px) {//mobile
  .tmp .card-block,
  .specialties-list .card-block,
  .specialties .card-block,
  .patientLeaflets .card-block,
  .calculators .tab-content .card-block {padding: $std-padding-sm; }
  .calculators .tab-content .card-block {padding: $std-padding-sm; }
  .patientLeaflets .tab-content .card-block .card-body {padding: 1.6rem 0rem 0rem 2rem; }
  .patientLeaflets .tab-content .card-block .card-body {padding: 1.6rem 0rem 0rem 2rem; }
}


.tmp .diffDiagnosis2 .tabCard {
  .nav-tabs {
    margin: 0 !important;
    .nav-item {
      margin: 0 !important;
    }
    .nav-item:before {
      content: normal !important;
    }
  }
}

.specialties, .calculators, .patientLeaflets, .serp, .diffDiagnosis2, .recent-updates, .guidelines {
  .tabCard {
    margin-bottom: 1rem;
    display: inline-block;
    .nav-item {
      width: auto;
    }
  }
}
.tabCard .card-block {padding: 0 !important; }
//global padding control !?!  :end.

@media (min-width: 768px) {//desktop/larger
  #header + .container,.main-container {
    min-height: 555px;
  }
}

// Add mobile behaviour
@media (max-width: 767px) {
  .w-75 {width: 100% !important; }

  .specialties, .calculators, .patientLeaflets, .serp, .diffDiagnosis2 {
    .tabCard {
      display: inline;
      .card-block {
        margin-bottom: 1rem;
      }
      .nav-item {
        width: 50%;
      }
    }
  }
}

// Full Width Cards for Mobile
@media (max-width: 767px) {
  .container {
    padding-left: 0;
    padding-right: 0;
  }

}

// HEADERS for Mobile (padding as no longer cards)
@media (max-width: 575px) {
  body > .container > .row > div {//cols div
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 1rem;
  }

  body > .container > h1,
  body > .container > h2 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  body > .container > .row > h1,
  body > .container > .row > h2 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

// PAGINATION
.pagination {
  background-color: #fff !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12), 0 3px 1px -2px rgba(0, 0, 0, .2) !important;
}

.card.js-override {
  &.is-active {
    margin-bottom: 1.5rem;

    .rowHead {
      font-size: 1.2rem;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }

    .panel {
      border-bottom: 1px solid #dfdfdf;
      &.bg-line{
        background: none;
      }
      span.title-01{
        &:before {
          background: none;
        }
      }
      .panel-group {
        &.parent {
          .txtwrap.no-icon {
            font-size: 1.125rem !important
          }
        }
      }
    }
  }
}


#ot-sdk-btn.ot-sdk-show-settings, #ot-sdk-btn.optanon-show-settings {

  background-color: #E1F2D4 !important;
  color:   $black-charcoal !important;
  border-radius: 6px !important;
  font-size: 1rem !important;
  border: none !important;
  padding: px-to-rem(12) px-to-rem(16) !important;
  height: px-to-rem(46) !important;

  &:hover {
    background-color: #96D269 !important;
  }

  &:focus {
    outline: 5px auto Highlight !important;
    outline: 5px auto -webkit-focus-ring-color !important;
  }
}

.guideline-table {
  tbody {
    tr {
      td:last-child {
        vertical-align: middle;
        height: 12.5rem;
        @include media-breakpoint-up(sm) {
          vertical-align: top;
          height: auto;
        }

        span {
          width: 12.5rem;
        }
      }
    }
  }
}

#localGuidanceTable {
  .sort {
    cursor: pointer;
  }
}