// -----------------------------------------------------
// ACCORDION
//
// Accordion styles

$toggle-icon-px-left: 10;
$toggle-icon-px-whitespace: 5;  // Toggle icon contains some whitespace
$table-indent-px-mobile: $toggle-icon-px-left + $toggle-icon-px-whitespace;
$table-indent-px-desktop: 45;   // Aligned with left of panel-heading-text

// Legacy
.accordion-toggle {
    color: $body-color;
    &:hover, &:active, &:focus {
        color: $body-color;
    }

    // Arrow alignment tweak within <th>
    th & {
        .material-icons {
            position: relative;
            top: 3px;
        }
    }
}

// MODULE: accordion

.accordion-primary {
    // .border-top-2 .border-dark
    border-top: 2px solid $palette-blue-a900 !important;
    // shadow-sm
    box-shadow: $shadow-2dp;
    // mb-4
    margin-bottom: $spacer-lg-y;
}

// MODULE: acc-panel (accordion panel). An accordion can have many expandable panels.

// Some styles are copied from _details-summary.scss
// so that we can tweak them without breaking legacy accordions

.acc-panel-primary {
    // .bg-white
    background-color: $white;
    // .border-dark .bs-border-bottom
    border-bottom: 1px solid $palette-blue-a900 !important;
}

.acc-panel-heading {
    position: relative; // For toggle icon
    padding-top: 1rem;
    padding-left: px-to-rem($table-indent-px-desktop);
    padding-bottom: 1rem;
}

.acc-panel-toggle:before {
    content: "\e5cf";
    font-family: 'Material Icons';
    color: #c50084;
    font-size: 1.75rem;
    position: absolute;
    left: px-to-rem($toggle-icon-px-left);
    top: 50%;
    margin-top: -1.25rem;
}
// Can't use "panel" name as it's is already used on homepage and treatment algorithm
.acc-panel[open] .acc-panel-toggle:before {
    content: "\e5ce";
    font-family: 'Material Icons';
}

@include ie-only(){
    .acc-panel.open .acc-panel-toggle:before {
        content: "\e5ce";
        font-family: 'Material Icons';
    }
}

.acc-panel-heading-text {
    color: $palette-blue-a900;
    font-weight: bold;
    // !important needed to over-ride ".tmp h3" style, for example
    font-size: 16px !important;
    line-height: 1.75rem  !important;

    font-family: $font-family-base;
}

// Some accordions have nested tables e.g. /topics/en-gb/7/investigations

.acc-panel-with-table {
    padding: 0;
    box-sizing: border-box;
}

// MODULE: table

.table-header {
    display: flex;
    text-transform: uppercase;
    background-color: $palette-grey-300;
    font-weight: bold;
    font-size: 0.95rem;
    padding: px-to-rem(3) 0;
    margin-bottom: 15px;
}

// On mobile, each table cell should be h-aligned with the panel-heading-text
.table-indent {
    padding-left: px-to-rem($table-indent-px-mobile);
}

// On desktop, the first column should have a wide intent (h-aligned with the toggle-icon)
.table-indent-wide {
    padding-left: px-to-rem($table-indent-px-mobile);

    @include media-breakpoint-up(md) {
        padding-left: px-to-rem($table-indent-px-desktop);
    }
}

// Fixes for table content ------------

.p-full-width p.w-75 {
    width: 100% !important;
}
.list-full-width ul.w-75, .list-full-width ol.w-75 {
    width: 100% !important;
}
.evidence-full-width .evidence-accordion {
    width: 100% !important;
}
.pitfall-full-width #tip_pitfall {
    width: 100% !important;
}

// We want to fix the rowDetails indentation, but keep descendant styling e.g.
//  .rowDetails hr.fw {display: none}
.fixRowDetailsLeftPadding {
    margin-left: -15px;
}

// Style duplicated from ".tmp #condition p.topicLink:after". This instance is more modular.
.link-with-arrow:after {
    color:  $palette-pink-500;
    content: '\2192';
    font-size: $material-icon-size;
    position: relative;
    top: 1px;
}

.li-para-no-padding li p {
    padding: 0px !important;
    margin-bottom: 0px !important;
}
.li-mb-1 li {
    margin-bottom: 0.25rem;
}

// Utilities --------------------------

.border-top-2 {
    border-top: 2px solid $palette-grey-light !important;
}

// REFACTOR: Move to "clean" folder, after Compass nav is merged into master

.bg-white {
    background-color: $white !important;
}

.bs-border-bottom {
    border-bottom: 1px solid $palette-grey-light !important;
}

.border-dark {
    border-color: $palette-blue-a900 !important;
}

.shadow-sm {
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075) !important;
}

.tx-accordion ul.w-75 {
    width: unset !important;
}