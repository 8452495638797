// BP has 12,000 lines of custom CSS. We can massively reduce this by moving
// to "clean" Bootstrap, themed properly.
// https://getbootstrap.com/docs/4.0/getting-started/theming/
//
// Many of BP's current Bootstrap classes are incorrect. The classes in this
// file enable us to move incrementally towards "clean" Bootstrap.
// https://bmjtech.atlassian.net/browse/BP-3700?focusedCommentId=331900
//
// The classes below are "clean" Bootstrap prefixed with "x-". (X is for XXX,
// the Java comment for "works but needs attention"). We will eventually remove
// the prefix.

.x-text-primary {
  color: $palette-blue-500;
}

.x-text-secondary {
  color: $palette-pink-500;
}

// BUTTONS
.x-btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1.1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:hover, &:focus {
    text-decoration: none;
  }
}

.x-btn-primary {
  color: $white;
  background-color: $palette-blue-500;

  &:hover{
    color: $palette-blue-500;
    background-color: $white;
  }
}

.x-btn-secondary {
  color: $white;
  background-color: $palette-pink-500;
  border-color: $palette-pink-500;

  &:hover {
    color: $white;
    background-color: $palette-pink-700;
    border-color:  $palette-pink-900;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(197, 0, 132, 0.5);
  }
}

.btn-outline-light {
  color: $palette-blue-30;
  background-color: transparent;
  background-image: none;
  border-color:$palette-blue-30;

  &:hover {
    color: #212529;
    background-color: $palette-blue-30;
    border-color: $palette-blue-30;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(212, 226, 241, 0.5);
  }
}

.x-btn-lg, .x-btn-group-lg > .x-btn {
  padding: 0.5rem 1rem;
  font-size: 1.375rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-medium {
  font-size: 1rem;
}

.border-top {
  /* FIXME: bp-bootstrap-theme generates this as #dee2e6 (Bootstrap's default $gray-300)
   * but it should be #d9d9da (BP's value for $gray-300 in custom.scss)
   */
  border-top: 1px solid #d9d9da !important;
}

.x-btn-outline-secondary {
  color: $palette-pink-500;
  border-color: $palette-pink-500;
  background-color: $transparent;
  background-image: none;
  &:hover{
    color: $white;
    background-color: $palette-pink-500;
    border-color: $palette-pink-500;
  }
}
.x-btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}