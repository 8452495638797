details {
  summary {
    list-style-type: none;
    outline: none;
    &::-webkit-details-marker {
      display: none;
    }
    > * {
      display: inline;
    }
    &.contributors {
      position: relative;
      padding-top: 1.125rem;
      padding-left: 3.25rem;
      padding-bottom: 1.125rem;
      h5 {
        font-size: 1rem;
      }
      &:before {
        content: "\e5cf";
        font-family: 'Material Icons';
        color: $palette-pink-500;
        font-size: 1.75rem;
        position: absolute;
        left: 1.25rem;
        top: 50%;
        margin-top: -1.25rem;
      }
    }
  }
  &[open] {
    summary {
      &.contributors {
        &:before {
          content: "\e5ce";
          font-family: 'Material Icons';
        }
      }
    }
  }
  &.open {
    @include ie-only {
      summary {
        &.contributors {
          &:before {
            content: "\e5ce";
            font-family: 'Material Icons';
          }
        }
      }
    }
  }
}
@include ie-only{
  #diffCommonContainer,
  #diffConditionTable,
  #diffConditionTable,
  #diffUnCommonContainer {
    button {
      color: $white;
    }

  }
}
[data-details="collapsed"] {
  border: none;
}
[data-details="wrapper"] {
 .card:last-of-type{
   margin-top: 0;
 }
}

.details-header {
  background-color: $palette-blue-040;
  border-bottom: 1px solid $palette-blue-grey-200;
  border-top: 2px solid $palette-blue-800;
  border-radius: 0;
  padding: 0.5625rem 2.25rem;
  button {
    background: none;
    color: $palette-pink-500;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    [data-details="text"] {
      &:hover {
        text-decoration: underline;
      }
    }
    &:hover {
      color: $palette-pink-500;
    }
  }
}

// An accordion has panels. A panel has a header and a body.
// Using summary/details tags is an implementation detail.
.details-inner, .panel-body {
  padding-left: 3.25rem;
}
