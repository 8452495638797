.topic {

	.local-guidelines {
	  border: 1px solid $palette-blue-500;
	  background-color: $palette-blue-500;

	  .icon {
		color: $white;
		font-size: 1.5rem;
		padding: 0.1875rem;
	  }

	  a {
		&:hover {
		  text-decoration: underline;
		}
	  }

	}
}	

	.lcGuidelinesContentUpdates {
        .card {
            border: 2px solid$palette-blue-500 !important;
        }
        .btn-lc-guidelines {
            background-color: $palette-blue-500;
            border: 2px solid $palette-blue-500;
			text-transform: none;
            color: $white;
            padding: .65rem .5rem .75rem .5rem;
            white-space: normal;
            z-index: 0;//stay behind slidedown topic nav!

            &:hover {    
				color: $white;            
            }
            .icon-bp-icons-info-circle:before {
                font-size: 1.25rem;
				font-color: $white;
                position: relative;
                bottom: -3px;
                @include media-breakpoint-up(md) {
                    bottom: 3px;
                }
                @include media-breakpoint-up(xl) {
                    bottom: -3px;
                }
            }
        }
		.btn::before {//override for :focus bg-color.
            background-color: inherit;
            color: inherit;
        }
	}	

    .lcGuidelinesContent {
        margin-top: px-to-rem(32);
		margin-bottom: 2rem;
		
        .card-block {
            padding: 0.2rem 0.2rem 0 0.2rem !important;
        }
        .card {
            border: 2px solid $palette-blue-500 !important;
        }
		.newline {
			display: block;
		}		
		.newline-last {
			margin-bottom: 1rem;
		}		
        .close {
            color: rgba(0,0,0,0.5);
            position: relative;
            top: -7px;
            right: -12px;
            &:hover {
              color: $palette-blue-500;
            }
        }
        #lcGuidelines {
            .card-block {
                padding: 1rem !important;
            }
            ul.w-75, p.w-75 {
                width: 100% !important;
            }
            h3 {
                float: none !important;
                line-height: 1.25;
                margin: 1rem 0;
            }
            a.upSource {
                &:hover {
                    color: $white;
                }
                &:after {
                    content: "\43";
                    font-family: 'bmj-icons';
                    font-size: 1.75rem;
                    position: relative;
                    top: 8px;
                    margin-left: -3px;
                }
            }
        }
    }	
	
	
.guidelines-arrow-card {

	h3 {
		float: none !important;
        line-height: 1.25;
        margin: 0 0 1rem 0;
   }	
   .card {
		border: 2px solid $palette-blue-500 !important;
   }
  margin-top: 2rem;
   border: 2px solid $palette-blue-500;
   border-radius: 2px;
   padding: 1rem 1rem 0 1rem;
   margin-bottom: 3rem;
   &.acLeft {
        &:after, &:before {
            left: 10%;
        }
   }
   &.acRight {
		&:after, &:before {
            left: 32%;
        }
    }
}	

	
.acleft-18 {
   &.acRight {
		&:after, &:before {
            left: 18%;
        }
    }
}	
.acleft-19 {
   &.acRight {
		&:after, &:before {
            left: 19%;
        }
    }
}	
.acleft-20 {
   &.acRight {
		&:after, &:before {
            left: 20%;
        }
    }
}	
.acleft-21 {
   &.acRight {
		&:after, &:before {
            left: 21%;
        }
    }
}	
.acleft-22 {
   &.acRight {
		&:after, &:before {
            left: 22%;
        }
    }
}	
.acleft-23 {
   &.acRight {
		&:after, &:before {
            left: 23%;
        }
    }
}	

.acleft-24  {
   &.acRight {
		&:after, &:before {
            left: 24%;
        }
    }
}	

.acleft-25 {
   &.acRight {
		&:after, &:before {
            left: 25%;
        }
    }
}	
.acleft-26 {
   &.acRight {
		&:after, &:before {
            left: 26%;
        }
    }
}	
.acleft-27 {
   &.acRight {
		&:after, &:before {
            left: 27%;
        }
    }
}	
.acleft-28 {
   &.acRight {
		&:after, &:before {
            left: 28%;
        }
    }
}	
.acleft-29 {
   &.acRight {
		&:after, &:before {
            left: 29%;
        }
    }
}	
.acleft-30 {
   &.acRight {
		&:after, &:before {
            left: 30%;
        }
    }
}	
.acleft-31 {
   &.acRight {
		&:after, &:before {
            left: 31%;
        }
    }
}	
.acleft-32 {
   &.acRight {
		&:after, &:before {
            left: 32%;
        }
    }
}	
.acleft-33 {
   &.acRight {
		&:after, &:before {
            left: 33%;
        }
    }
}	
.acleft-34 {
   &.acRight {
		&:after, &:before {
            left: 34%;
        }
    }
}	
.acleft-35 {
   &.acRight {
		&:after, &:before {
            left: 35%;
        }
    }
}	
.acleft-36 {
   &.acRight {
		&:after, &:before {
            left: 36%;
        }
    }
}	
.acleft-37 {
   &.acRight {
		&:after, &:before {
            left: 37%;
        }
    }
}	
.acleft-38 {
   &.acRight {
		&:after, &:before {
            left: 38%;
        }
    }
}	
.acleft-39 {
   &.acRight {
		&:after, &:before {
            left: 39%;
        }
    }
}	
.acleft-40 {
   &.acRight {
		&:after, &:before {
            left: 40%;
        }
    }
}

.lcGuidelinesContentUpdates  {
  position:relative;
  &:before, &:after {
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    bottom: -34px;
    z-index: 1;
    @include ie-only() {
      left:35%;
    }
  }
  &:after{
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: $white;
    border-width: 1.1875rem;
    @include ie-only() {
      bottom: -53px;
    }
  }
  &:before {
    border-color: rgba(113, 158, 206, 0);
    border-bottom-color: $palette-blue-500;
    border-width: 1.375rem;
    @include ie-only() {
      border-width: 1.1875rem;
    }
  }
}

.hideArrowLocal {
  .lcGuidelinesContentUpdates {
    &:before, &:after {
      display:none;
    }
  }
}

.local-guidance-upload-options {
  .tab-pane__group {
    overflow: hidden;
  }
  .nav-item {
    cursor: pointer;
    background: $white;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.5);
    svg {
      fill: rgba(0, 0, 0, 0.5);
      margin-right: 3px;
    }
    .active {
      background: $palette-blue-500;
      color: $white;
      svg {
        fill: $white;
      }
    }
    .nav-link {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .nav-item__or{
    color: rgba(0, 0, 0, 0.5);
  }
  .tab-pane__container-file {
    height: 17.875rem;
    @include media-breakpoint-up(sm) {
      height: 13.875rem;
    }
    @include ie-only {
      height: 100%;
    }
    background: #FFFFFF;
    border: 1px dashed #858585;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 1.875rem 0rem;
  }

  #pills-pdf {
    #file {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0;
      cursor: pointer;
    }
  }
  .tab-pane__file-error{
    border-color: $palette-red-500;
  }

  .tab-pane__file-uploaded {
    padding: 0.5rem;
    border-radius: 4px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25);
    background: white;
    border: 1px solid #2a6ebb;
    .file-uploaded__icon,
    .file-uploaded__remove {
      line-height: 0;
    }
    .file-uploaded__remove {
      cursor: pointer;
    }
    .file-uploaded__name {
      color: rgba(0, 0, 0, 0.75);
    }
  }
}
#local-guideline-modal {
  .modal-header {
    background: #5883BF;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25);
    h5 {
      font-size: 24px;
    }
  }
  .btn-cancel {
    background: $palette-grey-200;
    color: #3C3C3B;
  }
  .btn-save {
    &:hover{
      color: $white;
    }
  }
  .btn {
    padding: 18px 32px;
    font-weight: bold;
    font-size: 12px;
    &:hover{
      border: none;
    }
  }
}

#create-guideline,
#edit-guideline {
  .btn-open-modal {
    &:hover {
      color: $white;
      border: none;
    }
  }
}
.form-local-guideline {
  padding-left: 0.8125rem;
  padding-right: 0.8125rem;
  @include media-breakpoint-up(sm) {
    padding-left: 0;
    padding-right: 0;
  }
  .local-guidance-upload-options {
    .nav-link {
      padding-left: 0.3125rem;
      padding-right: 0.3125rem;
      span {
        font-size: 0.8125rem;
        @include media-breakpoint-up(sm) {
          font-size: 1rem;
        }
      }
    }
  }
  .local-guidance-option__label {
    margin-bottom: 0.5rem;
  }
  .border-danger {
    border: 1px solid $palette-red-500;
  }
  #specialty_select {
    background: $white;
    border:1px solid #858585;
    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-border-radius: 0px;
    appearance: none;
    border-radius: 4px;
    background: white url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E") no-repeat;
    background-position: right 12px top 50%;
  }
  .group-btn-local {
    flex-direction: column;
    .btn {
      width: auto;
      margin-bottom: 10px;
    }
    @include media-breakpoint-up(sm) {
        flex-direction: row;
    }
  }
}

#bySpecialty {
  h5 {
    a {
      color:$black;
    }
  }
  .card-body {
    padding: 0 1rem 1rem 1.5rem;
  }
}

#bySpecialty {
  h5 {
    a {
      color:$black;
    }
  }
  .card-body {
    padding: 0 1rem 1rem 1.5rem;
  }
}

.local-guidelines-page {
  .nav-tabs {
    .active {
      color:$palette-pink-500;
    }
  }
}
#specialtyList, #guidelineList {
    .card-saved-from-search {
        width: 100%;
        border: none;
        .icon-bp-icons-delete {
            margin-left: 1rem;
        }
    }
}
#mainSearchLocal {
    .recently-title {
        color: $palette-pink-500;
        font-size: .8rem;
        padding: 1rem;
        display: block;
    }
    .create-local-guideline {
        position: relative;
    }
    .rf-search__resultsLocal {
        list-style: none;
        position: absolute;
        top: calc(100% - 2px);
        width: 100%;
        background-color: #fff;
        z-index: 50;
        border: 0.0625rem solid #cccdcd;
        border-radius: 0 0 0.1875rem 0.1875rem;
        padding: 0;
    }
    .rf-search__resultsItem {
        padding: 0 1rem;
    }
    .rf-search__title {
        font-size: .8rem;
    }
    .rf-search__resultsItem__link {
        padding: 0.75rem 0;
        display: block;
        color: #333;
    }
    .rf-search__results b {
        color: $palette-pink-500;
        background-color: rgba(0,0,0,0);
        font-weight: bold;
        padding: 0;
    }
    .rf-search__resultsLocalItem {
        padding: 0 1rem;
        cursor: pointer;
        &:hover {
            background: #ccc;
        }
    }
    .rf-search__resultsItem__item {
        padding: 0.75rem 0;
        display: block;
        color: $black-charcoal;
    }
    #js-autocomplete {
        height: 2.875rem;
        width: 100%;
        background-color: $white;
        border: 0.0625rem solid $palette-bmj-grey-100;
        border-right: none;
        border-radius: 0.1875rem 0 0 0.1875rem;
        font-size: 1rem;
        padding: 0.75rem 1rem;
    }
    .rf-search__close {
        top: -2rem;
        right: 10px;
    }
}