// Topic Pages :: Tables
.tmp .card > .card-block {
    & > p:last-child {
        margin-bottom: 0;
    }
    & > section:last-child {
        margin-bottom: -1rem;
    }
}

.diffDiagnosis, .diffDiagnosis2, .histExam, .diagGuidelines, .complications, .invest, .htmlTable, .details, .treatment-table, #differ {

    & > .card > .card-block {
        padding: 0;
    }

    .rowHead {
        background-color: $palette-blue-500;
    }
    .rowHead > div {
        padding-left: 1.75rem;
    }

    .rowCondition {
        background-color: $palette-blue-040;
        padding: .75rem 1rem;
    }

}

.diffDiagnosis .rowDetails .conTitle h3.condition-title {
	margin-bottom: 1.9rem;
}

.diffDiagnosis .rowDetails .conTitle h3.font-normal {
	font-weight: normal;
}

.diffDiagnosis .rowHead .col-md-5 {
    padding-left: 0;
}

.treatment-table h5 {
	font-size: 0.9rem;
	font-weight: bold;
}


.diffDiagnosis, .diffDiagnosis2, .histExam, .diagGuidelines, .complications, .invest, .details, .htmlTable {

    &:first-child {
        margin-top: -1rem;
    }

    & > h2 {
        margin: 1rem 0;
    }

    p.labels {
        span {
            font-weight: normal;
            display: inline-block;
            padding: 0;
        }
        span:first-child {
            font-weight: bold;
            margin-right: 4px;
        }
    }

    .rowDetails {
        border-bottom: 1px solid #ddd;
        padding: 0 1rem;

        > *:first-child {
            padding-top: 1rem;
        }

        .conTitle {
            background-color: #ecf0f5;
            padding: 1rem;
            overflow-x: auto;
            display: block;

            h3 {width: 128px; display: block; word-break: keep-all;
                a {
                    margin-right: 6px;
                }
            }
        }
    }
    img {
        display: table-row;
    }
    h3 {
        display: inline;
        word-break: break-word;
    }
    img.cochraneInline {
        display: inline-block;
        padding: 0 .1rem;
        width: 5.5rem;
    }
}

.diffDiagnosis {
    &:first-child {
        margin-top: 0;
    }
}

.diffDiagnosis, .diffDiagnosis2 {
    .rowHead .col-md-2 {
        padding-left: 1rem;
    }

    .rowDetails {
        box-sizing: border-box;
        .w-75 {
            width: 100% !important;
        }

        .col-md-5 > i/*,
        .fig-inside-UL li > i,
        .fig-inside-UL li > p*/ {
            display: block;
            width: 100%;
            padding-left: 1rem;
        }
        /*.fig-inside-UL li > p {
            padding-left: 1rem;
        }*/
        .col-md-5 > i {
            font-size: 1rem;
        }
        /*.col-md-5 .w-75.fig-inside-UL {
            margin-top: 1rem !important;
        }
        .col-md-5 .figure {
            margin-top: 1rem;
        }*/
    }
	.overviewDescrLink {
		a {
			color: $palette-pink-500;   
			font-weight: bold;			
		}
		a:hover {
			color: $palette-pink-500;  
			font-weight: bold;
		}
	
		&:before {
			content: "\e5cc";
			font-family: 'Material Icons';
			color: $palette-pink-500;    
			margin-left: -6px;
			display: inline-flex;
			font-size: inherit;
			vertical-align: middle;
			width: 1em;
			scale: 135%;
			transform: scale(1.5, 0.75);
		}	
	}	
	
    /*@media (max-width: 767px) {.conTitle h3 {margin-left: 0.5rem; }}*/
}

.diffDiagnosis2, .invest {
    h3 {
        display: inline-block;
        .icon-bp-icons-red-flag:before {
            font-size: 2rem;
            position: relative;
            top: 7px;
        }
    }
   .tab-content .tab-pane {
        & > h3 {
            padding: 0;
            margin: 0 0 0.5rem 0;
        }
        .card + h3 {
            margin-top: 0.5rem;
        }
        .card {
            margin-top: 0;
        }
        .card-block {
            padding: 0rem !important;

            .material-icons {
                font-size: 1.5rem;
            }

        }
   }
}

.histExam {
    .strong {
        .rowHead {
            background-color: $palette-pink-500;
        }
        .rowCondition {
            background-color: $palette-pink-040;
        }
    }
}

.diagGuidelines {
    .rowCondition {
        padding: .75rem 0 0 1rem;
    }
    .rowDetails {
        span {
            display: inline-block;
            padding: 0 0 0 1rem;
            margin-bottom: 0;
            width: 150px;
        }
    }
}

.details {

    .rowDetails {
        display: none;
    }

    .show-content .rowDetails {
        display: block;
    }

    .rowHead {
        & > div > span {
            line-height: 1.6rem;
        }

        a {
        float: right;
        margin-right: -0.3rem;

            .material-icons {
                span:first-child {
                    display: block;
                }
                span {
                    display: none;
                }
            }

        }


    }
    .rowCondition {
        padding: 0;

        .material-icons {
            span:first-child {
                display: block;
            }
            span {
                display: none;
            }
        }
    }
    .rowCondition.openslide {
        .material-icons {
            span:first-child {
                display: none;
            }
            span {
                display: block;
            }
        }
    }
    .show-content {
        .rowHead a .material-icons,
        .rowCondition .material-icons {
                span:first-child {
                    display: none;
                }
                span {
                    display: block;
                }

        }
        .rowCondition.openslide {
            .material-icons {
                span:first-child {
                    display: block;
                }
                span {
                    display: none;
                }
            }
        }
    }

    .rowCondition h3 {
        width: 100%;
        display: inline-block;
        margin: 0;

        a {
            width: 100%;
            display: inline-block;
            padding: 0.8rem 1rem;

            & > span:first-child {
                padding-right: 20px;
                margin-right: -32px;
                display: inline-block;
                width: 100%;
            }
        }

        .material-icons {
            width: 16px;
            height: 16px;
            display: block;
            float: right;
            margin-right: -0.5rem;
         }
    }
}

@media (max-width: 767px) {//another hack for a quick fix; as this random page has a View All link
    .details .rowCondition h3 a {
        margin-left: 0.5rem;
    }
}

.nojs .rowDetails {
    display: block;
}

// Styles for < 768 responsive descriptions
@media (max-width: 767px) {

    .rowDetails > div[class^="col-"] {
        padding-right: 1rem !important;
    }

//START SIDE OVERFLOWS FIX ON MOBILE *** static-lists
    .tmp.tmp-static-list {
        .row.rowHead,
        .row.rowCondition,
        .row.rowDetails {
            margin-left: 0;
            margin-right: 0;
        }

        .row.rowHead,
        .row.rowDetails > div,
        .row.rowCondition > div {
            padding: 0;
        }

        .row.rowCondition h2,
        .row.rowCondition h3 {
            padding-left: 0;
        }

        .row.rowHead > div {
            margin-left: -0.5rem;
        }

    }

    .details  {
        .rowHead {
            span {
                margin-left: -0.3rem;
            }
        }
        .rowCondition h3 {
            margin-left: -0.5rem;

            .material-icons {
                margin-right: 0;
            }
        }
    }

    .histExam .rowCondition > div {
        padding-left: 1rem;
    }

    .diffDiagnosis .row.rowDetails .conTitle {
        padding: 1rem 1rem 1rem 0 !important;

        h3 {
            padding-left: 1rem;
        }
    }
//END SIDE OVERFLOWS FIX ***

    .diffDiagnosis .card .col-md-12:first-child, //Added in to ensure only top accordion has a top blue border on mobile
    .complications, .diffDiagnosis2, .invest{
        .rowCondition {
            border-top: 2px solid $palette-light-blue-900;
        }
        .rowCondition ~ .rowCondition {
            border: none;
        }
        .brdTop {
            border-top: 2px solid $palette-light-blue-900;
        }
    }

}

.no-results .card .card-block {
    padding: 1rem !important;
    p {
        margin: 0;
    }
    p + p {
        margin-top: 0.5rem;
    }
    .complications, .diffDiagnosis2, .invest{
        .rowCondition {
            border-top: 2px solid $palette-light-blue-900;
        }
        .rowCondition ~ .rowCondition {
            border: none;
        }
        .brdTop {
            border-top: 2px solid $palette-light-blue-900;
        }
    }

}

.no-results .card .card-block {
    padding: 1rem !important;
    p {
        margin: 0;
    }
    p + p {
        margin-top: 0.5rem;
    }
}

.more-pops {
    .reference {
        padding: 0.5rem 0 0 0 !important;
    }
    /*.morePop {
        font-weight: normal;
    }*/
}
.more-title {
    font-weight: bold;
    font-size: 1.1rem;
    line-height: 2rem;
}

.more-edit {
    span.reference {
        display: block;
        width: 100%;
        line-height: 0.9rem;
        padding-bottom: 0.6rem;

        &:first-child {
            margin-top: 1rem;
        }
        .referencePop,
        .citationText,
        .new-cite,
        .more-article {
            display: inline;
            font-size: 0.8rem;
            font-style: italic;
        }
        .more-article {
            white-space: nowrap;
            span {
                display: inline-block;
            }
        }
    }

    .referencePop {
        pointer-events: none;
        cursor: default;
        color: $black;
    }

}
.popover-content .w-75 {width: 100% !important; }

// -------- Accordion Tables ----------- //
// Condition monograph link icon
.tmp #condition {
    p.topicLink {
        &:after {
            color: #c50084;
            content: '\2192';
            font-size: 24px;
            position: relative;
            top: 1px;
        }
    }
}
// Differentials
.tmp #differ {
    .card {
        margin-bottom: 1.5rem !important;
        .card-block {
            .rowCondition{
                border-bottom: 1px solid #ddd;
                .accordion-toggle h3 {
                    padding: 0.5rem 0 0;
                    display: inline-block;
                    span.icon-bp-icons-red-flag:before, [class^="icon-"]:before, [class*=" icon-"]:before{
                        line-height: 0.2rem;
                    }
                }
            }
        }
    }
    p.topicLink {
        &:after {
            color: #c50084;
            content: '\2192';
            font-size: 24px;
            position: relative;
            top: 1px;
        }
    }
    h3 span a {
        color: $black;
    }
}
// Investigations
.invest, #differ, #diffConditionTable, .complications {

    // Complications
    #compTitle, .diffDiagnosis2 {

        h3 {
            a {
                position: relative;
            }
        }
    }
    .complications #compTitle h3 {
        float: right;
        a {
            color: #c50084;
            font-weight: normal
        }
    }
    #complicationsTable {
        h3 {
            display:flex;
            font-size: 1rem;
            font-weight: bold;
        }
    }
    #complicationsTable {
        &.tblStyle01 tbody {
            tr:nth-child(odd) {
                background-color: $white;
            }
            tr:nth-child(even) {
                background-color: $white;
            }
            tr:first-child th {
                padding-top: 1rem;
            }
            tr + tr td {
                padding: 1rem;
                p.topicLink {
                    &:after {
                        color: #c50084;
                        content: '\2192';
                        font-size: 24px;
                        position: relative;
                        top: 1px;
                    }
                }
            }
        }
        th.accordion-toggle {
            cursor: pointer;
            .material-icons {
                position: relative;
                top: 3px;
            }
        }
        @media (max-width:420px) {
                font-size: 90%;
                thead th {
                    font-size:  0.625rem;
                }
            h3 {
                font-size: 0.8125rem;
                span.material-icons {
                    padding-top: 2px;
                }
            }
        }
    }
}

// Investigations
.invest, #diffConditionTable {
    .card-block {
        padding: 0 !important;
    }
    .rowCondition {
        border-bottom: 1px solid #ddd;
        padding: .5rem 1rem;
        background-color:none;
        h3 {
            cursor: pointer;
            display: block;
            padding-top: 5px;
            margin-left: 20px;
            line-height: 1.5;

            a{color: $black}
        }
    }

    .collapse.show {
        display: block;
    }
    .collapse  {
        display: none;
    }
}

// History & Exam & Differentials
.histExam, .invest, #differ, .complications {

        h3 a .material-icons {
            span + span {
            display: none;
            }
            span:first-child {
            display: block;
            }
        }

        h3 a > span + span {//or .hidden-sm-down
            text-transform: uppercase;
            float: right;
        }
        h3 a > span + span.icon {//reset for above for icons
            float: none;
        }
        .show-content h3 .material-icons {
            span:first-child {
            display: none;
            }
            span {
            display: block;
            }
        }
        .show-content .panel-content {
            display: block;
        }

        @media (min-width:1024px) {
            .txtwrap {
            display: block;
            width: 100% !important;
            float: right;
            padding-left: 32px;
            }
            /* new hack ends **************** ..... */
        }
}
// History & Exam
#kdf, #odf, #rfs, #differ {
    .rowHead {
        h3 a {
            color: $white;
            padding-left: 0;
        }
        .material-icons,
        .material-icons.open {
            color: $white;
        }
    }

    .card-block {
        h3 > a > span {
            position: relative;
            top: 5px;
            font-size: 1.125rem;
        }
        .msg {
            font-size: 1rem;
        }
    }
    .collapsing {
        transition: height 0.25s;
    }
    .accordion-toggle {
        padding-left: 0;
        cursor: pointer;
        h3 {
            position: relative;
            top: 5px;
        }
    }
    .collapse {
        height: 0;
        display: none;
    }
    .show {
        height: auto;
        display: block;
    }
}

#diffConditionGroup, #diffCommonContainer, #diffUnCommonGroup {
    .row.rowCondition {
        padding: 0;
        background: none;
        summary {
            background-color: $palette-blue-040;
        }
    }
}

#complicationsTable {
    .js-toggle-header {
        .material-icons {
            margin-right: px-to-rem(10);
        }
    }
    tbody {
        &:last-child{
            border-bottom: 1px solid $dark-blue;
        }
    }
}
.complication-table {
    .complication-table-collapse-content {
        padding-left: 3rem;
        .w-75{
            width: 70%!important;
        }
    }
    .complication-table_header{
        background-color: $dark-blue;
        border-bottom: 1px solid $white;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-content: stretch;
        align-items: flex-start;
        .complication-table_header-item {
            padding: 0.4rem 0.6rem;
            padding-left: 1rem;
            font-weight: bold;
            font-size: 0.875rem;
            color: white;
            text-transform: uppercase;
            @include media-breakpoint-down(md){
                font-size: 0.625rem;
            }

            &:nth-child(1){
                flex: 0 1 55%;
            }
            &:nth-child(2){
                flex: 0 1 21%;
                text-align: center;
            }
            &:nth-child(3){
                flex: 0 1 24%;
                text-align: center;
            }
        }
    }
    .complication-table_content {
        .complication-table_content-item {
            border-bottom: 1px solid $dark-blue;
        }
        .complication-table_content-inner {
            display: flex;

            .accordion-toggle {
                background: $transparent;
                width: 100%;
                display: block;
                border: none;
                padding:0;
                font-size: 1rem;
                color: $palette-blue-a900;
                font-weight: bold;
                text-align: left;
                margin-bottom: px-to-rem(8);
                cursor: pointer;
                .material-icons {
                    margin-right: px-to-rem(5);
                    line-height: 1;
                }
                &:focus {
                    outline: none;
                }
                &:focus-visible {
                    outline: -webkit-focus-ring-color auto px-to-rem(1);
                }
                @include media-breakpoint-down(md){
                    display: flex;
                    font-size: px-to-rem(13);
                }
            }
            .complication-table_content-inner-box {
                .accordion-toggle {
                    display: flex;
                }
                padding-top: 1rem;
                padding-left: 1rem;
                padding-right: px-to-rem(8);
                padding-bottom: px-to-rem(8);
                &:nth-child(1){
                    flex: 0 1 55%;
                    text-align: left;
                }
                &:nth-child(2){
                    flex: 0 1 21%;
                    text-align: center;
                    color: $palette-blue-a900;
                    @include media-breakpoint-down(md){
                        font-size: 13px;
                    }
                }
                &:nth-child(3){
                    flex: 0 1 24%;
                    text-align: center;
                    color: $palette-blue-a900;
                    @include media-breakpoint-down(md){
                        font-size: 13px;
                    }
                }
            }
        }
    }
}

.topic-poce .locked-banner {
    margin-top: px-to-rem(-24);
}

.diffDiagnosis.table {
    .table {
        margin-bottom: 0;
    }

    .conTitle {
        display: revert !important;
    }

    .rowHead th {
        color: $white;
        padding: 0 1.75rem;
        font-size: 14px;
        font-weight: 700;
        height: 0;
    }

    @media only screen and (max-width: 768px) {
        .rowDetails {
            display: flex;
            flex-direction: column;
            padding: 0;
        }

        .conTitle {
            height: auto;
        }

        .table td {
            height: auto;
        }
    }
}

.prevention-table-intro {
    background: #EAF1F8 ;
    margin-bottom: -1rem;
}

#diffConditionGroup {
    .acc-panel {
        .d-md-none {
            background: #e0e0e0;
            font-weight: bold;
            font-size: .95rem;
            padding: 0.1875rem;
        }
    }
}

.acc-panel-with-table {
    .d-md-none {
        background: #e0e0e0;
        font-weight: bold;
        font-size: .95rem;
        padding: 0.1875rem;
        text-transform: uppercase;
    }
}