@mixin rowMachine($numPerRow, $margin) {
  width: ((100% - (($numPerRow - 1) * $margin)) / $numPerRow);
  &:nth-child(n) {
    margin-bottom: $margin;
    margin-right: $margin;
  }
  &:nth-of-type(#{$numPerRow}n) {
    margin-right: 0;
    margin-bottom: 0;
  }
}