.drugs-db {
    .img a{
        display: block;
        overflow: hidden;
        width: 100%;
        height: px-to-rem(200);
    }
    .img a img {
        width: 50%;
        height: inherit;
        -o-object-fit: contain;
        object-fit: contain;
    }
    .col-md-6 {

        .img {
            border: 1px solid #f8f8f8;
            margin-bottom: 8px;
            padding: 8px;
            text-align: center;
        }
        .img + p, .img + p + p  {
            text-align: center;
        }

        a {
            span.material-icons {
                font-size: inherit !important;
                color: inherit !important;
                margin-top: -0.3rem;
            }
        }
    }

    @media only screen and (min-width : 768px) {//desktop
        & > .card > .card-block > .row {
            margin-top: 2rem;
            margin-bottom: 2rem;

            & > div {
                margin-top: 0.5rem;
            }
        }

        .col-md-6 {//can't use extra .row on this page.
            min-height: 160px !important;
        }
    }

}

.popover .popover-content ul.pop-list {//used in drugs treatment table
    padding: 0;
    margin: 0;
    li {
        padding: 0 0 0.2rem 0;

        a {
            margin-left: -0.3rem;

            span.material-icons {
                color: inherit !important;
                position: relative;
                top: -1px;
            }
        }
    }
}