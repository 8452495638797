bp-content-filter {

  .bp-content-filter__input {
    background-color: #fff;
    border: 0.0625rem solid $palette-bmj-grey-100;
    border-radius: 0.1875rem 0 0 0.1875rem;
    font-size: 1rem;
    padding: 0.75rem 2rem 0.75rem 1rem;
    width: 20rem;
    margin-bottom: 1rem;
  }

  .bp-content-filter__label {
    position: relative;
  }

  .bp-content-filter__icon {
    position: absolute;
    top: .7rem;
    right: .7rem;
  }

  color: #444;

  .atoz_heading {
    border-bottom: 1px solid $palette-grey-300;
    font-size: 2.125rem;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 2.5rem;
    margin-bottom: 1rem;
  }

  .atoz_list {
    list-style: none;
    padding: 0;
    margin-bottom: 0;

    li   {
      margin-bottom: 1.5rem;
    }

    .atoz_list_text {
      display: block;
    }

    a .atoz_list_text {
      display: inline;
    }

    a {
      color: $palette-blue-500;
      text-decoration: none;
      display: block;
    }

    li:has(.atoz_list_description) .atoz_list_text {
      font-weight: 700;
    }

    mark {
      color: $palette-pink-500;
      background-color: rgba(0, 0, 0, 0);
      font-weight: bold;
      padding: 0;
    }

  }

}