// Admin:
  //Remove first TOC item
ul#markdown-toc {
  li:first-child {
    display: none;
    }
}

  // Code Block styles
  pre {
    font-size: 1rem;
    padding: 1rem;
    margin: 1rem;
  }

//Access
  .bt-1 {
    border-top: 1px solid  $black-secondary-solid;
  }

// SERP borders
//
  .border-l,
  .serp .card-group .card:first-child {
    border-left: 10px solid  $palette-pink-500;
  }

  .serp_detail .border-t {
    border-top: 10px solid  $palette-pink-500;
  }

   .tmp .border-t {
    border-top: none;
  }

// btn for reg / login modal - quick fix as code not working
  .btn-lg {
      padding: 1rem;
  }


/* DEFAULT TAB NAV STYLES */

.nav-tabs .nav-link::before {
  height: 5px;
  }

// SPINNER ON BUTTONS WHILE LOADING
  .progress-circular-spinner {
    border: 2px dotted $white;
    height: 1rem;
    top: 15px;
    width: 1rem;
  }
  .progress-circular-left {
        left: 15px;
  }/* Scss Document */

// HIDDEN REFERENCES CONTENT
span.reference span {
    display: none;
}
.referencePop, .gradeTablePop, .viewGradeTable {
    color: $palette-pink-500;
}
.highlighted {
  a {
    background-color: #ffe8ae;
  }
}

.button-as-link {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0 5px 0 0;
  color: $palette-blue-500;
}

.button-as-link:hover {
  color: $palette-pink-500;
}
