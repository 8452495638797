.paywall-header {
  padding-right: px-to-rem(15);
  padding-top: px-to-rem(24);
  padding-left: px-to-rem(15);
  position: relative;
  z-index: 11;
}

.login-link {
  color: $white !important;
  background: $palette-blue-500;
  border-radius: px-to-rem(6);
  padding: px-to-rem(8);
  width: fit-content;
  margin-left: auto !important;
}

.institution-link {
  &--text {
    color: $palette-grey-a500;
    display: block;
    margin: px-to-rem(-10) 0 px-to-rem(8) 0 !important;
  }
}

//-------------------------------------------------
// CME - CPD
//-------------------------------------------------
.cme-cpd {
  background-color: $palette-pink-500 ;
  border-radius: px-to-rem(3);
  color: $white !important;
  display: flex;
  width: fit-content;
  min-height: px-to-rem(35);
  margin:  0;
  order: 1;
  @include ie-only(){
    height: px-to-rem(32);
  }

  &__text {
    text-decoration: none !important;
    color: $white !important;
    padding: 0 px-to-rem(10) 0 px-to-rem(10);
    &:last-child {
      padding: 0 px-to-rem(10) 0 px-to-rem(10);
    }
  }

  &__credits {
    color: $white;
    background-color: #88005B;
    padding: px-to-rem(6) px-to-rem(10);
    height: 100%;
    display: flex;
    align-items: center;
  }

  a {
    display: flex;
    align-items: center;
  }
}

//-------------------------------------------------
// Notifications
//-------------------------------------------------
.notifications {
  color: $white;
  cursor: pointer;
  background: unset;
  border: unset;
  padding: unset;
  margin: 0 0 0 auto;

  &__icon {
    background-color: #15375E;
    border-radius: 50%;
    width: fit-content;
    margin-top: px-to-rem(8);
    margin-bottom: px-to-rem(5);
    margin-left: px-to-rem(23);
    line-height: 1.2;
    width: px-to-rem(38);
    height: px-to-rem(38);
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      position: relative;
      top: px-to-rem(-1);
    }
  }

  &__counter {
    background-color: $palette-red-500;
    border-radius: 50%;
    font-weight: 700;
    margin: auto auto auto px-to-rem(-13);
    position: relative;
    top: px-to-rem(-15);
    line-height: 1.2;
    width: px-to-rem(30);
    height: px-to-rem(30);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.notifications-dropdown {
  position: relative;
}

.notifications-menu {
  @media only screen and (max-width: 400px) {
    max-width: fit-content;
  }

  display: none;
  max-width: px-to-rem(315);
  width: max-content;
  background-color: #fff;
  border-radius: 0 0 0.375rem 0.375rem;
  box-shadow: 0 0.25rem 0.25rem rgba(0,0,0,.25);
  margin-top: 0.375rem;
  z-index: 21;
  position: absolute;
  text-align: left;

  &.bs-show {
    display: block;
  }

  &__text {
    color:  $palette-grey-a500;
    font-family: interface, Helvetica, Arial, sans-serif;
    font-weight: 400;
    line-height: px-to-rem(24);
    padding: 0.6rem 1rem;
    margin-bottom: 0;
  }

  &__list {
    display: block;
  }

  .icon {
    color: $palette-pink-500;
    margin-right: px-to-rem(4);
  }
  .dropdown-menu {
    padding: 0;
    border: px-to-rem(1) solid $palette-grey-200;
    margin-top: revert;

    @include media-breakpoint-between(sm, md) {
      width: min-content;
    }
    a {
      &:hover {
        color: unset;
      }
    }
  }

  .bs-dropdown-item {
    border-bottom: px-to-rem(1) solid $palette-grey-200;
    padding: 0.6rem 1rem;
    &:last-of-type {
      border-bottom: none !important;
    }
    &:hover {
      background-color: $palette-grey-200;
    }
  }
}

//-----------------------------------------------------------------------------------------------
// Language switcher
//-----------------------------------------------------------------------------------------------

.languageSelect {
  border: px-to-rem(1) solid $palette-bmj-grey-100;
  border-radius: px-to-rem(3);
  color: $black-charcoal;
  width: px-to-rem(223);
  font-weight: 700;
  height: fit-content;
  padding: px-to-rem(6) px-to-rem(11) px-to-rem(4.2);
  padding-left: px-to-rem(36);
  background-color: $white;
  background-image: url("/images/globe.svg"), url("/images/chevron-down.svg");
  background-repeat: no-repeat;
  background-position: px-to-rem(10) center, calc(100% - px-to-rem(10)) center;
  appearance: none;
}



@include media-breakpoint-up(lg){

  .paywall-header {
    padding: px-to-rem(16) 0;
    margin-bottom: auto;
  }


  .institution-link {
    &--text {
      display: none !important;
    }
  }

  .login-link {
    color: $palette-blue-500 !important;
    background: unset;
    padding: unset;
    width: auto;
  }

  .cme-cpd {
    order: 0;
  }

  .notifications {
    margin-right: unset;
  }

  .language-switching {
    top: unset;
    margin: 0 0 0 px-to-rem(19);
  }
}

.hide-dropdown {
  display: none;
}

.institution-user {
  .select__wrapper {
    top: 0.6875rem;
    margin-bottom: 1.875rem;
  }
}

@media only screen and (max-width: 375px) {
  .cme-cpd.pt-br {
    font-size: px-to-rem(13);

    .cme-cpd__credits {
      padding: px-to-rem(6) px-to-rem(6) px-to-rem(7);
    }
  }
}

//-----------------------------------------------------------------------------------------------
// RF - Paywall header
//-----------------------------------------------------------------------------------------------

.rf-paywall-header {
  padding:0;
  margin:0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: px-to-rem(16);
  font-weight: bold;
}

.rf-paywall-header__link--login {
  color: $white;
  background: $palette-blue-500;
  border-radius: px-to-rem(6);
  padding: px-to-rem(8);
  margin-left: auto;
  width: max-content;
  font-weight: bold;
  border: none;
}

.rf-paywall-header__mobileLoginOptions {
  list-style: none;
  padding: px-to-rem(8) 0;
  margin: 0;
  text-align: right;
    a {
      color: $palette-grey-a500;
      display: block;
      margin: px-to-rem(10) px-to-rem(0) px-to-rem(9);
      font-weight: bold;
    }
    li:first-child a:after {
      border-bottom: 0.0625rem solid #cccdcd;
      content: "";
      display: block;
      margin-left: auto;
      width: 35%;
      padding-top: px-to-rem(8);
  }
}

//-------------------------------------------------
// RF - Paywall header - Logged-out - Mobile
//-------------------------------------------------
.rf-paywall--mobile {
  justify-content: space-between;

  @include media-breakpoint-up(lg) {
    display: none;
  }

}

// Hide mobile menu on desktop - even when opened on mobile and resized to desktop
@include media-breakpoint-up(lg) {
  .rf-paywall-header__mobileLoginOptions {
    display: none !important;
  }
}

//-------------------------------------------------
// RF - Paywall header - Logged-out - Desktop
//-------------------------------------------------
.rf-paywall--desktop {
  margin-left: auto;
  width: fit-content;

    .rf-paywall-header__item--divider {
      border-right: px-to-rem(1) solid $palette-blue-500;
      padding-right: px-to-rem(16);
    }

  @include media-breakpoint-down(md) {
    display: none;
  }
}

//-------------------------------------------------
// RF - Paywall header - Logged-in
//-------------------------------------------------
.rf-paywall--loggedIn {

  .notifications__icon {
    margin-left: 0;
  }

  @include media-breakpoint-down(md) {
    flex-wrap: wrap;
    justify-content: space-between;

    > li:first-child {
      width: 100%;
    }
  }
  @include media-breakpoint-up(lg) {
    margin-left: auto;
    width: fit-content;
  }
}