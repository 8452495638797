.alt-table, .poce-table {
   border: solid 1px $palette-grey-300;
   border-collapse: collapse;
   border-spacing: 0;
   font: normal 16px Arial,sans-serif;
   margin: 1rem 0;
   table-layout: fixed;
   width: 100%;

   tr th,
   tfoot td {
       background-color: $palette-grey-100!important;
       border: solid 1px $palette-grey-300;
       color: #333!important;
       padding: 10px!important;
       text-align: left!important;
       text-transform: initial!important;
       font-size: 1rem!important;
       font-weight: bold;
       vertical-align: top;
       p {
           font-size: 1rem;
       }
   }
   tr th.empty {
       width: 15%;
   }
   tbody tr {
       &:nth-child(odd){
           background-color: $white!important;
       }
       td {
           border: solid 1px $palette-grey-300;
           color: #333;
           padding: 10px!important;
           text-align: left!important;
           font-size: 1rem;
           vertical-align: top;
           width: 100% !important;

         p, li, div, span, a, strong, em {
           max-width: 100%!important;
           min-width: 100%!important;
         }

         ul, ol {
           max-width: 100% !important;
           box-sizing: border-box;
           width: 100% !important;
         }

         &:first-child {
               text-align: left!important;
           }
           p {
               text-align: left;
               font-size: 1rem;
           }
       }
   }
}