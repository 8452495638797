#browser-update-bar {
  background-color: #E8E8E8;
  border-bottom: 1px solid #CCC;
  box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
  font-size: 0.7em;
  margin: 0;
  overflow: visible;
  padding: 0;
  position: relative;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 9999
}

#browser-update-bar .browser-update-notice {
  display: block;
  font-size: 14px;
  margin: 0 25px;
  padding: 8px;
  position: relative
}

.browser-update-bar-hide {
  display: none
}

#browser-update-hide-button {
  font-size: 30px;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: -3px
}