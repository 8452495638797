.calculators {
  .card {
    .card-block {
          border-bottom: 1px solid $palette-grey-300;

          .nav-link {
            color: $black;

            margin: 0 !important;

            &.active,
            &:hover {
              color: $palette-pink-500;
            }
          }
        h2 {
          border-bottom: 1px solid $palette-grey-300;
        }
    }
  }

  // Topic/calculators page

  .iconList {
    ul > li:before {
      content: none !important;
      }
    .list-group-item {
      display: block !important;
      &:not(:first-of-type)  {
        padding: 1rem 0 0 0;
      }
      a {
        display: flex;
        line-height: 1.5;
        &:hover {
          color: $palette-pink-500;
        }
        .icon-bp-icons-calculators {
            &:before {
                font-size: 1.9rem;
                color: $black-primary;
                position: relative;
                top: 14px;
                margin: 0 10px 0 -6px;
                line-height: .1;
            }
        }
      }
      a:hover .icon-bp-icons-calculators:before { // icon hover
        color: $palette-pink-500;
      }
      p {
        display: table-cell;
        padding: .5rem 0 .5rem 2rem;
      }
    }

  }
  // Icon List mobile
  @media (max-width: 576px) {
    .iconList {
      .list-group-item {
        p {
          display: table-cell;
          margin-top: .5rem;
        }
      }
    }
  }
  .card .card-block p a {
    display: block;
  }

  input, optgroup, select, textarea {
    font-family: Interface, Arial;
    font-size: 1rem;
    padding-left: 1rem;
  }

  .search-only .form-control {
    border: 1px solid $palette-grey-400;
    border-radius: 2px;
    height: 3rem;
  }
  #atoz-list {
        margin-top: 1.25rem;

        p {
          padding: 0.4rem 0;
        }
  }
  #bySpecialty {

    .card {
      box-shadow: none;


      .card-block:last-child {
        padding: 1rem;
      }

    }
  }
}

@media (min-width: 576px) {
  .calculators {
    .input-group {
      width: 50%;
    }
  }
}

#bySpecialty {//arrows: this css just uses the states already created, including an empty class which gets added to the 'a' tag (?)

  div.card-header h5 a:before {font-family: 'Material Icons'; position: relative; top: 3px; }

  div.card-header a:before, //onload state
  div.card-header a.collapsed:before { //second click
    content: '\E5CF'; //down
  }
  .card-header a[class='']:before { //first click
      content: '\E5CE'; //up
  }

}

.no-result--suggest{
  display: none;
}

.calcSpec-title {
  display: block;
  font-weight: 700;
}