// BP popover & Tooltip Styles

.popover {
  max-width: 320px;
  width: auto;

  // Drug DB etc
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    li:before {
        content: "\25CF";
        padding-right: .8725rem;
        vertical-align: top;
    }
  }
  // Evidence score etc
  .evidence-level {
    background-color: #e5eef7;
    border: 1px solid #1f5189;
    padding: 1rem;
    margin-bottom: .5rem;
  }
  // Cochrane etc
  img.cca-logo {
    width: 55%;
    margin-bottom: 1.5rem;
  }
}

@include media-breakpoint-up(sm) {
  .popover {
    max-width: none;
    width: 420px;
    }
}

// Inline CCA, Evidence Score styles

.cochranePop {
  img {
    display: inline;
    float: none !important; // img has a float: right in topics???
    padding-right: 0 !important; // img has a padding-right in topics??
  }
}

@media (max-width: 767px) {
   .cochranePop {
     img {
       width: auto;
     }
   }
}

.evidenceScorePop {
  color: $palette-pink-600;
    &:before {
      content: '['
    }
    &:after {
      content: ']'
    }
    &:hover {
      text-decoration: underline;
    }
}
