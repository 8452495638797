.guidelines{
  @include media-breakpoint-up(sm) {
    .input-group {
      width: 50%;
    }
  }

  .search-only .form-control {
    border: 1px solid $palette-grey-400;
    border-radius: 2px;
    height: 3rem;
    position: relative;
  }
  .icon-bp-icons-search {
    position: absolute;
    right: 0;
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #c50084;
    height: 100%;
    margin-right: 0.5rem;
  }
  #atoz-list {
    margin-top: 1.25rem;

    p {
      padding: 0.4rem 0;
    }
  }
}

