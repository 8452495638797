@include media-breakpoint-up(md) {
  .pw-menu-personal-account{
    display: block;
  }
}

.bs-dropdown-item-button {
  text-align: left;
  font-size: 1rem;
  font-family: $headings-font-family;
  &:hover {
    color: $palette-pink-500 !important;
    cursor: pointer;
  }
  &:focus {
    color: $palette-pink-500;
  }
}

.mobile-img {
  height: px-to-rem(57);
  width: px-to-rem(199);
  position: relative;
  top: px-to-rem(20);
  float: left;
  margin: 0;

  background-repeat: no-repeat;
  background-position: top center;
  background-image: url('/images/BP-Logo-colour.svg');
  background-size: contain;
}


#header {
  background-color: $white !important;
  .navbar {
    display:block;
  }
}

.bs-navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;

}

.bs-nav-link {
  color: $palette-blue-500 !important;
  font-size: px-to-rem(20);
  opacity: unset;
  margin: px-to-rem(10) 0;
  padding: unset;
}

.bs-nav-item {
  flex-direction: column;
  padding: 0.625rem 0;

  &.dropdown {
    padding: 0.625rem 0;
  }

  &:not(:first-child) {
    display: block;
    border-top: px-to-rem(1) solid $palette-bmj-grey-100;
  }
}

.nav-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bs-navbar-toggler {
  background: 0;
  border: 0;
  padding: 0;
}

.bs-dropdown-menu {
  line-height: 1.5rem;
  margin-top: px-to-rem(8);
}

.bs-dropdown-toggle::after {
  content: "";
  border: solid $palette-blue-500;
  border-width: 0 px-to-rem(2) px-to-rem(2) 0;
  display: inline-block;
  margin-left: px-to-rem(8);
  padding: px-to-rem(3);
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  top: px-to-rem(-2);
  position: relative;
  top: px-to-rem(-1);
  vertical-align: middle;
}

.bs-dropdown-toggle[aria-expanded="true"]:after {
  display: inline-flex;
  transform: rotate(224deg);
  top: px-to-rem(1);
}

.bs-dropdown-menu-right {
  right: 0;
  left: auto !important;
  transform-origin: 100% 0;
}

.bs-dropdown-item {
  color: $palette-grey-a500;
  display: block;
  padding: 0 0 px-to-rem(10) 0;

  &--title {
    color: $black-charcoal;
    padding: px-to-rem(9) 0 px-to-rem(11) 0;
    display: block;
    font-weight: 700;
    font-family: interface, Helvetica, Arial, sans-serif;
  }

  &__section {
    &::after {
      content: "";
      border-bottom: px-to-rem(1) solid $palette-bmj-grey-100;
      display: block;
    }
    &:last-child::after {
      border-bottom: none;
      padding-top: px-to-rem(8);
    }
  }
}

.access-nav {
  padding-top: px-to-rem(10);

  &__link {
    color: $palette-pink-500;
  }

  &__section {
    &::after {
      border-bottom: px-to-rem(1) solid $palette-pink-500 !important;
      position: relative;
      top: px-to-rem(1);
      width: 100% !important;
    }
    &::before {
      content: " ";
      border-top: px-to-rem(1) solid $palette-pink-500 !important;
      display: block;
      position: relative;
      top: px-to-rem(-1);
    }
  }
}

//--------------------
// Mobile styles navbar
//--------------------

@media only screen and (max-width: 575.5px) {
  #mainSearch {
    width: 100%;
  }
  .logged-out-nav.container {
    margin-right: px-to-rem(15);
    margin-left: px-to-rem(15);
    max-width: 100%;
    width: 100%;
  }

  #searchBoxForm {
    margin-right: px-to-rem(15);
    margin-left: px-to-rem(15);
  }
}

@media only screen and (max-width: 991.5px) {
  #header {
    display: flex;
    flex-direction: column;
  }

  #mainSearch {
    order: 1;
  }

  .bs-navbar-nav {
    margin-top: 1.875rem;
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }

  .navbar-nav-mobile {
    display: block;
  }

  .hamburger-icon-js {
    cursor: pointer;
    height: px-to-rem(35);
    width: px-to-rem(35);
  }

  .mobile-none{
    display: none;
  }

  .logged-out-nav {
    margin-top: px-to-rem(30);
  }

  .bs-nav-link {
    display: inherit;
    margin: 0 !important;
  }
}

.bs-dropdown-menu {
  display: none;
}

.display-mobile {
  display: block;
}

//--------------------
// Desktop styles navbar
//--------------------

@media only screen and (min-width: 992px) {
  .navbar {
    background-color: $palette-blue-500;
    display: block;
    line-height: px-to-rem(45);
    padding:0;
    font-family: $headings-font-family;
    .dropdown-menu {
      margin-top: px-to-rem(5);
    }
  }

  .bs-navbar-nav {
    justify-content: space-between;
    width: 100%;
    padding: unset;
    flex-direction: row;
    margin: 0;
  }

  .hamburger-icon-js, .bs-navbar-toggler {
    display: none;
  }

  .bs-nav-link {
    color: $white !important;
    font-size: px-to-rem(20);
    opacity: unset;
    display: inline;
  }

  .bs-nav-item.dropdown {
    padding: revert;
  }

  .bs-nav-item {
    border-top: none !important;
    padding: revert;
  }

  .mobile-img {
    display: none;
    &--china {
      display: none;
    }
  }

  .pw-menu-personal-account{
    display: none;
  }

  .bs-dropdown-toggle::after {
    border: solid #fff;
    border-width: 0 0.125rem 0.125rem 0;
  }

  .bs-dropdown-menu {
    background-color: $white;
    border-radius: 0 0 px-to-rem(6) px-to-rem(6);
    box-shadow: 0 px-to-rem(4) px-to-rem(4) rgba(0, 0, 0, 0.25);
    margin-top: px-to-rem(6);
    padding: px-to-rem(10) px-to-rem(12) px-to-rem(24);
    z-index: 21;
    position: absolute;
    min-width: 17rem;
  }

  .bs-dropdown-item {
    display: block;
    padding: 0 0 px-to-rem(11) 0;

    &--title {
      margin: px-to-rem(12) 0 px-to-rem(13);
      padding: 0;
    }

    &__section {

      &:not(:last-child) {
        content: "";
        border-bottom: px-to-rem(1) solid $palette-bmj-grey-100;
        display: block;
        margin: auto;
      }

      &::after {
        content: "";
        border-bottom: none;
        padding: inherit;
        width: calc(100% - 38%);
      }

      &:last-child::after {
        border-bottom: none;
        padding-top: 0;
      }
    }
    &__email {
      .bs-dropdown-item--title {
        margin-bottom: 0;
        font-size: px-to-rem(14);
      }
      .bs-dropdown-item {
        font-size: px-to-rem(14);
      }
    }
  }

  .bs-dropdown-item-profile {
    .bs-dropdown-item__section-profile {
      border-bottom: none;
      font-size: px-to-rem(14);
      .bs-dropdown-item--title {
        margin-bottom: 0;
      }
      .bs-dropdown-item {
        padding-bottom: 0;
      }
    }
  }

  //--------------------
  // Active BG Nav menu desktop
  //--------------------

  .bs-nav-item.active-bg {
    position: relative;
  }

  .bs-nav-item.active-bg:before {
    content: "";
    position: absolute;
    top: 0;
    left: px-to-rem(-10);
    right: px-to-rem(-10);
    bottom: 0;
    width: calc(100% + 20px);
    height: auto;
    background: #2663a8;
    z-index: -1;
  }

  //bg styling
  .bs-nav-item.dropdown.active-bg:before {
    content: "";
    position: absolute;
    top: 0;
    left: px-to-rem(-10);
    right: px-to-rem(-10);
    bottom: 0;
    width: calc(100% + 20px);
    height: auto;
    background: #2663a8;
    z-index: -1;
  }

  //bg border bottom
  .bs-nav-item.dropdown.active-bg.active-menu:after {
    content: "";
    background: #2663a8;
    display: block;
    height: 0.3125rem;
    position: absolute;
    left: px-to-rem(-10);
    right:px-to-rem(-10);
    width: calc(100% + 20px);
  }

  .bs-nav-item.dropdown.active-bg > .bs-dropdown-menu {
    left: px-to-rem(-10);
    right: px-to-rem(-10);
    width: calc(100% + 20px);
  }

  .bs-nav-item:last-child.active-bg > .bs-dropdown-menu {
    left: unset;
  }

  .bs-nav-item.dropdown.active-menu {
    &:after {
      content: "";
      background-color: $palette-blue-a900;
      display: block;
      height: 0.3125rem;
      position: absolute;
      width: 100%;
    }
  }
}

.bs-nav-link:focus{
  outline: px-to-rem(1) solid;
  outline: px-to-rem(5) auto -webkit-focus-ring-color;
}

#header:not(:has(.paywall-header)) {
  padding-top: 2rem;
}

.logged-in-nav {
  .bs-nav-item:last-child{
    .bs-dropdown-menu {
      .bs-dropdown-item__section:first-child{
        font-size: px-to-rem(14);
      }
    }
  }
}