//this file - not written as nested 'sass' so that we can reorder everything later

//does not included navs: _topic-nav.scss
//does not include math forms: _ebm-calculators.scss
//does not include access forms etc: _access.scss
//does not include any form element font styling
//does not include _utilities.scss for modal popups
//does not include _access.scss for modal popups
//does not include _srp.scss special styles for search forms etc
//does not include .nav-tabs * (in _global-elements.scss)
//have left padding for figs in situ


@font-face {
  font-family: 'interfaceregular';
  src: url('/fonts/if_std_rg-webfont.eot');
  src: url('/fonts/if_std_rg-webfont.eot?#iefix') format('embedded-opentype'),
       url('/fonts/if_std_rg-webfont.woff') format('woff'),
       url('/fonts/if_std_rg-webfont.ttf') format('truetype'),
       url('/fonts/if_std_rg-webfont.svg#interfaceregular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;

}

@font-face {
  font-family: 'interfaceitalic';
  src: url('/fonts/if_std_it-webfont.eot');
  src: url('/fonts/if_std_it-webfont.eot?#iefix') format('embedded-opentype'),
       url('/fonts/if_std_it-webfont.woff') format('woff'),
       url('/fonts/if_std_it-webfont.ttf') format('truetype'),
       url('/fonts/if_std_it-webfont.svg#interfaceitalic') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.container {// Best Practice Typographic Overrides - App speciific
    h1,
    .medCalcIndexIPTitle { // Style for EBM calculators headings too
        color: $black-primary;
        margin: 2rem 0 1rem 0;
        .material-icons {
            font-size: 24px;
            a {
                color: inherit;
                &:hover {
                    color: $palette-pink-500;
                }
            }
        }
    }
}


.tmp .rowCondition h3,
.tmp .accordion-toggle h3,
.tmp .rowDetails .conTitle *,
.treatment-table h3,
.more-title {font-family: Arial, Helvetica, sans-serif; }

//************* PINK starts ************
h3 a:hover,
h4 a:hover,
h5 a:hover,
a:hover {color: $palette-pink-500; }/*rgb(163, 0, 84) !important; */

.calculators .card .card-block .nav-link.active,
.specialties-list .card .card-block .nav-pills .nav-link.active,

.calculators .breadcrumb-item:hover,
.calculators .card-header h5 a:hover,
.calculators .card .card-block .nav-link:hover,
.patientLeaflets .breadcrumb-item:hover,
.patientLeaflets .card-header h5 a:hover,
.patientLeaflets .card .card-block .nav-link:hover,
.specialties-list .breadcrumb-item:hover,
.specialties-list .card .card-block .nav-pills .nav-link:hover,
.treatment-table .panel-title a .titles-wrap:hover,
.treatment-table .panel-heading a .msg,
.material-icons,
.popover .web-link:hover .material-icons,
.more-edit span.reference .more-article:hover,
.more-edit span.reference .more-article:hover span,
ul#searchByLanguage li a.active {color: $palette-pink-500; }

.nav-tabs .nav-link::before,
.card-header a:before,
#searchBoxWrap .tt-open:before,
#searchBoxWrap .tt-suggested:before {color: $palette-pink-500 !important; }

.popover {
    ul li:before,
    .evidence-level h4 {color:$palette-pink-500; }
}
.topic {
    a.more-link,
    a.less-link,
    .card .card-block.lastUpdate p {color: $palette-pink-500; }
}
.tmp {
    a.refLink,
    a.web-link,
    a.web-link span,
    button.refLink,
    .referencePop,

    .panel-title a .titles-wrap .title-03,
    .treatment-wrap h4,
    .treatment-wrap .small-or,

    .rowCondition h3 a:hover,
    .rowCondition h3 a:hover .material-icons,

    table.tblStyle01 a:hover {color: $palette-pink-500; }
}
.tmp.blue {
    a.web-link:hover,
    a.web-link:hover .material-icons {color: $palette-pink-500 !important; }
}
//************* BLUE starts ************
.tmp.blue {
    a.web-link,
    a.web-link .material-icons {color: $palette-blue-500; }
}
.tmp {
    h3 a,
    .rowCondition h3 a,
    .rowCondition h3 a .material-icons,
    .rowDetails .conTitle,
    a:hover,
    button:hover {color: $palette-blue-500; }
}
.popover .web-link .material-icons,
.treatment-box .material-icons,
.more-edit span.reference .more-article span,
.calculators .breadcrumb-item,
.patientLeaflets .breadcrumb-item,
.specialties-list .breadcrumb-item,
ul#searchByLanguage li a {color: $palette-blue-500; }


//************* BLACK starts ************
.patientLeaflets .card .card-block .nav-link,
.patientLeaflets .breadcrumb-item span,
.patientLeaflets .card-header h5 a,
.patientLeaflets .card .card-block a.accordion-toggle,
.calculators .card .card-block .nav-link,
.calculators .breadcrumb-item span,
.calculators .card-header h5 a,
.calculators .card .card-block a.accordion-toggle,
.specialties-list .card .card-block .nav-pills .nav-link,
.specialties-list .breadcrumb-item span {color: $black; }

.topic .card .card-header a,
.topic .card .card-block.lastUpdate p span,
.topic .card-inverse,
.tmp .rowDetails figcaption,
.tmp .rowDetails figcaption span.imgCredit,
/*.histExam*/.tmp .strong .rowCondition {color: $palette-grey-800; }

//************* WHITE starts ************
ul.a_z a,
.details .rowHead a {
    color: $white;
}
.details .rowHead a:hover {
    color: $white !important;
}
.tmp {
    table.tblStyle01 thead th,
    .treatment-wrap .and-or,
    .rowHead {color: $white; }
}
.rowHead a span.material-icons span {color: $white; }

//************* GREY starts **********
.diffDiagnosis .conTitle h3,
.diffDiagnosis2 .conTitle h3,
.details .rowCondition h3 a,
.tmp .figure .fig-caption span,
.treatment-table h3 a,
.treatment-table .panel-title a,
.treatment-table .panel-title a:hover,
.form-group.has-danger label.custom-control.custom-checkbox span {color: $palette-grey-800 !important; }

.treatment-table h5 {color: $black !important; }


//************* BOLD starts ************
.specialties-list .card .card-block .nav-pills,
.calculators .card .card-block .nav-pills,
.patientLeaflets .card .card-block .nav-pills,
.treatment-table .panel-title a .titles-wrap .title-03,
.treatment-table .panel-title a .titles-wrap .title-04,
.treatment-table h3,
.tmp .rowCondition h3,
.tmp .rowDetails .conTitle h3,
.tmp .rowHead,
.tmp table.tblStyle01 thead th {font-weight: bold; }


.treatment-table .treatment-wrap .small-or,
.treatment-table .treatment-wrap .and-or,
.tmp .rowDetails .conTitle {font-weight: 700; }

//************* LINE HEIGHT starts ************
.tmp .card .card-block p {line-height: 1.5rem; }
.calculators ul li {line-height: 2; }
.specialties-list ul.topic-list li {line-height: 3; }
.topic .card .card-block address {line-height: 2.25; }
.tmp h3 {line-height: 1; }


.tmp .figure-caption {line-height: normal !important; }

//************* FONT SIZE starts ************
.serp h1, .noResults h1 { font-size: $font-size-headline; }
.serp_detail .card .list-group-item h4 a .material-icons { font-size: $font-size-headline; }
.serp_detail .card .list-group-item .material-icons { font-size: $font-size-headline; }
.serp_detail .card .list-group-item .material-icons-inline { font-size: inherit; }
h4[id^="patLeaflet_"] a:before, h4[id^="drug_"] a:before, h4[id^="calc_"] a:before  { font-size: $font-size-headline; }

@media (min-width: 576px) {
    .serp .card a.card-link:after {
      font-size: 11px;
    }
  .serp .card a.card-link{
    &:nth-last-of-type(1){
      &:after{
        content: none;
      }
    }
  }
}

// Topic Landing
.topic span.read-more__link { font-size: $font-size-body-2; }

.tmp h2 {font-size: 2.125rem; }
.tmp h3 {font-size: 1.5rem; }

.tmp .rowCondition h3,
.topic .card .card-block p,
.tmp .figure .fig-caption span,
.tmp .rowDetails p,
.tmp h3 a,
.tmp h3 span.reference,
.tmp .rowDetails .conTitle h3,
.tmp button,
.treatment-table h3 {font-size: 1rem; }

.tmp .rowCondition {font-size: 1.125rem; }
.tmp .rowHead,
.tmp table.tblStyle01 thead th {font-size: 0.875rem; }

.tmp table.tblStyle01 tbody tr + tr td > a {font-size: 0.94rem; }
.treatment-table {

    h3 a {font-size: inherit; }
    h3 a > span + span,
    .panel-title a .title-03,
    .panel-title a .title-04,
    .panel-content p.smPopTxt {font-size: 0.875rem; }
    .treatment-wrap h4 {font-size: 1rem; }
}

ul.a_z li,
.calculators ul li,
.patientLeaflets ul li,
.specialties-list ul.topic-list li,
.popover .evidence-level h4,
.topic .card .card-block h5,
.topic .card .card-block h6,
.topic .fullDetails a,
.topic .card .card-block address,
.topic .card .card-block p,
.calculators .card .card-block p,
.patientLeaflets .card .card-block p,
.htmlTable .rowDetails figcaption,
.diagGuidelines .rowCondition .rowDetails span,
.popover ul li,
.popover p {font-size: $font-size-subheading; }

.fullDetails-disabled {
   color: $palette-grey-a500;
   font-size: $font-size-subheading;
}

.fullDetails-locked {
   font-size: 0.9rem;
   color: $palette-pink-500;
   padding: 1rem;
    @include media-breakpoint-up(sm) {
      padding: 0;
    }
}

.tmp a.fullDetails-locked:hover {text-decoration: underline; color: $palette-pink-600;}

.details .rowHead a .msg {font-size: 90%; }
#feedbackContainer fieldset.form-group label {font-size: 0.9rem; }
#feedbackContainer .modal-body .row.smTxt div p {font-size: 0.775rem !important; }

h2.h3.form-message {font-size: 1.4rem; }//used in altLogin subscription required pgs

//************* UNDERLINES starts ************
.treatment-table h3 a:hover > span > span:first-child,
.treatment-box a:hover,
.details .rowHead a:hover .msg,
.tmp a.refLink:hover,
.tmp a.web-link:hover,
.tmp .referencePop:hover {text-decoration: underline; color: $palette-pink-600;}
.tmp .gradeTablePop:hover {text-decoration: underline; color: $palette-pink-600;}


.patientLeaflets .card .card-block .nav-pills h2 {margin: 0 2.5rem; }
.patientLeaflets .card .card-block p {margin: 1rem 0; }
.patientLeaflets .card .card-block h2 {margin: 0; }
.calculators .card .card-block .nav-pills {padding-left: 1.5rem; }
.calculators .card .card-block .nav-pills h2 {margin: 0 2.5rem; }
.calculators .card .card-block .nav-link {margin: 0.25rem 0; }
.calculators .card .card-block p {margin: 1rem 0; }

.specialties-list .card .card-block .nav-pills {padding-left: 6px; }//1.5rem;
.specialties-list .card .card-block .nav-pills .nav-link {margin: .25rem 0; }


.treatment-table h3 {padding: 0; margin: 0; }
.treatment-table .panel-header {padding-left: 1rem; }
.treatment-table .panel-title a {text-decoration: none; }
.treatment-table .panel-title a .titles-wrap:hover .title-03 {/*text-decoration: underline; */}
@media only screen and (max-width : 768px) {
    .treatment-table .rowHead > div {padding-left: 1.5rem; }
    //.treatment-table h3 a {padding-left: 1.5rem; }
}

.tmp > h2 {margin-bottom: 1rem; }
.tmp > h3 {margin-top: 1.6rem; margin-bottom: 1rem; }
.tmp > h2 + h3 {margin-top: 0; }
.tmp section > h3 {padding: 0.5rem 1rem 0.2rem 0; }
.tmp .card + h2,
.tmp h2 + .card,
.tmp .card + h3,
.tmp h3 + .card {margin-top: 1rem; }
.tmp .card-block > section > h3 {padding-top: 0; float:none!important;}//FLOAT - fix li's jumping off screen NG-1066

.tmp p span.reference a {font-weight: normal; }

 .topic #commomEvalDiffs .fullDetails a, .topic #evalDifferentials .fullDetails a {margin-left: 1.5rem; }
.topic .card .card-header {padding: .55rem 1rem .125rem 1rem !important; }
.topic .card .card-header h5 { margin-left: 1.5em; text-indent: -1.4em;}
.topic .card .card-block.lastUpdate p + p {padding: 0; }
.topic .card .card-block.cb-inner {padding-top: 0; padding-left: 2.25rem; }
.topic .card-inverse .card-block h4 {padding-left: .2rem; }

/*.vertFig .figure span,
.vertFig2 .figure span {font-weight: normal; }

.vertFig .figure span .source,
.vertFig2 .figure span .source, */
.tmp .rowDetails .figcaption span.imgCredit {font-style: italic; }

p.ref-link {text-transform: uppercase; text-align: right; display: inline-block; width: 100%; }

.popover ul li {padding: 0 0 1rem 0; }

/* PLACEHOLDER STYLES */
::-webkit-input-placeholder {color: $palette-grey-600; }/* WebKit, Blink, Edge */
:-moz-placeholder {color: $palette-grey-600; opacity:  1; }/* Mozilla Firefox 4 to 18 */
::-moz-placeholder {color: $palette-grey-600; opacity:  1; }/* Mozilla Firefox 19+ */
:-ms-input-placeholder {color: $palette-grey-600; }/* Internet Explorer 10-11 */
::-ms-input-placeholder {color: $palette-grey-600; }/* Microsoft Edge */


.topic-page {
  .rowHead {
    background-color: $palette-blue-500;
    display: table;
    margin-bottom: px-to-rem(21) !important;
    width: 100%;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: 1.2rem;
  }

  .panel-heading {
    .tx-content a, span {
      color: $black-charcoal !important;
      padding: 0 1rem 0.8125rem 1.5rem !important;
      &:last-child {
        margin-bottom: px-to-rem(8);
      }
    }
  }
}

