.access-through-institution-page {
  background: white;
  #main-content {
    a:hover:not(.improve-bp__content-btn, .contact-us a) {
      color: #23527c;
    }
  }
  .breadcrumb {
    background: transparent;
    margin-bottom: 0;
    padding-top: px-to-rem(50);
    display: flex;
    padding-left: 0;
    padding-bottom:0;
    gap:5px;
  }
  .block {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .block__content {
    flex: 3.1;
  }

  .block__heading {
    color: $palette-blue-500;
    font-style: normal;
    font-weight: 400;
    font-size: px-to-rem(42);
    line-height: 49px;
    margin-bottom: 0.7em;
  }

  .block__aside {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    position: relative;
  }

  .block__icon--1 {
    img:nth-child(1){
      position: absolute;
      top: px-to-rem(72);
      bottom: 0;
      margin: auto;
      left: px-to-rem(45);
    }
  }

  .ptb-m {
    padding: px-to-rem(50) 0;
  }

  .info-block__content {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    max-width: 75%;
    .info-block__subheading {
      margin-bottom: px-to-rem(20);
      font-weight: 400;
      font-size: px-to-rem(18);
      color: $black-charcoal;
      font-family: "interfaceregular", Arial, Helvetica, sans-serif;
      line-height: 24px;
    }
  }

  .improve-bp {
    display: grid;
    grid-template-columns: 25% 75%;
    padding: px-to-rem(50) 0;
    img {
      width: px-to-rem(200);
    }
    .improve-bp__content {
      h3 {
        font-size: px-to-rem(34);
        margin-bottom: px-to-rem(20);
        color: $black-charcoal;
      }
      p {
        margin-bottom: px-to-rem(20);
      }
      .improve-bp__content-btn {
        padding: px-to-rem(14) px-to-rem(24) px-to-rem(12);
        background: $palette-pink-500;
        border-radius: 5px;
        display: inline-block;
        font-weight: 400;
        font-size: px-to-rem(20);
        line-height: 23px;
        color: $white;
        margin: px-to-rem(30) 0 px-to-rem(60) 0;
      }
    }
  }

  .bp-access {
    .bp-access__title {
      h3 {
        font-weight: 400;
        font-size: px-to-rem(20);
        line-height: 28px;
        margin-bottom: px-to-rem(20);
      }
    }
    .bp-access__row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: px-to-rem(40);
      text-align: center;
      margin-top: px-to-rem(60);
      padding: 0 px-to-rem(7.5);

      .bp-access__column-title {
        h2 {
          font-weight: 700;
          font-size: px-to-rem(20);
          color: #2a6ebb;
          margin-bottom: px-to-rem(25);
          line-height: 32px;
        }
      }

      .bp-access__column {
        background: #eaf1f8;
        border-radius: 10px;
        padding: px-to-rem(30) px-to-rem(25) px-to-rem(80);

        p {
          color: $palette-blue-500;
        }

        .bp-access__column-icon {
          margin-bottom: px-to-rem(25);
        }
      }
    }
  }

  .access-code {
    .access-code__row {
      display: grid;
      grid-template-columns: 25% 75%;
      .access-code__column {
        img {
          width: px-to-rem(200);
        }
        h3 {
          font-size: px-to-rem(34);
          color: $black-charcoal;
          margin-bottom: px-to-rem(40);
        }
      }
    }
  }

  .contact-us {
    display: flex;
    flex-direction: column;
    gap: px-to-rem(30);
    padding-bottom: px-to-rem(20);

    h3 {
      font-size: px-to-rem(34);
      line-height: 40px;
      color: $black-charcoal;
    }

    a {
      padding: px-to-rem(14) px-to-rem(24) px-to-rem(12);
      background: $palette-pink-500;
      border-radius: 5px;
      color: $white;
      font-weight: 400;
      font-size: px-to-rem(20);
      line-height: 23px;
    }
  }

  @media only screen and (max-width: 600px) {
    #main-content {
      .container {
        padding: 0 px-to-rem(30);
      }
    }
    .block {
      flex-direction: column;
      .block__icon--1 img:nth-child(1){
        left: 0;
        right: 55%;
      }
      .block__aside {
        width: 100%;
        justify-content: center;
      }
    }

    .info-block__content {
      max-width: 100%;
    }

    .improve-bp {
      grid-template-columns: 1fr;

      .improve-bp__img {
        margin-bottom: px-to-rem(30);
      }

      .improve-bp__content-btn {
        text-align: center;
      }
    }

    .bp-access {
      .bp-access__row {
        grid-template-columns: 1fr;
        padding:0;
      }
    }

    .access-code {
      .access-code__row {
        grid-template-columns: 1fr;
        gap: px-to-rem(30);
      }
    }

    .breadcrumb {
      padding-top: 0;
      display: inline;
      span:nth-child(2){
        margin-left: px-to-rem(5);
        margin-right: px-to-rem(5);
      }
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    .improve-bp,
    .access-code .access-code__row {
      grid-template-columns: 35% 65%;
    }
    .block__icon--1 img:nth-child(1) {
      left: -25%;
    }
  }
}