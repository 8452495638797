.quick-links {
  h3 {
    font-size: 1.25rem;
    color: $palette-pink-500;
  }
  .quick-links-img {
    background-image: url("https://bestpractice.bmj.com/api/image/3000168/en-gb/normal/3000168-1.jpg"); /* The image used */
    background-color: #cccccc;
    height: 140px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
