.search-bar-wrapper{
  width: 100%;
  position: relative;
  @media only screen and (min-width: 991px) {
    display: flex;
  }
  input.search-bar {
    border: 1px solid $palette-blue-500;
    background-color: $palette-grey-050;
    border-radius: 4px;
    display: block;
    width: 100%;
    height: 48px;
    box-shadow: 0px 1px 6.9px 0.1px rgba(0, 0, 0, 0.16);
    padding-left: 1rem;
    @media only screen and (min-width: 991px) {
      width: 50%;
    }
  }
  button {
    height: 48px;
    width: 50px;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 1.25rem;
    background-color: transparent;
    border: none 0;
    margin: 0;
    @media only screen and (min-width: 991px) {
      position: relative;
      top: 0;
      right: 50px;
    }
  }
}

#searchGuidelinesBtn, #searchCalculatorsBtn  {
  background-color: transparent;
  border: none;
  margin: 0;
  position: absolute;
  right: 0;
  height: 3rem;
}

#myInput {
  &::-ms-clear{
    margin-right: 2.5rem;
  }
}
.case-reports {
  #q {
    &::-ms-clear{
      margin-right: 2.5rem;
    }
  }
}