.login-wrapper {
  @media only screen and (max-width: 991.5px) {
    flex-direction: column;
  }

  display: flex;
  flex-direction: row;

  @media only screen and (min-width: 991.5px) {
    column-gap: px-to-rem(95);
  }
}

.bs-col {
  @media only screen and (max-width: 991.5px) {
    min-width: fit-content;
  }

  width: 50%;
  max-width: fit-content;
}

.institution-title,
.altLogin-grouping-nhs {
  margin-top: px-to-rem(34);
}
.institution-title.country-gb,
.institution-title.country-us {
  margin-top: px-to-rem(22);
}

.bs-institution-login {
  background-color: $palette-blue-500;
  border-radius: px-to-rem(6);
  color: $white;
  cursor: pointer;
  display: inline-block;
  margin-top: px-to-rem(24);
  margin-bottom: px-to-rem(30);
  padding: px-to-rem(12) px-to-rem(16);
  line-height: px-to-rem(22);

  &:hover, &:focus {
    color: $white;
  }
}

.login-form {
  .x-btn{
    &:focus {
      color: white;
    }
  }
  color: $black-charcoal;
  padding: 0 px-to-rem(15);

  &__title {
    font-size:px-to-rem(30);
    font-weight: 400;
    margin-bottom: 0;
  }

  &__intro {
    font-weight: 400;
    margin-top: px-to-rem(46);
    margin-bottom: 60px;
  }

  &__input {
    padding-left: px-to-rem(9) !important;
  }

  &__error {
    color: $palette-red-500;
    font-weight: 700;
    margin-top: px-to-rem(31);
    margin-bottom: px-to-rem(19);
    padding-left: px-to-rem(7);
    display: flex;

    img {
      padding-right: px-to-rem(7);
      align-self: baseline;
    }

    p {
      display: inline;
      margin-bottom: unset;
    }
  }


  label {
    color: $black-charcoal !important;
  }

  &__btn {
    margin-top: px-to-rem(20);
    margin-bottom: px-to-rem(57);
    padding: px-to-rem(12) px-to-rem(16);
  }
}

.altLogin {
  background-color: $palette-grey-light;
  border-radius: px-to-rem(6);
  padding: px-to-rem(25) px-to-rem(20);
  margin: px-to-rem(44) 0 px-to-rem(44) 0;

  h2 {
    color: $black-charcoal;
    font-size: px-to-rem(20);
    font-weight: 700;
    line-height: px-to-rem(23);
    margin-bottom: px-to-rem(23);
  }

  ul {
    margin-bottom: px-to-rem(12);
  }

  .institution-login {
    border-radius: px-to-rem(6);
    margin-top: px-to-rem(37);
    padding: px-to-rem(6) px-to-rem(12);
    width: fit-content;
    span {
      text-transform: uppercase;
      display: block;
    }
  }
}

.subscription-bp {
  h2 {
    color: $black-charcoal;
    font-size: px-to-rem(20);
    line-height: px-to-rem(23);
    margin-bottom: px-to-rem(23);
  }

  &__image {
    padding-top: px-to-rem(46);
    width: 100%;
    height: auto;
    object-fit: cover;
    @media only screen and (max-width: 992px) {
      width: px-to-rem(300);
      margin-bottom: 2rem;
    }
    @media only screen and (min-width: 992px) {
      display: none;
    }
  }
}

// FORM STYLING
.has-danger {
  border: px-to-rem(2) solid $palette-red-500 !important;
}

.floating-label  {
  flex-direction: column-reverse;
  border: px-to-rem(1) solid $black-charcoal;
}

#loginForm {
  margin-top: 1.5rem;
}

.form-group.floating-label.has-danger.last-input {
  margin-bottom: 0 !important;
}

.password-visible {
  float: right;
  position: relative;
  top: px-to-rem(20);
  height: px-to-rem(20);
  right: px-to-rem(50);
  cursor: pointer;
}

.open-eye-icon {
  background-image: url("/images/eye-open.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  float: right;
  position: relative;
  top: px-to-rem(2);
  height: px-to-rem(10);
  cursor: pointer;
  width: px-to-rem(50);
}

.closed-eye-icon {
  background-image: url("/images/eye-closed.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  float: right;
  position: relative;
  height: px-to-rem(14);
  cursor: pointer;
  width: px-to-rem(50);
}

.toggle-password {
  margin-top: px-to-rem(-50);
  border: none;
  padding: 0;
  background-color: Transparent;
  background-repeat: no-repeat;
}

#visibility-hidden {
  visibility: hidden;
}

.login-form-inputs {
  .form-group {
    margin-bottom: px-to-rem(20);
    margin-left: 0;
  }
  .form-control-feedback {
    font-weight: 700;
    margin-bottom: px-to-rem(13);
  }

  //form styling states
  .form-group.floating-label {
    &:active {
      box-shadow: map-get($card-elevation-shadow, shadow);
    }
  }

  .form-control-feedback {
    position: relative;
    top: px-to-rem(-8);
    display: inline;

    span {
      font-size: px-to-rem(16);
      position: absolute;
      top: px-to-rem(-2);

    }
  }
}

.login-form-inputs {
  //hide IE default password toggle
  @include ie-only() {
    input::-ms-reveal,
    input::-ms-clear {
      display: none;
    }
  }
  .form-group {
    display: flex;
  }

  .error-icon {
    background-image: url("/images/error.svg");
    background-position: left;
    background-repeat: no-repeat;
    background-size: contain;
    height: px-to-rem(16);
    width: px-to-rem(16);
    display: inline-block;
    margin-left: px-to-rem(7);
    margin-right: px-to-rem(7);
  }

}

@include media-breakpoint-up(lg){
  .altLogin {
    margin: revert;
    margin-bottom: auto;
    margin-top: px-to-rem(49);

    &__image {
      float: right;
    }
  }

  .login-form {
    &__title {
      font-size: px-to-rem(40);
      margin-top: px-to-rem(66) !important;
    }
  }

  .floating-label {
    border: px-to-rem(1) solid $palette-bmj-grey-100;
  }

  .bt-black {
    border-top: px-to-rem(1) solid $black;
  }

  .subscription-bp {
    margin-top: px-to-rem(20);
    margin-bottom: px-to-rem(157);
    padding-top: px-to-rem(23);

    h2 {
      margin-bottom: px-to-rem(25);
    }

    .bs-col:last-of-type {
      margin-left: auto;
    }


    &__image--desktop {
      margin-top: 2rem;
      margin-bottom: 4rem;
      width: px-to-rem(350);
    }
  }
}

.bs-registration-form {
  @media only screen and (max-width: 992px) {
    padding: 1rem;
  }
    .bp-form__visibility-btn {
    position: absolute;
    right: 9px;
    z-index: 1;
    background: no-repeat;
    border: none;
  }

  .custom-control {
    margin-right: unset;
  }
}

@media only screen and (max-width: 991.5px) {
  .subscription-bp__image--desktop {
    display: none;
  }
}


//--------------------
// IP registration page /registration
//--------------------

.bs-registration-form__title {
  display: inline-block;
  margin-top: px-to-rem(72) !important;
  margin-bottom: px-to-rem(50) !important;
}

.bs-registration-form__pw-regs {
  font-size: px-to-rem(14);
  margin: px-to-rem(28) 0;
}

.registration-info {
  background-color: $palette-grey-light;
  border-bottom-left-radius: px-to-rem(6);
  border-bottom-right-radius: px-to-rem(6);
}

.registration-info__img {
  width: 100%;
  height: auto;
}

.registration-info__text {
  padding: px-to-rem(30) px-to-rem(25);
}

.registration-info__text h2 {
  color: $black-charcoal;
  margin-bottom: px-to-rem(38);
  font-size: px-to-rem(20);
  font-weight: 700;
}

.registration-info__list {
  list-style-type: none;
  padding: 0;

  li {
    background-image: url("/images/check-mark.svg");
    background-repeat: no-repeat;
    background-position: left top;
    margin-bottom: px-to-rem(24);
    padding-left: px-to-rem(37);
  }
}

.registration-wrapper {
  @media only screen and (max-width: 991.5px) {
    flex-direction: column;
  }

  display: flex;
  flex-direction: row;

  @media only screen and (min-width: 991.5px){
    column-gap: px-to-rem(60);
  }
}

@media only screen and (max-width: 991.5px) {
  .registration-wrapper .bs-col:first-of-type {
    order: 1;
  }
}

//FORM

.registration-form-header {
  font-size: px-to-rem(20);
  font-family: $headings-font-family;
  font-weight: 700;
  line-height: px-to-rem(28);
  padding-left: 0 !important;
  margin-bottom: 0;
}

.bp-form__label {
  color: $black-charcoal;
  display: flex;
  font-size: 1rem;
  margin-left: px-to-rem(10);
  padding: px-to-rem(15) 0;
  position: absolute;
  z-index: 1;
}

.bp-form__input {
  border: px-to-rem(1) solid $palette-bmj-grey-100;
  border-radius: px-to-rem(3);
  font-size: 1rem;
  padding: px-to-rem(22) px-to-rem(5) px-to-rem(3) px-to-rem(10);
  margin-bottom: px-to-rem(18);
  width: -webkit-fill-available;
  width: -moz-available;

  &:hover {
    box-shadow: 0 px-to-rem(2) 0 $palette-bmj-grey-100;
  }
  &::-ms-reveal {
    display: none;
  }
}

.bp-form__input-select {
  color: $black-charcoal;
  background-color: white;
  font-size: 1rem;
  padding: px-to-rem(14) 0 px-to-rem(13) px-to-rem(11);
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L6.20548 6L11.411 1' stroke='%23333333'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right px-to-rem(20) center;
  height: px-to-rem(50);
}

.bp-form__pw-text {
  color: $palette-grey-a500;
  font-size: px-to-rem(14);
  line-height: px-to-rem(22);
  text-align: initial;
  margin-top: px-to-rem(-3);
  margin-bottom: px-to-rem(20);
}

.form-field {
  position: relative;

  .bp-form__visibility-btn {
    background: none;
    border: none;
    cursor: pointer;
    position: absolute;
    right: px-to-rem(11);
  }
}

.bp-form__btn {
  background-color: $palette-blue-500;
  color: $white;
  cursor: pointer;
  border: none;
  border-radius: px-to-rem(6);
  padding: px-to-rem(12) 1rem;
  margin: px-to-rem(24) auto px-to-rem(10);
}

.bp-form__link {
  background-color: $palette-blue-500;
  display: inline-block;
  margin-top: px-to-rem(17);
  margin-bottom: px-to-rem(29);
  color: $white !important;
  border: none;
  border-radius: px-to-rem(6);
  padding: px-to-rem(12) 1rem;

  &:hover {
    color: $white !important;
  }
}

.checkbox-wrapper {
  display: flex;
  column-gap: 1rem;
  margin-bottom: px-to-rem(11);

  [type=checkbox] {
    height: px-to-rem(20);
    flex: 0 0 px-to-rem(20);
  }

  label {
    font-size: px-to-rem(14);
    line-height: px-to-rem(22);
    text-align: left;

    a {
      text-decoration: underline;
    }
  }
}

.registration__group .bp-form__input {
  margin-bottom: 0.625rem;
}

.registration-return {
  color: $palette-blue-500;
  cursor: pointer;
  display: block;
  &:hover {
    color: $palette-pink-500;
  }
}

//--------------------
// Validation dom classes
//--------------------

.form-registration-error {
  display: none;
}

.error-input {
  border: px-to-rem(2) solid $palette-red-500 !important;
}

.disabled-form {
  pointer-events: none;
  opacity: 0.5;
}

.form-field {
  small {
    color: $palette-red-500;
    font-weight: 700;
    font-size: 1rem;
    display: none;
    margin-bottom: px-to-rem(10);

    &.error-icon {
      background-image: url("/images/error-icon.svg");
      background-repeat: no-repeat;
      display: inline-block;
      text-align: left;
      padding-left: px-to-rem(25);
      margin-left: px-to-rem(11);
    }
  }

  label.focus {
    font-size: px-to-rem(12);
    transition: 0.1s ease all;
  }

  label:has(+ input:-webkit-autofill) {
    font-size: px-to-rem(10);
    margin-top: -0.5rem;
  }

  .focus {
    margin-top: px-to-rem(-8);

    input {
      font-size: 3rem;
    }
  }
}

//--------------------
//  Registration form
//--------------------

.registration-wrapper {
  .bp-form__input {
    margin-bottom: 0;
  }

  .form-field {
    margin-top: px-to-rem(20);
  }

  .form-field .bp-form__visibility-btn {
    bottom: px-to-rem(11);
  }

  .bp-form__btn--reg {
    background-color: $palette-pink-500;
    border: none;
    border-radius: px-to-rem(6);
    color: $white;
    cursor: pointer;
    font-size: 1rem;
    padding: px-to-rem(12) px-to-rem(16);
    margin-top: px-to-rem(20);
    margin-bottom: px-to-rem(36);
  }

  #alreadyRegistered {
    margin-top: px-to-rem(15);
  }
}

.user-login {
  @media only screen and (max-width: 991.5px) {
    font-size: 1rem !important;
    font-weight: 400;
    margin-bottom: px-to-rem(66);
  }

  .bp-form__link {
    margin-bottom: unset;
  }

  display: block;
  border-top: 1px solid $black-charcoal;
}

.visibility-hidden {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.custom-reg-checkbox {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: px-to-rem(20);
  height: px-to-rem(20);
  cursor: pointer;
}

.custom-reg-checkbox[type="checkbox"]:checked + .custom-reg-checkbox__img::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: px-to-rem(20);
  height: px-to-rem(20);
  background-image: url('data:image/svg+xml;base64,77u/PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjxzdmcgdmVyc2lvbj0iMS4xIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgd2lkdGg9IjIwcHgiIGhlaWdodD0iMjBweCIgdmlld0JveD0iODM1IDc2MSAgMjAgMjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQogIDxwYXRoIGQ9Ik0gODM1LjUgNzYxLjUgIEwgODU0LjUgNzYxLjUgIEwgODU0LjUgNzgwLjUgIEwgODM1LjUgNzgwLjUgIEwgODM1LjUgNzYxLjUgIFogIiBmaWxsLXJ1bGU9Im5vbnplcm8iIGZpbGw9IiNmZmZmZmYiIHN0cm9rZT0ibm9uZSIgLz4NCiAgPHBhdGggZD0iTSA4MzUuNSA3NjEuNSAgTCA4NTQuNSA3NjEuNSAgTCA4NTQuNSA3ODAuNSAgTCA4MzUuNSA3ODAuNSAgTCA4MzUuNSA3NjEuNSAgWiAiIHN0cm9rZS13aWR0aD0iMSIgc3Ryb2tlPSIjYzUwMDg0IiBmaWxsPSJub25lIiAvPg0KICA8cGF0aCBkPSJNIDgzOS4yODU3MTQyODU3MTQgNzcxICBMIDg0My41NzE0Mjg1NzE0MjkgNzc1LjI4NTcxNDI4NTcxNCAgTCA4NTAuNzE0Mjg1NzE0Mjg2IDc2NS4yODU3MTQyODU3MTQgICIgc3Ryb2tlLXdpZHRoPSI0LjI4NTcxNDI4NTcxNDI5IiBzdHJva2U9IiNjNTAwODQiIGZpbGw9Im5vbmUiIC8+DQo8L3N2Zz4=');
  background-size: cover;
  pointer-events: none;
}

.label-spacing {
  cursor: pointer;
  font-size: .875rem;
  padding-left: 2.25rem;
  position: relative;
}

//--------------------
//  Future techdebt refactor using stripe journey
//--------------------

.inst-login {
  display: flex;
  align-items: flex-start;
  gap: 5rem;

  .form-col{
    flex: 1;
    .user-login {
      margin-top: 2.25rem;
    }
  }
  .img-col {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;

    img {
        width: 100%;
        max-width: 100%;
        height: px-to-rem(300);
    }
  }
  h1 {
    margin-bottom: 5.0625rem;
  }
}

@media only screen and (max-width: 991.5px) {
  .inst-login {
    flex-direction: column;
    gap: initial;
  }

  .form-col {
    flex: initial;
  }
}

.inst-login-form {
  .login-form__btn {
    text-transform: uppercase;
    margin: 0.5rem auto 1.875rem;
    border: none;
    border-radius: 0.375rem;
    padding: 0.75rem 1rem;
  }
}

.access-code-login {
  margin-bottom: 3rem;
}

#registrationRequestForm {
  .bs-registration-form__pw-regs {
    margin-bottom: 0;
    margin-top: 0.5rem;
    color: $black-charcoal;
  }
  .form-field {
    .bp-form__label {
      color: $palette-grey-600;
    }
    .label-spacing {
      color: $black-charcoal;
    }
  }
}