#header {
  //--------------------
  // #mainSearch to be replaced with new search component
  //--------------------
  #mainSearch {
    height: 70px;

    #searchBoxWrap {
      border: 1px solid $palette-blue-500;
      background-color: #fafafa;
      border-radius: 4px;
      width: 100%;
      margin: 0 auto;
      height: 48px;
      box-shadow: 0px 1px 6.9px 0.1px rgba(0, 0, 0, 0.16);
      position: absolute;

      .tt-menu .tt-dataset .tt-cursor,
      .tt-menu .tt-dataset .tt-suggestion:hover,
      .tt-menu .tt-dataset .tt-suggestion a:hover {
        background-color: #ccc !important;
      }

      .twitter-typeahead {
        width: 100% !important;
      }

      .tt-menu {
        border-left: 1px solid $palette-blue-500;
        border-right: 1px solid $palette-blue-500;
        border-bottom: 1px solid $palette-blue-500;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        margin-top: -5px;
        width: 100%; //fallback
        width: calc(100% + 2px) !important;//works for all modern browsers
        margin-left: -1px;

        .card {
          background-color: #fafafa !important;
          box-shadow: none;
          border-radius: 0;
        }
        & div.tt-dataset .card:last-child,
        & div.tt-dataset .card:last-child .dropdown-item {
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
        }

        .dropdown-item {
          font-size: 0.9rem;
          white-space: normal !important;
        }
      }

      .tt-suggestion .tt-highlight {
        color: $palette-pink-500 !important;
      }

      .tt-open:before {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        display: inline-block;
        margin: 0;
        padding: 0.8rem 1rem;
        background-color: #fafafa;
        font-size: 0.8rem;
        line-height: 1; //1.42857
      }

      input,
      button {
        background-color: transparent;
        border: none 0;
        margin: 0;
      }

      input {
        width:100%;
        height: 48px;
        font-size: 1rem;
        color: $black !important;
        padding-left: 1rem;
        padding-right: 56px;
        opacity: 0.7;

        &:focus {
          opacity: 0.7;
          outline-width: 0;
        }
        ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
          color: $palette-grey-600; ;
        }
        ::-moz-placeholder { /* Mozilla Firefox 19+ */
          color: $palette-grey-600; ;
          opacity:  1;
        }
        ::-ms-input-placeholder { /* Microsoft Edge */
          color: $palette-grey-600; ;
        }
        ::placeholder {
          color: $palette-grey-600;
        }
      }

      button {
        height: 48px;
        width: 50px;
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 0;
        font-size: 120%;

        .material-icons {
          line-height: normal;
        }
      }

      .error {
        color: $palette-red-500;
        left: 0;
        top: 48px;
        position: absolute;
        width: 100%;
      }
    }

    &.bare-header {
      min-height: 126px;
      margin-top: 35px;
    }
  }

  // China Logo Image White
  .logo-img-china {
    @media only screen and (max-width: 991.5px) {
      display: none;
      img {
        width: 350px;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .logo-img {
    display: none !important;
  }
}


@media only screen and (max-width: 991.5px){
  #header {
    display: flex;
    flex-direction: column;
  }

  #mainSearch {
    order: 1;
  }
}

@media only screen and (max-width: 576px) {
  #mainSearch {
    margin-top: 0.6875rem;
  }
}

#header .logo-img {
  height: 57px;
  width: 334px;
  display: block;
  float: left;
  margin: 0;
  background-repeat: no-repeat;
  background-position: top center;
  background-image: url('/images/BP-Logo-colour.svg');
  background-size: contain;
  
}

#diffCommonContainer, #diffConditionTable, #diffUnCommonContainer {
  button {
    border: none;
    color: $palette-blue-500;
    background-color: unset;

    & span:first-child:hover {
      text-decoration: underline;
    }
  }
}


.paywall-header__menu {
  @media only screen and (max-width: 991.5px) {
    .mobile-header {
      display: none;
    }
  }
}