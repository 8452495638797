.topic {
    .allUpdates { // media queries below
        // Disable links for now (content issue)
        min-height: 52px;
        a:not(.btn-updates) {
           pointer-events: none;
           color: $black-primary;
        }
        // Important Updates Button
        .btn-updates {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            background-color: $white;
            border: 2px solid $palette-pink-500;
            color: $palette-pink-500;
            padding: .65rem .5rem .55rem .5rem;
            white-space: normal;
            z-index: 0;//stay behind slidedown topic nav!
            &:hover {
                background-color: $palette-pink-500;
                border: 2px solid $white;
                color: $white !important;
                span.material-icons {
                    color: $white !important;
                }
            }

            .icon-bp-icons-important-updates {
                display: flex;
            }

            .icon-bp-icons-important-updates:before {
                font-size: 1.25rem;
                top: -1px;
                position: relative;
            }
        }
        .btn::before {//override for :focus bg-color.
            background-color: inherit;
            color: inherit;
        }
    }
    .updatesContent {
        .card {
            border: 2px solid #c50084 !important;
        }
        .card-block {
            padding: 0 !important;
        }
        .close {
            color: rgba(0,0,0,0.5);
            position: relative;
            top: -7px;
            right: -12px;
            &:hover {
                color: $palette-pink-500;
            }
        }
        #impUpdate {
            .card-block {
                padding: 1rem !important;
            }
            ul.w-75, p.w-75 {
                width: 100% !important;
            }
            h3 {
                float: none !important;
                line-height: 1.25;
                margin: 1rem 0;
            }
            a:hover {
                color: $palette-pink-500;
            }
            a.upSource {
                &:hover {
                    color: $palette-pink-500;
                }
                &:after {
                    content: "\43";
                    font-family: 'bmj-icons';
                    font-size: 1.75rem;
                    position: relative;
                    top: 8px;
                    margin-left: -3px;
                }

            }

        }
    }
    .serp_detail {
        margin-top: 0; // for RHS. Remove when srp layout tidied up

        .list-group-item > div:not(.row),
        .list-group-item h4 {
            display: inline-block;
            width: 100%;
        }
    }
    .expandable {
        p.w-75 {
            width: 100% !important;
        }
        p + ul {
            margin-bottom: .5rem !important;
        }
        h3 + ul {
            margin-top: .5rem !important;
            margin-bottom: .5rem !important;
        }
        .ellipsis {
            &:after {
                content: '...';
                margin-left: -4px;
            }
        }
    }

    .expandable > p + ul,
    .expandable > p + img {
        display: none;
    }

    .expandable > p:first-child,
    .expandable > ul:first-child,
    .expandable > img:first-child {
        display: block;
    }

    &.tmp .card-block .expandable ul {//these styles are required to render the nested - within LIs - figcaptions properly. UL and LI 100% width!
        text-indent: 0 !important;
        width: 100% !important;
        display: block;

        li {width: 100%;
            &:before {
                padding-left: 0;
                padding-right: 0.3rem;
            }
        }
    }
    span {
        &.read-more__link {
            color: $palette-pink-500;
            cursor: pointer;
            display: block;
            margin: -0.625rem 0 1rem 0;
            &:after {
                font-family: 'Material Icons';
                content: "\E5CF";
                position: relative;
                top: 3px;
            }
        }
        &.read-less__link {
            display: none;
        }
    }
    .card {
        border-radius: 0;
        border-bottom: 1px solid $palette-grey-300;
        .card-header {
            padding: .55rem 1rem .125rem 1rem !important;
            a {
                color: $black-primary;
            }
        }
        .card-block {
            padding: 1.4rem;

            .red-flag {
                margin-left: -1.4rem;
                &:before {
                    color: #cd202c;
                    content: "\4c";
                    font-family: 'bmj-icons';
                    font-size: 1.5rem;
                    line-height: 1;
                    padding-left: 0;
                    padding-right: 0;
                    position: relative;
                    top: -2px;
                }
            }

            &.lastUpdate{
                p {
                    color: $palette-pink-500;
                    span {
                        color: $black-primary;
                    }
                }
                p + p {
                    padding: 0;
                }
            }
            &.cb-inner {
                padding-top: 0;padding-left: 2.25rem;
                img {
                    padding-bottom: 1rem;
                }
            }

            &.cb-inner p {margin-bottom: 0.5rem; }

        }
    }

    .card-group {
        .card {
            box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 1px 1px 1px 1px rgba(224, 224, 224, 1), 0 3px 1px -2px rgba(0,0,0,.2); // change rhs shadow
        }
    }
    .no-shadow {
        .card {
            box-shadow: 0 0px 2px 0 rgba(0, 0, 0, 0.14), 0px 0px 0px 0px #e0e0e0, 0 0px 0px 0px rgba(0, 0, 0,  0.2);
            border-bottom: none;
        }
    }
    .card-primary { // H1 topic title & View All
        border-bottom: none;
        box-shadow: none;
        height: 60px;

        .card-block {
            padding-top: 6px;
        }
    }
    .card-inverse {
        background-color: $palette-blue-040;
        border-bottom: 1px solid  $palette-blue-grey-200;
        border-top: 2px solid $palette-blue-800;
        border-radius: 0;
        color: $palette-grey-800;
            .card-block {
                padding: 0.7rem 1rem 0.7rem 2rem;
                h4 {
                    line-height:1;
                    &:hover {
                        cursor: pointer;
                        a {
                            text-decoration: underline;
                        }
                    }
                    a {
                        float: right;
                        margin-right: 1rem;
                        color: $palette-pink-500;

                        &:hover {
                            text-decoration: underline;
                            color:$palette-pink-500 !important;
                        }

                    }
                }
            }
        #accordion {
            .card-block {
                padding: 0 0 1rem 2.25rem;
                p {
                    padding-top: 0;
                }
            }
        }
    }//.card-inverse ends

    .treatment-table {//.topic pgs
        .panel-heading {
            border-bottom: none;
            h3 a {
                //color: $palette-blue-500 !important;
                padding: 0.7rem 1rem 0.7rem 1.5rem;
            }
            span {
                color: $palette-grey-a600 !important;
                padding: 0.7rem 1rem 0.7rem 1.5rem;
				display: block;
            }			
        }
        .rowHead > div {
            padding-left: 1.5rem;
        }
    }
    .details {
        ul {
            li {
            padding-left: 1rem;
            text-indent: -2rem;
            }
        }
        ul li p {
            display: inline;
        }
    }

}

// Cards with Arrow for Last reviewed, Last updated, Important updates
.arrow-card {
    position: relative;
    top: 2rem;
    border: 1px solid $palette-pink-500;; /*set border colour here*/
    border-radius: 2px;
    padding: 1rem;
    margin-bottom: 3rem;
    &:after, &:before {
        bottom: 100%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }
    &:after {
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: #ffffff;
        border-width: 19px;
        left: 40%;
        margin-left: -19px;
    }
    &:before {
        border-color: rgba(113, 158, 206, 0);
        border-bottom-color: $palette-pink-500;;
        border-width: 22px;
        left: 40%;
        margin-left: -22px;
    }
    &.acLeft {
        &:after, &:before {
            left: 10%;
        }
    }
    &.acRight {
        &:after, &:before {
            left: 85%;
        }
    }
}

// Mobile Last reviewed, Last updated, Important updates
@media (max-width: 767px) {
    .topic .card .card-block.cb-inner img {
        float: none;
    }
    .topic .arrow-card .card-block ul {
        font-size: .85rem !important;
    }
    .topic .card .card-block ul li:before {
        padding-left: 1.5rem;
    }
    .arrow-card, .guidelines-arrow-card {
        &:before, &:after {
            display: none;
        }
    }
    .topic .allUpdates .btn-updates {
        margin: 8px 0;
    }
}

@media (min-width: 769px) and (max-width: 1199px) {
    .allUpdates {
        span {
            display: block;
        }
        .btn-updates {
            font-size: $font-size-body-2;
            padding: .5rem !important;
        }
        .material-icons {
            display: none;
        }
    }
}
.figureWrap {
    img {
        width: 100%;
    }
}

.ls-modal {
    @media only screen and (max-width: 570px) {
        margin-left: 1rem;
        margin-right: 1rem;
        padding: px-to-rem(47) px-to-rem(26) 0 px-to-rem(26);
        max-width: calc(100% - 30px);
    }

    border: none;
    border-radius: px-to-rem(6);
    padding: px-to-rem(84) px-to-rem(60) 0 px-to-rem(60);
    z-index: 5;
    max-width: px-to-rem(543);

    p {
        color: $black-charcoal;
        font-size: 1rem;
        line-height: px-to-rem(24);
        text-align: center;
        margin-bottom: px-to-rem(26);
    }

    &::backdrop {
        background-color: rgba(0, 0, 0, 0.5);
    }
}

.ls-modal__wrapper {
    display: flex;
    justify-content: center;
    padding-bottom: px-to-rem(62);
    gap: px-to-rem(25);

    .ls-btn {
        border: none;
        border-radius: px-to-rem(5);
        cursor: pointer;
        padding: px-to-rem(6) px-to-rem(12) px-to-rem(6) px-to-rem(12);

        &.primary {
            background-color: $palette-pink-500;
            color: $white;
        }

        &.secondary {
            background-color: #BFC0C1;
            &:hover {
                color: inherit;
            }
        }
    }
}