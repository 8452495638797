@import 'mixins';

// Inline videos in Topic pages (paras)
.inlineVid {
  display: block;
  padding: 1rem 0 0 1rem;
  a {
    color: $black-primary;
    font-weight: bold;
  }
  img {
    float: none !important;
  }
}
hr.fw {
  margin-right: -1.563rem; //25px
  margin-left: -1.563rem;
}
@media (max-width: 767px) {
  .inlineVid { // mobile
    a {
      display: flex;
    }
  }
  hr.fw {
    margin-right: -1rem;
    margin-left: -1rem;
  }
}
// Inline videos in Topic pages (ul li p)
ul li hr.fw {
  margin-right: -1.563rem; //25px
  margin-left: -1.875rem;
}
@media (max-width: 767px) {
  ul li hr.fw {
    margin-right: -0.75rem;
    margin-left: -1.313rem;
  }
}
// Inline videos in Topic Tables
.rowDetails {
  hr.fw {
    display: none;
  }
  .inlineVid {
    margin-bottom: 1rem !important;
    img {
      display: inline;
    }
  }
}
@media (max-width: 767px) {
  .inlineVid img {
    width: auto;
  }
}

// Multimedia (Serp)
.imgsVids {
  ul {
    li {
      &:before { // remove pink bullet
        content: none !important;
      }
      &.list-group-item {
        font-size: 1rem;

        @media (max-width: 360px) {
          padding-left: 0.75rem;
          padding-right: 0.75rem;
        }

        a {
          display: inline;
          word-break: break-word;
        }
      }
    }
  }

  .list-group-item_title_summary:has(.video-summary_text) .list-group-item_text {
    font-weight: 700;
  }

  @media (min-width: 500px){
    .list-group_inner {
      display: flex;
    }
  }

}

#video-page {
  ul {
    list-style-type: none;
    li {
      &:before {
        color: #c50084;
        content: "\2022";
        margin-left: -1.275rem;
        padding-right: .5rem;
        vertical-align: top;
      }
      p {
        display: inline;
      }
    }
  }
}
// ADDED for videos on small screens - controls went off screen
@media (max-width: 360px) {
    #video-page {
      .card-block {
        padding:0 0 1.5rem 0!important;
      }
      h2, h3, h4, h5, p{
        padding:1rem 1rem;
      }
    }
  }
// Video List mobile
@media (max-width: 767px) {
  .imgsVids {
    ul {
      li.list-group-item {
        a {
          display: inline-flex;
        }
      }
    }
  }
}

// Google Images type image viewer

.gridder{
    margin: 0px;
    padding: 0px;
    list-style-type: none;
}

.gridder {
    img.img-fluid {
      width: 165px;
      height: 124px;
    }
    .imgLge {
      width: 600px !important;
      height: auto !important;
      border: none;
      padding: 0;
    }
}

.gridder-list {
    display: inline-block;
    vertical-align: top;
}
.gridder-show {
    display: block;
    float: left;
    width: 100%;
    position: relative;
    background: #EEE url('/images/loading-spin.svg') no-repeat center;

    &.loading{
        background: #EEE url('/images/loading-spin.svg') no-repeat center;
    }
}
.gridder-content{
    display: none;
}

.gridder-list{
    @include rowMachine(6,1%);
}

/* */
.gridder-show{
    padding: 20px;
    background: #EEE;
    margin-bottom: 1%;
}

.gridder-navigation{
    .gridder-nav{
        &.disabled{
            opacity: .5;
        }
    }
}


.gridder-list{
    cursor: pointer;
    &:hover{
        opacity: .8
    }
}

.hasSelectedItem{
    .gridder-list{
        opacity: .5;
        &.selectedItem{
            opacity: 1;
        }
    }
}

.gridder {
    margin: 0px;
    padding: 0px;
    list-style-type: none;
    font-size: 0;
    .gridder-list {
      display: inline-block;
      vertical-align: top;
      cursor: pointer;
      position: relative;
      width: 173px;
      margin-bottom: 0.5%;
      margin-right: 0.5%;
      .image {
        background-color: #313131;
        background-size: cover;
        background-position: center;
        width: 350px;
        height: 250px;
        position: relative;
      }
    }
    .overlay {
      position: absolute;
      left: 10%;
      top: 10%;
      color: #FFF;
      line-height: 130%;
      text-align: left;
      .title {
        font-weight: 800;
        font-size: 16px;
        color: #FFF;
      }
      .description {
        font-weight: 300;
        font-size: 14px;
        color: #FFF;
      }
    }
    .gridder-list, .gridder-show {
      font-size: 1rem;
    }
    .selectedItem:after {
      content: ' ';
      display: block;
      border-bottom: 40px solid #404040;
      border-left: 40px solid transparent;
      border-right: 40px solid transparent;
      height: 0%;
      position: absolute;
      top: 99%;
      width: 0;
      left: 43%;
      margin-bottom: -4px;
    }
    &.hasSelectedItem .gridder-list:not(.selectedItem) {
      opacity: 1;
    }
  }

  .gridder-show {
    padding: 4%;
    background: #404040;
    position: relative;
    display: block;
    float: left;
    width: 100%;
    margin-bottom: 0.5%;
    text-align: left;
    color: rgba(255, 255, 255, 0.87);
    img {
      width: 100%;
      box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.2);
      width: 600px !important;
      height: auto !important;
    }
  }

  .gridder-content {
    display: none;
  }

  .gridder-navigation {
    .gridder-nav {
      height: 77px;
      padding: 0;
      position: absolute;
      width: 32px;
      z-index: 0;
      background-color: #454545;
      background-image: linear-gradient(top, #3e3e3e, #333);
      border: 1px solid #141414;
      border-radius: 2px;
      box-shadow: 0 1px 0 rgba(255, 255, 255, 0.06), 1px 1px 0 rgba(255, 255, 255, 0.03), -1px -1px 0 rgba(0, 0, 0, 0.02), inset 1px 1px 0 rgba(255, 255, 255, 0.05);
      color: #aaa !important;
      cursor: pointer !important;
      display: inline-block;
      filter: progid:DXImageTransform.Microsoft.gradient(startColorStr='#303030',EndColorStr='#262626');
      font-size: 11px;
      font-weight: bold;
      margin: 0 5px;
      text-align: center;
      text-decoration: none !important;
      text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.5);
      -webkit-user-select: none;
      user-select: none;
      vertical-align: middle;
      white-space: normal;
      word-wrap: normal;
      top: 50%;
      margin-top: -40px;
      overflow: hidden;
      span {
        display: block;
        bottom: 0;
        height: 23px;
        left: 0;
        margin: auto;
        position: absolute;
        right: 0;
        top: 0;
        width: 14px;
      }
      &.prev {
        left: -6px;
        z-index: 0;
        span {
          background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAXBAMAAADJv2OxAAAAMFBMVEX///+ZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZnHHh8xAAAAD3RSTlMAESIzRFVmd4iZqrvM3e5GKvWZAAAAdElEQVQI12NgAAH2v2CKsf83mJb8vxBEMe3/KQCitf83gLn3n4Flff8FgCjW90/A3Pw/BiBK4v0lMHf+bwUw/R/CZVj/SwBq5kaIQD1UAfv7yxCBuH8OYJrl/VWIgM3/AjDNdP45REDq/0SI+9b/gghw/AUAn68l+4HFkZUAAAAASUVORK5CYII=) center no-repeat;
        }
      }
      &.next {
        right: -6px;
        z-index: 0;
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        span {
          background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAXBAMAAADJv2OxAAAAMFBMVEX///+ZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZnHHh8xAAAAD3RSTlMAESIzRFVmd4iZqrvM3e5GKvWZAAAAdElEQVQI12NgAAH2v2CKsf83mJb8vxBEMe3/KQCitf83gLn3n4Flff8FgCjW90/A3Pw/BiBK4v0lMHf+bwUw/R/CZVj/SwBq5kaIQD1UAfv7yxCBuH8OYJrl/VWIgM3/AjDNdP45REDq/0SI+9b/gghw/AUAn68l+4HFkZUAAAAASUVORK5CYII=) center no-repeat;
        }
      }
      &:hover {
        background: #3d3d3d;
      }
    }
    .gridder-close {
      background: url(data:image/gif;base64,R0lGODlhFAAUAJEAAE1NTf///////wAAACH5BAEHAAIALAAAAAAUABQAAAIzBISpK+YMm5Enpodw1HlCfnkKOIqU1VXk55goVb2hi7Y0q95lfG70uurNaqLgTviyyUoFADs=) no-repeat center center;
      content: '\e876';
      font-family: 'Material Icons';
      cursor: pointer;
      height: 30px;
      padding: 7px;
      position: absolute;
      right: 10px;
      top: 10px;
      width: 30px;

      &:hover {
        color: #3d3d3d;
      }
    }

  }

  .gridder {
    .gridder-list {
        width: 165px;
        height: 124px;
        img {
            width:100%;
        }
    }
    .gridder-navigation{
        .gridder-nav.disabled {
            cursor: not-allowed !important;
        }
    }
  }

  .video-summary_text {
    p {
        color: $black-charcoal;
        display: block!important;
    }
  }

  #multiWrap {
    #imgsVids {
      margin-bottom: 0.5rem;
    }
    .list-group_inner {
      display:flex;
      align-items: center;
      .list-group-item_title_summary {
        a {
          display: block;
          margin-bottom: 0.5rem;
        }
      }
      .video-summary_text {
        p {
          line-height: 1;
        }
      }
    }
  }

  @media (max-width: 767px) {
      #multiWrap {
        #imgsVids {
          .gridder-list {
            margin-bottom: 0.5rem;
          }
        }
        .list-group_inner {
          flex-direction: column;
          align-items: start;
        }
        .list-group-item_video {
            img {
                min-width: px-to-rem(150);
            }
        }
      }
      #video-page {
        .video-summary_text {
            p {
                margin-bottom: 1rem!important;
            }
        }
      }
     .video-summary_text {
        p {
            margin: 0!important;
        }
     }
  }