/* BDI Notifications */
.badge-bp,
.chip-bp {
    background-color: #c50084;
    color: #fff
}

a.badge-bp,
a.badge-bp:active,
a.badge-bp:focus,
a.badge-bp:hover,
a.chip-bp,
a.chip-bp:active,
a.chip-bp:focus,
a.chip-bp:hover {
    color: #fff;
}

span.badge.badge-bp.notification-number {
    position: absolute;
    top: 2px;
    left: 4px;
    padding: .25rem 0.4rem;
    background-color: #cd202c;
    z-index: 2;
}

.notifications-list-group .list-group-item {
    border-radius: 0px;
    padding: .6rem 1rem !important;
}

.notifications-list-group .UNSEEN .icon-bp-icons-important-updates {
    font-size: 1.25rem;
    color: #c50084;
}

.notifications-list-group .UNREAD .icon-bp-icons-important-updates {
    font-size: 1.25rem;
    color: #c50084;
}

.notifications-list-group .READ .icon-bp-icons-important-updates {
    font-size: 1.25rem;
    color: #757575!important;
}

.notifications-list-group .notifications-title {
    vertical-align: text-bottom;
    font-size: 0.85rem;
}

.notifications-list-group .notifications-date {
    font-size: 0.7rem;
}

.notifications-list-group .READ {
    color: #757575!important;
    box-shadow: none;
}

.notifications-list-group .UNSEEN{
    border-left: 5px solid #c50084;
    font-weight: bold;
}

.notifications-list-group .UNREAD{
    border-left: 5px solid #c50084;
    font-weight: bold;
}