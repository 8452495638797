@charset "UTF-8";

@font-face {
  font-family: "bmj-icons";
  src:url("/fonts/bmj-icons.eot");
  src:url("/fonts/bmj-icons.eot?#iefix") format("embedded-opentype"),
    url("/fonts/bmj-icons.woff") format("woff"),
    url("/fonts/bmj-icons.ttf") format("truetype"),
    url("/fonts/bmj-icons.svg#bmj-icons") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;

}

[data-icon]:before {
  font-family: "bmj-icons" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "bmj-icons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-bp-icons-info-circle:before {
  content: "\e904";
}
.icon-bp-icons-delete2:before {
  content: "\e902";
}
.icon-bp-icons-edit2:before {
  content: "\e903";
}
.icon-bp-icons-info-solid:before {
  content: "\e900";
}
.icon-bp-icons-edit:before {
  content: "\e901";
}
.icon-bp-icons-alert:before {
  content: "\61";
}
.icon-bp-icons-alert-small:before {
  content: "\7c";
}
.icon-bp-icons-back:before {
  content: "\62";
}
.icon-bp-icons-breadcrumb-arrow:before {
  content: "\63";
}
.icon-bp-icons-calculator-ex-sm-on-white:before {
  content: "\64";
}
.icon-bp-icons-calculators:before {
  content: "\66";
}
.icon-bp-icons-calculator-small:before {
  content: "\65";
}
.icon-bp-icons-calendar:before {
  content: "\67";
}
.icon-bp-icons-certificate:before {
  content: "\68";
}
.icon-bp-icons-checkmark:before {
  content: "\69";
}
.icon-bp-icons-close:before {
  content: "\6a";
}
.icon-bp-icons-comorbidities:before {
  content: "\7b";
}
.icon-bp-icons-delete:before {
  content: "\6b";
}
.icon-bp-icons-down-arrow:before {
  content: "\6c";
}
.icon-bp-icons-download:before {
  content: "\6d";
}
.icon-bp-icons-drugs:before {
  content: "\7a";
}
.icon-bp-icons-drugs-extra-small:before {
  content: "\79";
}
.icon-bp-icons-drugs-extra-small-on-white:before {
  content: "\78";
}
.icon-bp-icons-evidence:before {
  content: "\77";
}
.icon-bp-icons-evidence-extra-small:before {
  content: "\76";
}
.icon-bp-icons-facebook:before {
  content: "\75";
}
.icon-bp-icons-feedback:before {
  content: "\74";
}
.icon-bp-icons-highlight:before {
  content: "\73";
}
.icon-bp-icons-highlight-checkmark:before {
  content: "\72";
}
.icon-bp-icons-important-updates:before {
  content: "\70";
}
.icon-bp-icons-info:before {
  content: "\6f";
}
.icon-bp-icons-library:before {
  content: "\7d";
}
.icon-bp-icons-linked-in:before {
  content: "\6e";
}
.icon-bp-icons-menu:before {
  content: "\41";
}
.icon-bp-icons-mobile:before {
  content: "\42";
}
.icon-bp-icons-outbound-link:before {
  content: "\43";
}
.icon-bp-icons-patient-leaflets:before {
  content: "\44";
}
.icon-bp-icons-patient-leaflets-extra-small:before {
  content: "\45";
}
.icon-bp-icons-pdf-extra-small-on-white:before {
  content: "\46";
}
.icon-bp-icons-pdf-light-blue-on-dark-blue:before {
  content: "\47";
}
.icon-bp-icons-procedural-videos:before {
  content: "\48";
}
.icon-bp-icons-procedural-videos-extra-small:before {
  content: "\49";
}
.icon-bp-icons-recent-updates:before {
  content: "\4a";
}
.icon-bp-icons-recent-updates-extra-small:before {
  content: "\4b";
}
.icon-bp-icons-red-flag:before {
  content: "\4c";
}
.icon-bp-icons-search:before {
  content: "\4d";
}
.icon-bp-icons-specialties:before {
  content: "\56";
}
.icon-bp-icons-specialties-extra-small:before {
  content: "\55";
}
.icon-bp-icons-topic-menu:before {
  content: "\54";
}
.icon-bp-icons-twitter:before {
  content: "\53";
}
.icon-bp-icons-up-arrow:before {
  content: "\52";
}
.icon-bp-icons-user-for-email-desktop:before {
  content: "\51";
}
.icon-bp-icons-user-for-email-mobile:before {
  content: "\50";
}
.icon-bp-icons-warning:before {
  content: "\4f";
}
.icon-bp-icons-youtube:before {
  content: "\4e";
}
.icon-bp-icons-empty-file:before {
  content: "\e924";
}
.icon-bp-icons-cross:before {
  content: "\ea0f";
}
.icon-bp-icons-print:before {
  content: "\e954";
}
// Recent Updates
.updateheadline {
  .icon-bp-icons-important-updates {
    &:before {
      color: $palette-pink-500;
      font-size: 1.75rem;
      position: relative;
      top: 7px;
      margin: 0 3px 0 -3px;
    }
  }
}
