.ebm {
 .card {
   h2, h3 {
    padding-left: 0;
    margin-bottom: 1rem;
    }
  }
  // Image Caption
  .imgBox {
    padding: 10px;
    .imageCap {
      position: absolute;
      left: 10px;
      color: #fff;
      right: 10px;
      bottom: 9px;
      height: 32px;
      overflow: hidden;
      opacity: .75;
      padding: 5px 10px;
      background: #2a6ebb;
      -webkit-transition: .25s height;
      -moz-transition: .25s height;
      -o-transition: .25s height;
      transition: .25s height;
      p {
        opacity: 1;
        margin:0;
      }
    }
  }
  .imgBox {
    &:hover {
    .imageCap {
      height: 44px;
      opacity: 1;
      -webkit-transition: .25s height;
      -moz-transition: .25s height;
      -o-transition: .25s height;
      transition: .25s height;
      }
    }
  }
  .ebmCCA {
    margin-top: 27px;
    img {
      width: 190px;
      }
  }
  a.btn {
    border: 3px solid $palette-pink-500;
    background-color: $white;
    color: $palette-pink-500;
    padding: .6875rem .5rem .6875rem 1.5rem;;
    &:hover.btn {
      background-color: $palette-pink-500;
      color: $white;
    }
  }
}

#cca {
  ul li {
    margin-bottom: .825rem;
  }
  a.ext-link {
    margin: 0 0 .5rem 0.625rem;
    &:hover {
      color: $palette-pink-500 !important;
    }
  }
}

// Media Queries
@media (max-width: 575px) {
  .ebm h1 {
      padding-left: 1rem;
  }
}

@media (max-width: 991px) {
  .ebmCCA {
    text-align: center;
    margin: 1.5rem 0;
  }
}

@media (min-width: 992px) and (max-width: 1199px){
  .ebmCCA {
    .btn {
      max-width: fit-content;
    }
  }
}
.gradeTablePagePops {
  #kdf{
    .rowCondition {
      h3 {
        font-weight: lighter;
      }
    }
  }
  .cca-logo-md {
    max-height: 50px;
  }
}

#kdf {
 .cca-logo-sm {
    max-height: 24px;
    margin-left: 1rem;
    display: inline-block;
 }


  .gradeTablePageEffPop, .gradeTablePageGradePop {
    &:hover {
      cursor: pointer;
    }
  }

  .icon {
    &.icon-bp-icons-info-circle {
      color: $palette-blue-grey-900;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.75rem;
    }
  }
  
  .evTables {
		.row {
			margin-right: 0px;
			margin-left: 0px;				
		}
  }

  .custom-evidence-img {
    max-height: 70%;
    max-width: 100%;
  }
}


.has-pop {
  &.gradeTablePageEffPop, &.gradeTablePageGradePop, &.gradeTablePageEvPop{
    color: $palette-pink-500 !important;
    font-weight: bold;

    sup {
      color: $white;
      background-color: $palette-pink-500;
      border-radius: 50%;
      margin-left: 0.25rem;
      padding: 0 3px;
    }
  }

}
.gradeTablePage-bg {
  background-color: $white;
 }

.evidence-page {
  img {
    max-height: 150px;
  }
  ul{
    li {
      color:$palette-blue-500;
    }
  }
}

.evTables {
  .ev-question{
    line-height: 1.5rem;
  }
}

.gradeTablePagePops {
  .histExam  {
    .rowDetails {
      p {
        padding-left: 0;
        padding-right: 0;

        @include media-breakpoint-up(sm) {
          padding-left: 1rem;
          padding-right: 1rem;
        }
      }
    }
  }
}

.main-container {
  &.evidence {
    h3 {
      font-size: 1.75rem;
    }
  }
}

